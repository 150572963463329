import React, { type FC } from 'react';
import type { SanityVisualAsset } from 'sanity.types';
import { useScreenSize } from '@konsus/superside-kit';
import { Animation } from '../../Animation';
import { buildSanityUrl } from '../utils';

interface LottieProps
  extends Pick<
    SanityVisualAsset,
    | 'lottie'
    | 'lottieAspectRatio'
    | 'lottieMobile'
    | 'lottieMobileAspectRatio'
    | 'lottieTrigger'
    | 'videoOnClickEnabled'
  > {
  onClick: () => void;
}

const Lottie: FC<LottieProps> = (props) => {
  const {
    lottieMobile,
    lottie,
    lottieMobileAspectRatio,
    lottieAspectRatio,
    lottieTrigger,
    videoOnClickEnabled,
    onClick
  } = props;

  const { breakpoints } = useScreenSize();

  const aspectRatio = breakpoints?.medium
    ? lottieAspectRatio
    : lottieMobileAspectRatio || lottieAspectRatio;
  const animationUrl = buildSanityUrl(breakpoints?.medium ? lottie : lottieMobile || lottie);

  return animationUrl ? (
    <Animation
      aspectRatio={aspectRatio}
      animationUrl={animationUrl}
      lottieTrigger={lottieTrigger}
      onClick={onClick}
      videoOnClickEnabled={videoOnClickEnabled}
    />
  ) : null;
};

export default Lottie;
