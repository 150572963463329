import React from 'react';
import { cn } from '@superside/ui';

export interface RichTextTableComponentProps {
  node: {
    styleName: 'galaxy-blue' | 'nebula-indigo' | 'marble';
    sameWidth?: boolean;
    borders?: boolean;
    table: { rows: { _key: string; _type: string; cells: string[] }[] };
  };
}

export const RichTextTableComponent: React.FC<RichTextTableComponentProps> = ({
  node: {
    styleName = 'galaxy-blue',
    sameWidth,
    borders,
    table: { rows }
  }
}) => {
  const firstRowCells = rows[0].cells;
  const columnsAmount = firstRowCells.length;
  const columnWidth = `${100 / columnsAmount}%`;
  const lessThanSixColumns = columnsAmount < 6;

  const STYLES = {
    'galaxy-blue': {
      container: 'shadow-[0_0_0_1px] shadow-blue-300',
      th: 'bg-blue-200',
      thBorder: '[&:not(:last-child)]:after:bg-blue-300',
      td: '[&:not(:last-child)]:shadow-[1px_0_0_0] [&:not(:last-child)]:shadow-blue-300 first:font-semibold',
      tr: 'odd:bg-[#fff] even:bg-[#eff2fc]'
    },
    'nebula-indigo': {
      container: 'shadow-[3px_3px_16px_0px_rgba(15,33,51,0.08)]',
      th: 'bg-indigo-500 text-light',
      thBorder: '[&:not(:last-child)]:after:bg-blue-200',
      td: '[&:not(:last-child)]:shadow-[1px_0_0_0] [&:not(:last-child)]:shadow-blue-200 first:font-semibold',
      tr: 'odd:bg-[#fff] even:bg-[#eff2fc]'
    },
    marble: {
      container: 'shadow-[3px_3px_16px_0px_rgba(222,209,198,0.1)]',
      th: 'bg-[rgba(222,209,198,0.7)]',
      thBorder: '[&:not(:last-child)]:after:bg-grey-300',
      td: '[&:not(:last-child)]:shadow-[1px_0_0_0] [&:not(:last-child)]:shadow-[rgba(222,209,198,0.5)]',
      tr: 'odd:bg-[#fff] even:bg-[rgba(222,209,198,0.2)]'
    }
  };

  return (
    <div className='relative -mx-5 -mt-5 mb-5 overflow-x-auto overflow-y-visible p-5'>
      <table
        className={cn(
          'w-full',
          'rounded-lg',
          'border-collapse',
          'border-spacing-px',
          lessThanSixColumns && 'text-lg',
          STYLES[styleName].container,
          sameWidth && '1280:table-fixed'
        )}
      >
        <thead>
          <tr>
            {firstRowCells.map((cell) => (
              <th
                key={cell}
                className={cn(
                  'font-semibold',
                  'text-left',
                  'py-4',
                  'px-6',
                  'max-w-[215px]',
                  '1280:max-w-none',
                  'relative',
                  'first:rounded-tl-lg',
                  'last:rounded-tr-lg',
                  'after:absolute after:-right-px after:top-0 after:z-10 after:h-full after:w-px',
                  borders && STYLES[styleName].thBorder,
                  STYLES[styleName].th
                )}
              >
                {cell}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(1).map((row, idx) => (
            <tr key={row._key} className={STYLES[styleName].tr}>
              {row.cells.map((cell) => (
                <td
                  key={cell}
                  className={cn(
                    'w-auto',
                    'border-none',
                    'py-4',
                    'px-6',
                    'max-w-[215px]',
                    '1280:max-w-none',
                    borders && STYLES[styleName].td,
                    idx === rows.length - 2 && 'first:rounded-bl-lg',
                    idx === rows.length - 2 && 'last:rounded-br-lg'
                  )}
                  style={{ width: sameWidth ? columnWidth : undefined }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
