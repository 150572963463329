import React, { type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, cn } from '@superside/ui';
import NextImage from '@konsus/image';
import { cursorMagnifier } from './commonStyles';
import type { SanityVisualAsset } from 'sanity.types';

const ANIMATION_DURATION = 2;

interface ProgressBarProps
  extends Pick<
    SanityVisualAsset,
    'progressBarIcon' | 'progressBarPercentage' | 'progressBarText' | 'videoOnClickEnabled'
  > {
  onClick?: () => void;
}

export const ProgressBar: FC<ProgressBarProps> = ({
  progressBarIcon,
  progressBarPercentage,
  progressBarText,
  videoOnClickEnabled,
  onClick
}) => {
  const { ref, inView } = useInView({
    threshold: 0.1
  });

  if (!progressBarPercentage) {
    return null;
  }

  return (
    <Box onClick={onClick} className={cn('flex-row', videoOnClickEnabled && cursorMagnifier)}>
      {progressBarIcon ? (
        <Box className='chapterIconStyle 1024:h-[96px] 1024:mr-6 1024:w-[96px] mr-4 h-[72px] w-[72px]'>
          <NextImage
            image={progressBarIcon}
            alt={progressBarText || `${progressBarPercentage}%`}
            layout='responsive'
          />
        </Box>
      ) : null}
      <Box className='shrink grow'>
        <span className='progressBarTitle 1024:[&_span]:-ml-[14px] 1024:text-[57px] text-dark dark:text-light text-[40px] font-bold leading-[1.4] [&_span]:-ml-[10px]'>
          {progressBarPercentage} <span>%</span>
        </span>
        <Box
          ref={ref}
          className={cn(
            'relative h-5 w-full overflow-hidden rounded-md',
            'before:content-empty before:absolute before:left-0 before:top-0 before:z-[10] before:h-[20px] before:w-0 before:rounded-bl before:rounded-tl before:bg-indigo-500 before:will-change-transform',
            'after:content-empty after:absolute after:inset-0 after:z-0 after:h-[20px] after:w-full after:rounded-[4px] after:bg-blue-200',
            inView && 'before:animate-changeWidth before:w-[var(--width-percentage)]'
          )}
          style={
            {
              '--width-percentage': `${progressBarPercentage}%`,
              '--animation-duration-sec': `${ANIMATION_DURATION}s`
            } as { [key: string]: string }
          }
        />
        {progressBarText ? (
          <span className='progressBarTextStyle 1024:mt-3 1024:text-bor-base dark:text-light text-bor-sm mt-[8px] text-[#646464]'>
            {progressBarText}
          </span>
        ) : null}
      </Box>
    </Box>
  );
};

export default ProgressBar;
