import React, { useRef, forwardRef, useCallback, useEffect, type MutableRefObject } from 'react';
import { useAtomMutator } from '@konsus/atoms';
import { getTextSelection } from '@konsus/utils/src/getTextSelection';
import { tweetThisValueAtom } from './SanityBlockComponent';

export const TweetThisWrapper: React.FC<{ children; ref?: any }> = forwardRef((props, ref) => {
  const paragraphRef = useRef<HTMLDivElement>(null);

  const setTweetThisValue = useAtomMutator(tweetThisValueAtom);

  const mouseupHandler = useCallback(
    (e) => {
      const text = getTextSelection();

      if (text) {
        const box = (ref as MutableRefObject<any>).current.getBoundingClientRect();

        setTweetThisValue({
          text,
          coordinates: {
            y: e.pageY - (box.top + window.pageYOffset) + 13,
            x: e.pageX - (box.left + window.pageXOffset) - 80
          }
        });
      } else {
        setTweetThisValue({
          text: ''
        });
      }
    },
    [ref, setTweetThisValue]
  );

  useEffect(() => {
    const currentRef = paragraphRef.current;

    currentRef.addEventListener('mouseup', mouseupHandler);

    return () => {
      currentRef.removeEventListener('mouseup', mouseupHandler);
    };
  }, [paragraphRef, mouseupHandler]);

  return (
    <p
      className={
        '[&::selection,&_*::selection]:text-dark relative [&::selection,&_*::selection]:bg-green-200'
      }
      ref={paragraphRef}
    >
      {props.children}
    </p>
  );
});

TweetThisWrapper.displayName = 'TweetThisWrapper';
