import React from 'react';
import { Box, Text, Anchor } from '@konsus/superside-kit';
import { createLazyLoadableComponent } from './utlis/createLazyLoadableComponent';

export const ImageWithCreditContent = (props) => {
  const { ImageComponent, node, options } = props;
  const { image, creditLinkLabel, creditLinkUrl, centerAlign = false } = node;

  const content = (
    <Box className='image-with-credit' pad={{ top: '9', bottom: '9' }}>
      <ImageComponent node={image} sanityConfig={options} style={{ maxWidth: '100%' }} />

      {creditLinkLabel ? (
        <Box className={'[html_body_&]:mt-0'} pad={{ top: 'xsmall' }}>
          {creditLinkUrl ? (
            <Text>
              Credit:{' '}
              <Anchor
                className={'[html_body_&]:text-[#1d1f25]'}
                href={creditLinkUrl}
                target='_blank'
              >
                {creditLinkLabel}
              </Anchor>
            </Text>
          ) : (
            <Text textAlign='center'>Credit: {creditLinkLabel}</Text>
          )}
        </Box>
      ) : null}
    </Box>
  );

  if (centerAlign) {
    return <div className={'w-full text-center'}>{content}</div>;
  }

  return content;
};

export const ImageWithCredit = createLazyLoadableComponent(ImageWithCreditContent);
