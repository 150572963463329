import React from 'react';
import { type CropMode, type FitMode, type ImageFormat } from '@sanity/image-url/lib/types/types';
import { NextImage, type SafeNumber, tryToExtractImageDetails } from '@konsus/image';

// TODO cleanup interface
export interface ImageComponentProps {
  width?: number;
  height?: SafeNumber;
  mobileWidth?: number;
  mobileHeight?: number;
  mediumWidth?: number;
  mediumHeight?: number;
  largeWidth?: number;
  largeHeight?: number;
  xlargeWidth?: number;
  xlargeHeight?: number;
  fit?: FitMode;
  crop?: CropMode;
  format?: ImageFormat;
  quality?: number;
  className?: string;
  node: any;
  sanityConfig?: any;
  preserveRatio?: boolean;
  placeholderSrc?: string;
  isInline?: boolean;
  isLazy?: boolean;
  alt?: string;
  imgRef?: any;
  onLoad?: (e) => void;
  onError?: (e) => void;
  style?: React.CSSProperties;
}

export const ImageComponent = (props: ImageComponentProps) => {
  if (!props.node?.asset?._ref) {
    return null;
  }

  const image = tryToExtractImageDetails(props.node);

  const { centerAlign, width: setWidth } = props.node;
  const setHeight = setWidth / image.aspectRatio;

  const width = setWidth || props.width || image.width;
  const height = (setHeight || props.height || image.height) as SafeNumber;

  return (
    <div className='rich-text-image' style={{ textAlign: centerAlign ? 'center' : 'left' }}>
      <NextImage
        image={props.node}
        alt={image.name}
        width={width}
        height={height}
        loading={props.isLazy === false ? 'eager' : 'lazy'}
        objectFit='contain'
        objectPosition='left'
      />
    </div>
  );
};

export default ImageComponent;
