import React from 'react';
import { NextFutureImage, type SanityImage } from '@konsus/image';
import { Box, classForExcludingFromRichEditorRules } from '@konsus/superside-kit';
import Superscript from '@konsus/lego/src/components/Superscript';

import { Heading } from '@superside/ui';

export type ResultsType = {
  _key: string;
  resultTitle: string;
  resultNumber: string;
  resultAsset: SanityImage;
};

export const Results: React.FC<{ results: ResultsType[] }> = ({ results }) => (
  <Box
    className={'1280:pr-[50px] <1280:justify-between'}
    direction='row'
    margin={{ vertical: '4' }}
  >
    {results.map(({ _key, resultTitle, resultNumber, resultAsset }) => (
      <Box
        key={_key}
        className={
          '1280:[&_img]:max-w-[64px] 768:[&:not(:last-child)]:mr-[10%] 768:[&:not(:last-child)]:pr-[10%] 768:[&_img]:max-w-[56px] <1280:[&_span]:overflow-hidden <1280:[&_span]:text-bor-xs [&:not(:last-child)_:after]:z-1 box-border flex-1 flex-wrap [&_img]:h-auto [&_img]:max-w-[40px] [&:not(:last-child)]:relative [&:not(:last-child)]:mr-[5%] [&:not(:last-child)]:pr-[5%] [&:not(:last-child):after]:absolute [&:not(:last-child):after]:right-0 [&:not(:last-child):after]:top-0 [&:not(:last-child):after]:block [&:not(:last-child):after]:h-full [&:not(:last-child):after]:w-[2px] [&:not(:last-child):after]:bg-[rgba(15,33,51,0.1)] [&:not(:last-child):after]:content-[""] [&_h3]:overflow-hidden'
        }
        direction='row'
        align='start'
        gap='6'
      >
        <NextFutureImage
          image={resultAsset}
          className={classForExcludingFromRichEditorRules}
          width={64}
          height={64}
        />
        <Box>
          <Superscript content={resultTitle} weight='bold' />
          <Heading level='h3'>{resultNumber}</Heading>
        </Box>
      </Box>
    ))}
  </Box>
);
