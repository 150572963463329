import React from 'react';
import { Button, previewMediaValueAtom } from '@konsus/superside-kit';
import NextImage, {
  getFormatSpecificImageProps,
  type SanityAsset,
  tryToExtractImageDetails,
  type NextImageProps
} from '@konsus/image';
import { useAtomMutator } from '@konsus/atoms';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { cn } from '@superside/ui';
import type { SlideType } from '../types';
import { type IVisualAsset } from '../types';
import styles from './ImageStyles.module.css';

const MAX_HEIGHT = 700;

export const Image: React.FC<
  NextImageProps & {
    imageMobile: any;
    objectFitMobile: any;
    objectPositionMobile: any;
    showPreviewModal: any;
    videoOnClickEnabled: any;
    requestWidthMobile: any;
    maxWidth: IVisualAsset['maxWidth'] | 'optimized';
    maxWidthClassName?: string;
    captionText: any;
    isRte: any;
    slides?: SlideType[];
    activeIndex?: number;
    previewModalTitle?: string;
  }
> = ({
  image,
  imageMobile,
  alt,
  objectFit,
  objectPosition,
  layout,
  className,
  objectFitMobile,
  objectPositionMobile,
  showPreviewModal,
  slides,
  activeIndex,
  previewModalTitle,
  onClick,
  videoOnClickEnabled,
  requestWidth,
  requestWidthMobile,
  maxWidth = '100%',
  maxWidthClassName,
  captionText,
  isRte,
  loading
}) => {
  const setPreviewMediaValue = useAtomMutator(previewMediaValueAtom);

  if (!image) {
    return null;
  }

  const imageDetails = tryToExtractImageDetails(image as any);
  const extraImageProps = getFormatSpecificImageProps(image, imageDetails);

  if (image._type === 'image' && isRte && maxWidth === 'optimized') {
    if (typeof imageDetails.height === 'number' && imageDetails.aspectRatio) {
      if (imageDetails.height > MAX_HEIGHT) {
        extraImageProps.height = MAX_HEIGHT;
        extraImageProps.width = MAX_HEIGHT * imageDetails.aspectRatio;
      } else {
        extraImageProps.height = imageDetails.height;
        extraImageProps.width = (imageDetails.width as number) * imageDetails.aspectRatio;
      }
    }
  }

  const imageComponent = (
    <NextImage
      image={image as SanityAsset}
      alt={alt}
      objectFit={objectFit}
      objectPosition={objectPosition}
      layout={layout}
      className={cn('!h-full', className)}
      requestWidth={requestWidth}
      loading={loading}
      {...extraImageProps}
    />
  );

  const imageClickHandler = () => {
    if (videoOnClickEnabled) {
      // @ts-ignore
      onClick();
    } else {
      // @ts-ignore
      if (slides?.length) {
        setPreviewMediaValue({ slides, alt, title: previewModalTitle, activeIndex });
      } else {
        setPreviewMediaValue({ image, alt, title: previewModalTitle });
      }
    }
  };

  if (imageMobile) {
    const extraMobileImageProps = getFormatSpecificImageProps(imageMobile);

    return (
      <div className={cn('mx-auto w-full', maxWidthClassName)}>
        <div className='va-m-img 768:last:[&_>_img]:!inline-block 768:last:[&_>_span]:!inline-block 768:first:[&_>img]:!hidden 768:first:[&_>span]:!hidden flex h-full w-full last:[&_>_img]:!hidden last:[&_>_span]:!hidden'>
          <NextImage
            image={imageMobile}
            alt={alt}
            objectFit={objectFitMobile || objectFit}
            objectPosition={objectPositionMobile || objectPosition}
            className={className}
            onClick={onClick}
            requestWidth={requestWidthMobile || requestWidth}
            loading={loading}
            {...extraMobileImageProps}
          />
          {showPreviewModal ? (
            <Button className='cursor-zoom-in' onClick={imageClickHandler} plain>
              {imageComponent}
            </Button>
          ) : (
            imageComponent
          )}
        </div>
        {captionText ? (
          <div className={styles.captionTextWrapper}>
            <SanityBlockComponent blocks={captionText} />
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={cn(
        'mx-auto flex h-full w-full flex-col gap-3',
        maxWidthClassName,
        videoOnClickEnabled ? '[&_button]:cursor-pointer' : ''
      )}
    >
      {showPreviewModal ? (
        <Button className='cursor-zoom-in' onClick={imageClickHandler} plain>
          {imageComponent}
        </Button>
      ) : (
        imageComponent
      )}
      {captionText ? (
        <div className={styles.captionTextWrapper}>
          <SanityBlockComponent blocks={captionText} />
        </div>
      ) : null}
    </div>
  );
};

export default Image;
