import React from 'react';
// @ts-ignore implicit 'any' type
import BlockContent from '@sanity/block-content-to-react';
import {
  cn,
  Heading,
  type HeadingProps,
  Superscript,
  type SuperscriptProps,
  Text,
  type TextProps
} from '@superside/ui';
import { QuoteComponent } from './QuoteComponent';

const BlockRenderers = {
  body: (props: TextProps) => <Text {...props} />,
  normal: (props: { renderNormalAsHeading?: HeadingOptions } & (HeadingProps | TextProps)) => {
    if (!props.renderNormalAsHeading) {
      return (
        <Text as='p' className={cn(props.className, 'mb-4 last:mb-0')} {...(props as TextProps)} />
      );
    }

    const { as = undefined, level } =
      typeof props.renderNormalAsHeading === 'object'
        ? props.renderNormalAsHeading
        : { level: props.renderNormalAsHeading };

    return <Heading as={as} level={level} {...(props as HeadingProps)} />;
  },
  disclaimer: (props: TextProps) => (
    <Text as='p' className={cn(props.className, 'mb-4 last:mb-0')} size='sm' {...props} />
  ),
  blockquote: (props: TextProps) => <QuoteComponent {...props} />,
  superscript: (props: SuperscriptProps) => (
    <Superscript as='p' className={cn(props.className, 'mb-4 last:mb-0')} {...props} />
  ),
  mega: (props: HeadingProps) => <Heading level='mega' {...props} />,
  epic: (props: HeadingProps) => <Heading level='epic' {...props} />,
  h1: (props: HeadingProps) => <Heading level='h1' {...props} />,
  h2: (props: HeadingProps) => <Heading level='h2' {...props} />,
  h3: (props: HeadingProps) => <Heading level='h3' {...props} />,
  h4: (props: HeadingProps) => <Heading level='h4' {...props} />,
  h5: (props: HeadingProps) => <Heading level='h5' {...props} />,
  h6: (props: HeadingProps) => <Heading level='h6' {...props} />
};

export type HeadingOptions =
  | HeadingProps['level']
  | { as?: HeadingProps['as']; level: HeadingProps['level'] };

export interface TextBlockRendererProps extends React.PropsWithChildren {
  node: {
    style?: keyof typeof BlockRenderers;
  };
  renderNormalAsHeading?: HeadingOptions;
  className?: string;
}

export const TextBlockRenderer: React.FC<TextBlockRendererProps> = (props) => {
  const {
    node: { style },
    ...rest
  } = props;

  return style && style in BlockRenderers
    ? BlockRenderers[style](rest)
    : // @ts-ignore
      BlockContent.defaultSerializers.types.block(props);
};
