import React from 'react';
import { cn } from '@superside/ui';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

interface GoogleSlidesComponentProps {
  url?: string;
  mobileUrl?: string;
  aspectRatio?: string;
  mobileAspectRatio?: string;
  title?: string;
}

interface GoogleSlidesComponentContentProps extends GoogleSlidesComponentProps {
  node?: GoogleSlidesComponentProps;
}

const DEFAULT_GOOGLE_SLIDES_AR = '5/3';
const DEFAULT_GOOGLE_SLIDES_IFRAME_TITLE = 'google-slides';

export const GoogleSlidesComponentContent: React.FC<GoogleSlidesComponentContentProps> = (
  props
) => {
  const url = props?.node?.url || props?.url;
  const mobileUrl = props?.node?.mobileUrl || props?.mobileUrl || url;
  const aspectRatio = props?.node?.aspectRatio || props?.aspectRatio || DEFAULT_GOOGLE_SLIDES_AR;
  const mobileAspectRatio =
    props?.node?.mobileAspectRatio || props?.mobileAspectRatio || aspectRatio;
  const title = props?.node?.title || props?.title || DEFAULT_GOOGLE_SLIDES_IFRAME_TITLE;

  const iframeStyles =
    'rte-mobile-full-width absolute left-0 top-0 h-full max-h-full w-full max-w-full';

  if (!url?.includes('docs.google.com/presentation')) {
    return null;
  }

  return url ? (
    <div
      style={
        {
          '--aspect-ratio-gs': aspectRatio,
          '--mobile-aspect-ratio-gs': mobileAspectRatio
        } as React.CSSProperties
      }
      className={cn(
        'rte-sub relative mx-auto max-h-[calc(100vh-100px)]',
        `768:aspect-[var(--aspect-ratio-gs)]`,
        `<768:aspect-[var(--mobile-aspect-ratio-gs)]`
      )}
    >
      <iframe
        src={mobileUrl}
        className={cn(iframeStyles, '768:hidden')}
        title={`mobile-${title}`}
      />
      <iframe src={url} className={cn(iframeStyles, '<768:hidden')} title={title} />
    </div>
  ) : null;
};

export const GoogleSlidesComponent = createLazyLoadableComponent(GoogleSlidesComponentContent);
