import React, { useEffect, useState } from 'react';
import { cn } from '@superside/ui';
import { useScript } from '@konsus/superside-kit/src/hooks/useScript';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

const STANDART_16_9_ASPECT_RATIO = '56.25%';
const wistiaScriptUrl = `https://fast.wistia.com/assets/external/E-v1.js`;

interface RichTextWistiaVideoProps {
  node: {
    id: string;
  };
}

const RichTextWistiaVideoContent: React.FC<RichTextWistiaVideoProps> = ({ node: { id } }) => {
  const [aspectRatio, setAspectRatio] = useState(`${STANDART_16_9_ASPECT_RATIO} 0 0 0`);

  useEffect(() => {
    async function logJSONData() {
      await fetch(`https://fast.wistia.com/embed/medias/${id}.jsonp`)
        .then(async (res) => {
          const data = await res.text();
          const { width, height } = JSON.parse(data.split(';')[0].split('=')[1]).media.assets[0];

          setAspectRatio(`${(100 * height) / width}% 0 0 0`);
        })
        .catch((err) => {
          setAspectRatio(`${STANDART_16_9_ASPECT_RATIO} 0 0 0`);

          // eslint-disable-next-line no-console
          console.error(err);
        });
    }

    logJSONData();
  }, [id]);

  useScript(`//fast.wistia.com/embed/medias/${id}.jsonp`);
  useScript(wistiaScriptUrl);

  return (
    <div
      className={cn('wistia_responsive_padding', 'relative p-[var(--aspect-ratio-var-9487)]')}
      style={{ '--aspect-ratio-var-9487': aspectRatio }}
    >
      <div className={cn('wistia_responsive_wrapper', 'absolute left-0 top-0 h-full w-full')}>
        <div
          className={cn(
            `wistia_embed wistia_async_${id} seo=false videoFoam=true`,
            'h-full w-full'
          )}
        >
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export const RichTextWistiaVideo = createLazyLoadableComponent(RichTextWistiaVideoContent);
