import React from 'react';
import { Button, Box, cn } from '@superside/ui';
import { classForExcludingFromRichEditorRules } from '@konsus/superside-kit';
import {
  bannerBox,
  content,
  descriptionStyle,
  desktopOverflowImageStyle,
  desktopOverflowImageContainerStyle,
  headingStyle,
  gradientOverlayStyles
} from './styles';
import { DesktopErrorImage } from './asset/DesktopErrorImage';
import { MobileErrorImage } from './asset/MobileErrorImage';

type BannerFailurePropType = {
  onRetryClick: () => void;
};

export const BannerFailureContent: React.FC<BannerFailurePropType> = ({ onRetryClick }) => {
  return (
    <Box
      className={cn(
        bannerBox,
        '<1024:flex-col 768:p-10 1024:p-12 bg-coral-100 text-dark flex-row items-center overflow-visible rounded-lg p-5 '
      )}
    >
      <Box className={cn('1024:hidden [span]:overflow-visible relative -mt-10')}>
        <MobileErrorImage
          className={cn(classForExcludingFromRichEditorRules, 'h-full w-full object-cover')}
        />
        <div
          className={cn(gradientOverlayStyles)}
          style={{
            background:
              'linear-gradient(360deg, rgba(255, 224, 223, 1) 0%, rgba(255, 224, 223, 0) 100%)'
          }}
        ></div>
      </Box>
      <Box className={cn(content, '<1024:py-12 flex-1')}>
        <h3 className={cn(classForExcludingFromRichEditorRules, headingStyle)}>
          Oh no, something went wrong!
        </h3>
        <div className={cn(descriptionStyle)}>
          <p className='text-dark mt-[3px] leading-6'>
            Somewhere in the process an error occurred and your request couldn’t be processed.
          </p>
        </div>
        <Button
          variant='secondaryGhost'
          className='text-dark dark:text-dark [&_div]:dark:text-dark underline'
          label='Please try again'
          onClick={onRetryClick}
        />
      </Box>
      <Box
        className={cn(
          '<1024:hidden absolute right-0 top-[-50px]',
          desktopOverflowImageContainerStyle
        )}
      >
        <DesktopErrorImage
          className={cn(classForExcludingFromRichEditorRules, desktopOverflowImageStyle)}
        />
      </Box>
    </Box>
  );
};
