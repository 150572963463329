/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { CaretLeft, Spinner } from 'phosphor-react';
import { Box, Button, cn } from '@superside/ui';
import { ProgressiveBar } from '../ProgressiveBar';
import { ProgressiveFormFields, type ProgressiveFormFieldsProps } from './ProgressiveFormFields';
import { type Answers, type Errors, type ProgressiveFormType, type QuestionItemType } from './type';
import { useFormSubmission } from './useFormSubmission';
import {
  fieldValidator,
  insertFieldsAfterPosition,
  extractValueFromObject,
  getChilipiperValue,
  isPersonalQuestionsSubmitted,
  getLlmScore
} from './util';

export const ProgressiveForm: React.FC<ProgressiveFormType> = ({
  questions: baseQuestions,
  form,
  onFormSuccess,
  onFormError,
  otherProps,
  onKeyPress
}) => {
  const [questions, setQuestions] = useState(baseQuestions);
  const [finalQuestions, setFinalQuestions] = useState<QuestionItemType[]>([]);
  const [position, setPosition] = useState(0);
  const [apolloObject, setApolloObject] = useState({});
  const [errors, setErrors] = useState<Errors>({});
  const [isError, setIsError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [llmFieldIsSet, setLlmFieldIsSet] = useState(false);

  const nonHiddenQuestions = useMemo(() => {
    return questions.filter((q) => !q.hidden && q.type !== 'apolloField' && q.type !== 'llmField');
  }, [questions]);

  const conditionalFields = useMemo(() => {
    return baseQuestions.filter((q) => q.isConditionalField);
  }, [baseQuestions]);

  const llmField = useMemo(() => {
    return baseQuestions.find((q) => q.type === 'llmField');
  }, [baseQuestions]);

  useEffect(() => {
    setFinalQuestions(nonHiddenQuestions);
  }, [nonHiddenQuestions]);

  const useApollo = useMemo(() => {
    return Boolean(questions.find((item) => item.type === 'apolloField'));
  }, [questions]);

  const apolloAnswers = useMemo(() => {
    if (!useApollo) {
      return;
    }

    return questions.reduce((acc: Record<string, QuestionItemType>, question) => {
      if (question.type === 'apolloField') {
        const propertyName = question.apolloPropertyName as string;

        acc[propertyName] = question;
      }

      return acc;
    }, {});
  }, [useApollo, questions]);

  const [answers, setAnswers] = useState<Answers>(
    () => questions?.reduce((acc, { name }) => ({ ...acc, [name]: '' }), {})
  );

  useEffect(() => {
    const updateQuestionVisibility = (
      condition: boolean,
      eligibility: 'true' | 'false',
      item: QuestionItemType
    ) => {
      const setQuestionsSetter = (hidden: boolean) => {
        setQuestions((prevQuestions) =>
          prevQuestions.map((field) => {
            if (field.name === item.name) {
              return {
                ...field,
                hidden
              };
            }

            return field;
          })
        );
      };

      if ((condition && eligibility === 'true') || (!condition && eligibility === 'false')) {
        setQuestionsSetter(false);
      } else if (!item.hidden) {
        setQuestionsSetter(true);
      }
    };

    const evaluateCondition = (item: QuestionItemType) => {
      const { dependentQuestionName, dependentFieldValidation, eligibility, startsWith, includes } =
        item;
      const dependedAnswer = answers[dependentQuestionName];

      if (!eligibility) {
        return;
      }

      if (dependentFieldValidation === 'notNull') {
        const isConditionMet = dependedAnswer !== null && dependedAnswer !== '';

        updateQuestionVisibility(isConditionMet, eligibility, item);
      }

      if (startsWith && dependentFieldValidation === 'startsWith') {
        const isConditionMet = dependedAnswer.startsWith(startsWith);

        updateQuestionVisibility(isConditionMet, eligibility, item);
      }

      if (includes && dependentFieldValidation === 'includes') {
        const isConditionMet = dependedAnswer.includes(includes);

        updateQuestionVisibility(isConditionMet, eligibility, item);
      }
    };

    conditionalFields.forEach((item) => {
      if (item.isConditionalField) {
        evaluateCondition(item);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionalFields, answers]);

  const handleChangeErrors = (errors: Errors) => {
    setErrors((prevErrors) => ({ ...prevErrors, ...errors }));
  };

  const handleChangeAnswers = (answers: Answers) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, ...answers }));
  };

  const { handleSubmit, isSubmitting } = useFormSubmission({
    answers,
    form,
    onFormSuccess,
    onFormError
  });

  const questionItemPercentage = 100 / finalQuestions?.length + 1;
  const progressWidth = Math.round((position + 1) * questionItemPercentage);

  useEffect(() => {
    if (useApollo && apolloAnswers) {
      const newAnswers = { ...answers };

      Object.keys(apolloAnswers).forEach((item) => {
        const extractedValue = extractValueFromObject(apolloObject, item);

        if (extractedValue) {
          newAnswers[`${apolloAnswers[item].name}`] = extractedValue;
          newAnswers[`${apolloAnswers[item].name}_chilipiper`] =
            getChilipiperValue(apolloAnswers[item], extractedValue) || extractedValue;
        }
      });

      setAnswers(newAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useApollo, apolloObject]);

  const handleNext = useCallback(async () => {
    const {
      name,
      type,
      dependentFields = [],
      isDifferentPayload = false
    } = finalQuestions[position];
    const withApollo = name === 'email' && useApollo;
    const currentAnswer = answers[name];

    const newAnswers = { ...answers };

    if (llmField && !llmFieldIsSet && isPersonalQuestionsSubmitted(answers)) {
      getLlmScore(answers).then(({ score }) => {
        newAnswers[`${llmField.name}`] = score;
        setLlmFieldIsSet(true);
      });
    }

    if (withApollo) {
      fetch('/api/apollo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: currentAnswer })
      })
        .then((data) => data.json())
        .then((res) => setApolloObject(res));
    }

    if (isDifferentPayload) {
      const initialQuestion = finalQuestions
        ?.find((q) => q.name === name)
        ?.options?.find((op) => op.value === currentAnswer);

      newAnswers[`${name}_chilipiper`] = initialQuestion?.valueChilipiper || currentAnswer;
    }

    if (dependentFields.length > 0) {
      const matchingDependentFields = dependentFields.filter(
        (field) => field.dependentConditionValues?.includes(currentAnswer)
      );

      if (matchingDependentFields.length > 0) {
        const currentIndex = questions.findIndex((item) => item.name === name);

        setQuestions((prevQuestions) =>
          insertFieldsAfterPosition(prevQuestions, currentIndex, matchingDependentFields)
        );
      } else {
        const answerPositionsToRemove = finalQuestions
          .map((item, i) => ({ item, position: i }))
          .filter((entry) => {
            const dependentField = dependentFields.find((field) => field.name === entry.item.name);

            if (dependentField) {
              return !dependentField.dependentConditionValues?.includes(currentAnswer);
            }

            return !nonHiddenQuestions.includes(entry.item);
          });

        answerPositionsToRemove.forEach((answerPosition) => {
          const questionName = finalQuestions[answerPosition.position].name;

          setQuestions((prevQuestions) =>
            prevQuestions.filter(
              (item) => item.name !== finalQuestions[answerPosition.position].name
            )
          );

          delete newAnswers?.[questionName];
        });
      }
    }
    setAnswers(newAnswers);

    const { isValid, message } = await fieldValidator({
      type,
      name,
      value: currentAnswer,
      isCompanyEmail: Boolean(
        finalQuestions[position].onlyCompanyMail || otherProps?.onlyCompanyEmail
      )
    });

    if (!isValid) {
      setErrors({ [name]: message });
      setIsError(true);

      return;
    } else {
      setErrors({ [name]: '' });
      setIsError(false);
    }

    if (isSubmitting) {
      return;
    }

    if (position === finalQuestions.length - 1) {
      return handleSubmit();
    }

    setIsError(false);

    if (withApollo) {
      setInProgress(true);

      setTimeout(() => {
        setPosition((prev) => prev + 1);
        setInProgress(false);
      }, 1200);
    } else {
      setPosition((prev) => prev + 1);
    }
  }, [
    questions,
    useApollo,
    answers,
    position,
    isSubmitting,
    handleSubmit,
    otherProps?.onlyCompanyEmail,
    finalQuestions,
    nonHiddenQuestions,
    llmField,
    llmFieldIsSet
  ]);

  const handlePrev = () => {
    setIsError(false);
    setPosition((prev) => prev - 1);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyPress?.(e);
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  const progressiveFormFieldsProps: Omit<ProgressiveFormFieldsProps, 'fieldPosition'> = {
    position,
    answers,
    onChangeAnswers: handleChangeAnswers,
    isError,
    onChangeErrors: handleChangeErrors,
    errors,
    questions: finalQuestions,
    otherProps,
    onGoNext: handleNext,
    onKeyPress: handleKeyPress
  };

  return (
    <Box className='relative h-full gap-4' data-testid='progressive-form-container'>
      <Box className='flex-1 justify-end'>
        <Box className='flex-row items-center justify-between'>
          <Box className='flex-1 items-end'>
            <ProgressiveFormFields {...progressiveFormFieldsProps} fieldPosition='top' />
          </Box>
          <Box className='flex-row items-center gap-4'>
            <PrevNextIcons
              onGoPrev={handlePrev}
              onGoNext={handleNext}
              isFirstField={position === 0}
              isSubmitting={inProgress || isSubmitting}
            />
          </Box>
        </Box>
      </Box>
      <ProgressiveBar width={progressWidth} isError={isError} />
      <Box className='flex-1 justify-start'>
        <ProgressiveFormFields {...progressiveFormFieldsProps} fieldPosition='bottom' />
      </Box>
    </Box>
  );
};

const PrevNextIcons = ({
  onGoPrev,
  onGoNext,
  isFirstField,
  isSubmitting
}: {
  onGoPrev: () => void;
  onGoNext: () => void;
  isFirstField: boolean;
  isSubmitting: boolean;
}) => (
  <>
    <Button
      variant='iconGhost'
      size='base'
      onClick={onGoPrev}
      icon={<CaretLeft weight='bold' />}
      className={cn('p-4', isFirstField && 'hidden')}
      aria-label='Previous'
    />
    <Button
      variant='iconFill'
      size='base'
      onClick={onGoNext}
      className='p-4'
      icon={
        isSubmitting ? (
          <Spinner className='animate-spin' />
        ) : (
          <CaretLeft weight='bold' className='rotate-180' />
        )
      }
      aria-label='Next'
    />
  </>
);
