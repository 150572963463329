import React from 'react';
import NextImage, { tryToExtractImageDetails } from '@konsus/image';
import { type LogoSaladItem } from './types';

export interface RichTextLogoSaladProps {
  node: {
    title?: string;
    items?: LogoSaladItem[];
  };
}

export const RichTextLogoSalad: React.FC<RichTextLogoSaladProps> = ({
  node: { title, items = [] }
}) => {
  return (
    <div className='flex flex-col gap-4 text-left'>
      {Boolean(title) && <span>{title}</span>}
      <div className='flex items-center gap-8'>
        {items.length > 0 &&
          items.map((item) => {
            const imageDetails = tryToExtractImageDetails(item.image);
            const heigth = item.height ? Number(item.height) : 35;
            const width = heigth * imageDetails.aspectRatio;

            return (
              <NextImage
                key={item._key}
                image={item.image}
                alt={item.title}
                height={heigth}
                width={width}
              />
            );
          })}
      </div>
    </div>
  );
};
