import React from 'react';
import CardGrid from '../../components/CardGrid';
import { InfoBlock } from '../TilesDeck/InfoBlock';
import { PageSection } from '../../components/PageSection';
import { SectionHeader } from '../../components/SectionHeader';
import PersonItem from './PersonItem';
import { type PeopleCardsSectionProps } from './types';

export const PeopleCardsSection: React.FunctionComponent<PeopleCardsSectionProps> = ({
  header,
  ctaButton,
  isLight,
  ...rest
}) => (
  <PageSection isLight={isLight}>
    {header ? <SectionHeader {...header} ctaButton={ctaButton} /> : null}

    <PeopleCardsContent {...rest} />
  </PageSection>
);

export const PeopleCardsContent: React.FC<Omit<PeopleCardsSectionProps, 'header'>> = ({
  peopleList,
  hasInfoCard,
  isCollapsed,
  is3Columns,
  columnSize,
  infoBlock,
  alignImages = false
}) => {
  if (!peopleList?.length) {
    return null;
  }

  const showLastRowItem = hasInfoCard && !!infoBlock?.headerText && !!infoBlock?.descriptionText;

  return (
    <CardGrid
      isCollapsed={isCollapsed}
      is3Columns={is3Columns}
      itemsLength={peopleList.length}
      lastRowFillItem={showLastRowItem ? <InfoBlock {...infoBlock} /> : null}
      show3ElementsMobile
      hasBigRowGap
      columnSize={columnSize}
    >
      {peopleList.map((person) => (
        <PersonItem
          {...person}
          key={person._key}
          columnSize={columnSize}
          alignImages={alignImages}
        />
      ))}
    </CardGrid>
  );
};

export default PeopleCardsSection;
