import React from 'react';
import NextImage, { type SanityImage } from '@konsus/image';
import { Box, Button, previewMediaValueAtom } from '@konsus/superside-kit';
import type { SlideType } from '@konsus/lego/src/components/VisualAsset/types';
import { useAtomMutator } from '@konsus/atoms';
import { SanityBlockComponent } from '../../SanityBlockComponent';

interface RichTextImageProps {
  node: {
    _key: string;
    image: SanityImage;
    altText: string;
    captionText?: any[];
    maxWidth?: number;
    hashtags?: string[];
  };
  showPreviewModal?: boolean;
  slides?: SlideType[];
  previewModalTitle?: string;
}

export const RichTextImage: React.FC<RichTextImageProps> = ({
  node,
  showPreviewModal,
  slides,
  previewModalTitle
}) => {
  const { image, altText, captionText, maxWidth, hashtags, _key } = node;

  const setPreviewMediaValue = useAtomMutator(previewMediaValueAtom);

  const content = <NextImage layout='responsive' image={image} alt={altText} />;

  const imageCLickHandler = () => {
    if (slides?.length) {
      const index = slides.findIndex((item) => item._key === _key);

      setPreviewMediaValue({
        slides,
        alt: altText,
        title: previewModalTitle,
        activeIndex: index,
        hashtags
      });
    } else {
      setPreviewMediaValue({ image, alt: altText, title: previewModalTitle, hashtags });
    }
  };

  return (
    <Box className='rte-sub rte-sub-image'>
      <Box
        style={{
          '--image-max-width-var-6107': maxWidth ? `${maxWidth}px` : '100%'
        }}
        className={'mx-auto w-full max-w-[var(--image-max-width-var-6107)]'}
      >
        {showPreviewModal ? (
          <Button plain onClick={imageCLickHandler} className={'cursor-zoom-in'}>
            {content}
          </Button>
        ) : (
          content
        )}

        {captionText ? (
          <Box
            margin={{ top: '4' }}
            className={
              '1280:[&_p]:text-bor-base 1280:mt-6 768:[&_p]:text-bor-sm [&_p]:text-bor-xs [&_p]:mb-0 [&_p]:mt-0 [&_p]:leading-6 [&_p]:text-[rgba(15,33,51,0.6)]'
            }
          >
            <SanityBlockComponent blocks={captionText} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
