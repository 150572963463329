import React from 'react';
import Link from 'adapter/next/link';
import { Box, cn } from '@superside/ui';
import { Icon } from '@konsus/superside-kit';
import {
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Globe
} from '@konsus/superside-kit/src/images/icons';
import NextImage from '@konsus/image';
import { type PersonItemProps } from './types';

const icons = {
  linkedInLink: Linkedin,
  twitterLink: Twitter,
  facebookLink: Facebook,
  instagramLink: Instagram,
  websiteLink: Globe
};

const PersonItem: React.FunctionComponent<PersonItemProps> = ({
  name,
  descriptor,
  profileImage,
  socials = {},
  columnSize = 3,
  alignImages
}) => {
  const img = (
    <NextImage
      image={profileImage}
      alt={descriptor}
      requestWidth={400}
      className='h-full w-full object-cover'
    />
  );

  return (
    <article className='relative flex w-full flex-col'>
      {profileImage ? (
        <Box
          className={cn('h-auto flex-col justify-center overflow-hidden rounded-lg bg-[#EFF2FC]')}
        >
          {alignImages ? (
            <div className='relative pt-[100%]'>
              <div className='absolute left-0 top-2/4 -translate-y-2/4'>{img}</div>
            </div>
          ) : (
            img
          )}
        </Box>
      ) : null}
      {name ? (
        <Box className={cn('pb-6 pt-8', 'flex-1')}>
          <h4
            className={cn(columnSize > 5 ? 'text-base' : columnSize > 3 ? 'text-lg' : 'text-2xl')}
          >
            {name}
          </h4>
          <span className={cn('font-semibold', columnSize > 3 && 'text-sm')}>{descriptor}</span>
        </Box>
      ) : null}
      {Object.keys(socials).length > 0 ? (
        <Box className='flex-row gap-2'>
          {Object.entries(socials).map((social) =>
            icons[social[0]] ? (
              <Link href={social[1]} target='_blank' key={social[0]} data-testid='socialLink'>
                <Box className='bg-darker hover:bg-black-700 rounded-full p-2 transition-all duration-500'>
                  <Icon
                    data-testid='socialIcon'
                    icon={icons[social[0]]}
                    size={columnSize > 3 ? 'xsmall' : 'small'}
                    color='#fff'
                  />
                </Box>
              </Link>
            ) : null
          )}
        </Box>
      ) : null}
    </article>
  );
};

export default PersonItem;
