import React from 'react';

export const BingoSchmoonImage = () => {
  return (
    <svg width='81' height='80' viewBox='0 0 81 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M80.7998 40.0024C80.7998 62.0942 62.8891 79.9997 40.7974 79.9997C21.5842 79.9997 5.53867 66.4578 1.68187 48.3952C1.09401 45.6363 0.798423 42.8232 0.799994 40.0024C0.799994 17.9106 18.7056 4.05617e-07 40.7973 4.05617e-07C46.0508 -0.000747732 51.2528 1.03343 56.1065 3.04348C60.9602 5.05352 65.3703 8.00005 69.085 11.7148C72.7997 15.4295 75.7463 19.8396 77.7563 24.6933C79.7663 29.5469 80.8005 34.749 80.7998 40.0024Z'
        fill='#27FF84'
      />
      <path
        d='M80.7998 40.0024C80.7998 62.0942 62.8891 79.9997 40.7974 79.9997C21.5842 79.9997 5.53867 66.4578 1.68187 48.3952C1.09401 45.6363 0.798423 42.8232 0.799994 40.0024C0.799994 17.9106 18.7056 4.05617e-07 40.7973 4.05617e-07C46.0508 -0.000747732 51.2528 1.03343 56.1065 3.04348C60.9602 5.05352 65.3703 8.00005 69.085 11.7148C72.7997 15.4295 75.7463 19.8396 77.7563 24.6933C79.7663 29.5469 80.8005 34.749 80.7998 40.0024Z'
        fill='#29FE86'
      />
      <path
        d='M80.8007 40.0027C80.8007 62.0945 62.8901 80.0001 40.7983 80.0001C21.5852 80.0001 5.53961 66.4581 1.6828 48.3955C5.52653 52.8803 10.2962 56.4792 15.6636 58.9447C21.0311 61.4101 26.8689 62.6835 32.7755 62.6773C55.4045 62.6773 73.756 44.3309 73.756 21.6968C73.758 20.103 73.6649 18.5105 73.4773 16.9277C78.2539 23.6731 80.8133 31.7375 80.8007 40.0027Z'
        fill='#1AF479'
      />
      <path
        d='M80.799 40.0032C80.799 62.095 62.8884 80.0006 40.7966 80.0006C32.1504 80.0138 23.7345 77.2139 16.8195 72.0234C23.0147 75.507 30.0047 77.3314 37.1121 77.3196C60.035 77.3196 78.6197 58.7349 78.6197 35.8119C78.6271 30.5089 77.6136 25.254 75.6346 20.334C79.0304 26.3325 80.81 33.1102 80.799 40.0032Z'
        fill='#1BAF4D'
      />
      <path
        d='M11.8581 52.8447C12.8879 52.8447 13.7228 52.0098 13.7228 50.98C13.7228 49.9501 12.8879 49.1152 11.8581 49.1152C10.8282 49.1152 9.99335 49.9501 9.99335 50.98C9.99335 52.0098 10.8282 52.8447 11.8581 52.8447Z'
        fill='#0F2133'
      />
      <path
        d='M46.985 52.8447C48.0149 52.8447 48.8498 52.0098 48.8498 50.98C48.8498 49.9501 48.0149 49.1152 46.985 49.1152C45.9552 49.1152 45.1203 49.9501 45.1203 50.98C45.1203 52.0098 45.9552 52.8447 46.985 52.8447Z'
        fill='#0F2133'
      />
      <path
        d='M31.3991 57.9184C29.254 57.9078 27.1207 57.5992 25.0606 57.0013C24.7975 56.9183 24.5782 56.7343 24.4508 56.4897C24.3234 56.2451 24.2984 55.96 24.3812 55.6969C24.464 55.4338 24.6478 55.2144 24.8923 55.0869C25.1368 54.9593 25.422 54.9341 25.6851 55.0167C25.7504 55.0372 32.2662 57.0347 36.86 54.7025C36.9818 54.6407 37.1146 54.6034 37.2508 54.5929C37.387 54.5824 37.5239 54.5988 37.6538 54.6413C37.7836 54.6837 37.9038 54.7512 38.0076 54.8401C38.1113 54.929 38.1965 55.0374 38.2584 55.1592C38.3202 55.2811 38.3574 55.4139 38.368 55.5501C38.3785 55.6863 38.362 55.8232 38.3196 55.953C38.2772 56.0829 38.2096 56.2031 38.1208 56.3069C38.0319 56.4106 37.9234 56.4958 37.8016 56.5577C35.7612 57.5934 33.4701 57.9184 31.3991 57.9184Z'
        fill='#0F2133'
      />
    </svg>
  );
};
