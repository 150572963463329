import React from 'react';
import { Clock, CalendarBlank } from 'phosphor-react';

export interface RichTextMeetingInfoProps {
  node: {
    duration?: string;
    date?: string;
  };
}

export const RichTextMeetingInfo: React.FC<RichTextMeetingInfoProps> = ({
  node: { duration, date }
}) => {
  return (
    <div className='flex items-center gap-6'>
      {Boolean(duration) && (
        <div className='flex items-center gap-2'>
          <Clock size='1.5rem' />
          <span>{duration}</span>
        </div>
      )}
      {Boolean(date) && (
        <div className='flex items-center gap-2'>
          <CalendarBlank size='1.5rem' />
          <span>{date}</span>
        </div>
      )}
    </div>
  );
};
