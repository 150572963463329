import React, {
  type PropsWithChildren,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { cn } from '@superside/ui';
import { useForwardedRef } from '../../utils';
import { Box } from '../Box';
import css from './Collapsible.module.css';

const tokens = {
  speed: {
    name: '--speed',
    val: 'var(--speed)'
  }
};

const collapsibleStyles = {
  animatingOrClosed: 'opacity-0',
  horizontal: {
    base: cn(css.transitionW, 'max-w-0 opacity-0'),
    open: '!opacity-100 max-w-[unset]'
  },
  vertical: {
    base: cn(css.transitionH, 'max-h-0 opacity-0'),
    open: '!opacity-100 max-h-[unset]'
  }
};

export interface CollapsibleProps {
  direction?: 'horizontal' | 'row';
  open: boolean;
}

export const Collapsible = forwardRef<HTMLDivElement, PropsWithChildren<CollapsibleProps>>(
  ({ children, direction, open: openArg }, ref) => {
    const [open, setOpen] = useState(openArg);
    const [animate, setAnimate] = useState(false);
    const [size, setSize] = useState<number>();
    const [speed, setSpeed] = useState(200);
    const dimension = direction === 'horizontal' ? 'width' : 'height';
    const containerRef = useForwardedRef(ref);

    // When the caller changes openArg, trigger animation
    useEffect(() => {
      if (openArg !== open) {
        setAnimate(true);
        setOpen(openArg);
      }
    }, [open, openArg]);

    // Clean up animation state on transition end
    useEffect(() => {
      const container = containerRef.current;

      if (container) {
        const onTransitionEnd = (e: TransitionEvent) => {
          if (e.propertyName === 'opacity') {
            return;
          }

          setAnimate(false);
          setSize(undefined);
          const cssPropName = getCssPropertyName(dimension);

          // @ts-expect-error error TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'
          container.style[cssPropName] = null;
        };

        container.addEventListener('transitionend', onTransitionEnd);

        return () => {
          container.removeEventListener('transitionend', onTransitionEnd);
        };
      }
    }, [containerRef, dimension]);

    useEffect(() => {
      if (animate) {
        const container = containerRef.current;
        const minSpeed = 200;
        const baseline = 500;
        // get the desired size by unsetting the max temporarily

        const cssPropName = getCssPropertyName(dimension);

        if (container) {
          // @ts-expect-error error TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'
          container.style[cssPropName] = 'unset';
          const rect = container.getBoundingClientRect();

          // @ts-expect-error error TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'
          container.style[cssPropName] = 'initial';
          const nextSize = rect[dimension];
          // start with the max set to the size we are starting from

          // @ts-expect-error error TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'
          container.style[cssPropName] = open ? 0 : `${nextSize}px`;
          setSize(nextSize);
          const nextSpeed = Math.max((nextSize / baseline) * minSpeed, minSpeed);

          setSpeed(nextSpeed);
        }
      }
    }, [animate, containerRef, dimension, open]);

    useLayoutEffect(() => {
      if (animate && size) {
        const container = containerRef.current;

        requestAnimationFrame(() => {
          // Change the max to where we want to end up, the transition will
          // animate to get there. We do this in an animation frame to
          // give our starter setting a chance to fully render.
          const cssPropName = getCssPropertyName(dimension);

          if (container) {
            // @ts-expect-error error TS7015: Element implicitly has an 'any' type because index expression is not of type 'number'
            container.style[cssPropName] = open ? `${size}px` : 0;
          }
        });
      }
    }, [animate, containerRef, dimension, open, size]);

    const styles = direction === 'row' ? collapsibleStyles.horizontal : collapsibleStyles.vertical;

    return (
      <Box
        style={{
          [tokens.speed.name]: `${speed}ms`
        }}
        className={cn(
          styles.base,
          open && styles.open,
          (animate || open) && collapsibleStyles.animatingOrClosed
        )}
        aria-hidden={!open}
        ref={containerRef}
      >
        {children}
      </Box>
    );
  }
);

const getCssPropertyName = (dimension: string) =>
  'max' + dimension.charAt(0).toUpperCase() + dimension.slice(1);

Collapsible.displayName = 'Collapsible';

export default Collapsible;
