import React from 'react';
import { cn } from '@superside/ui';

export const HighlightBlockBlue = (props) => {
  return (
    <span
      className={cn(
        'mb-10 mt-10 block bg-[#f2f6ff] p-6 max-[576px]:relative max-[576px]:pl-0 max-[576px]:pr-0 max-[576px]:[&:after]:absolute max-[576px]:[&:after]:right-[-24px] max-[576px]:[&:after]:top-0 max-[576px]:[&:after]:block max-[576px]:[&:after]:h-full max-[576px]:[&:after]:w-6 max-[576px]:[&:after]:bg-[#f2f6ff] max-[576px]:[&:before]:absolute max-[576px]:[&:before]:left-[-24px] max-[576px]:[&:before]:top-0 max-[576px]:[&:before]:block max-[576px]:[&:before]:h-full max-[576px]:[&:before]:w-6 max-[576px]:[&:before]:bg-[#f2f6ff]',
        props.className
      )}
    >
      <span
        className={
          'max-[576px]:text-bor-lg text-[24px] font-light leading-[1.8] text-[#5989f3] max-[576px]:leading-[2]'
        }
      >
        {props.children}
      </span>
    </span>
  );
};
