import React from 'react';

export const BingoPartySchmoonImage = () => {
  return (
    <svg
      width='80'
      height='116'
      viewBox='0 0 80 116'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M79.9998 76.0024C79.9998 98.0942 62.0891 116 39.9974 116C20.7842 116 4.73869 102.458 0.88188 84.3952C0.294024 81.6363 -0.00156505 78.8232 6.23137e-06 76.0024C6.23137e-06 53.9106 17.9056 36 39.9974 36C45.2508 35.9993 50.4529 37.0334 55.3065 39.0435C60.1602 41.0535 64.5703 44 68.285 47.7148C71.9997 51.4295 74.9463 55.8396 76.9563 60.6933C78.9664 65.5469 80.0006 70.749 79.9998 76.0024V76.0024Z'
        fill='#27FF84'
      />
      <path
        d='M79.9998 76.0024C79.9998 98.0942 62.0891 116 39.9974 116C20.7842 116 4.73869 102.458 0.88188 84.3952C0.294024 81.6363 -0.00156505 78.8232 6.23137e-06 76.0024C6.23137e-06 53.9106 17.9056 36 39.9974 36C45.2508 35.9993 50.4529 37.0334 55.3065 39.0435C60.1602 41.0535 64.5703 44 68.285 47.7148C71.9997 51.4295 74.9463 55.8396 76.9563 60.6933C78.9664 65.5469 80.0005 70.749 79.9998 76.0024Z'
        fill='#29FE86'
      />
      <path
        d='M80.0007 76.0027C80.0007 98.0945 62.0901 116 39.9983 116C20.7852 116 4.73962 102.458 0.882812 84.3955C4.72654 88.8803 9.4962 92.4792 14.8637 94.9447C20.2311 97.4101 26.0689 98.6835 31.9755 98.6773C54.6045 98.6773 72.956 80.3309 72.956 57.6968C72.958 56.103 72.8649 54.5105 72.6773 52.9277C77.4539 59.6731 80.0133 67.7375 80.0007 76.0027Z'
        fill='#1AF479'
      />
      <path
        d='M79.9991 76.0032C79.9991 98.095 62.0884 116.001 39.9966 116.001C31.3504 116.014 22.9345 113.214 16.0195 108.023C22.2147 111.507 29.2047 113.331 36.3121 113.32C59.2351 113.32 77.8198 94.7349 77.8198 71.8119C77.8271 66.5089 76.8136 61.254 74.8346 56.334C78.2304 62.3325 80.01 69.1102 79.9991 76.0032V76.0032Z'
        fill='#1BAF4D'
      />
      <path
        d='M11.0581 88.8447C12.088 88.8447 12.9228 88.0098 12.9228 86.98C12.9228 85.9501 12.088 85.1152 11.0581 85.1152C10.0282 85.1152 9.19336 85.9501 9.19336 86.98C9.19336 88.0098 10.0282 88.8447 11.0581 88.8447Z'
        fill='#0F2133'
      />
      <path
        d='M46.185 88.8447C47.2149 88.8447 48.0498 88.0098 48.0498 86.98C48.0498 85.9501 47.2149 85.1152 46.185 85.1152C45.1552 85.1152 44.3203 85.9501 44.3203 86.98C44.3203 88.0098 45.1552 88.8447 46.185 88.8447Z'
        fill='#0F2133'
      />
      <path
        d='M30.5992 93.9184C28.454 93.9078 26.3207 93.5992 24.2606 93.0013C23.9976 92.9183 23.7783 92.7343 23.6509 92.4897C23.5235 92.2451 23.4984 91.96 23.5812 91.6969C23.664 91.4338 23.8478 91.2144 24.0923 91.0869C24.3368 90.9593 24.622 90.9341 24.8851 91.0167V91.0167C24.9504 91.0372 31.4662 93.0347 36.06 90.7025C36.1818 90.6407 36.3146 90.6034 36.4508 90.5929C36.587 90.5824 36.7239 90.5988 36.8538 90.6413C36.9836 90.6837 37.1039 90.7512 37.2076 90.8401C37.3113 90.929 37.3966 91.0374 37.4584 91.1592C37.5202 91.2811 37.5575 91.4139 37.568 91.5501C37.5785 91.6863 37.5621 91.8232 37.5196 91.953C37.4772 92.0829 37.4097 92.2031 37.3208 92.3069C37.2319 92.4106 37.1235 92.4958 37.0016 92.5577C34.9612 93.5934 32.6701 93.9184 30.5992 93.9184Z'
        fill='#0F2133'
      />
      <path
        d='M68.0551 8.98028L68.1002 9.03745C70.8948 14.037 74.2312 16.1845 74.2312 16.1845L74.2651 16.1901C74.5265 9.5997 74.6268 4.52302 74.3446 4.36696C73.943 4.15139 71.4603 6.06084 68.0559 8.97544L68.0551 8.98028Z'
        fill='#CB03AC'
      />
      <path
        d='M62.6304 13.7469C63.0399 14.6346 65.7543 19.9985 73.8248 26.0256C73.9913 22.6494 74.1465 19.2514 74.2714 16.1863L74.2376 16.1807C74.2376 16.1807 70.8955 14.0372 68.1058 9.03852L68.0606 8.98134C66.418 10.3789 64.5676 12.0105 62.6344 13.7525L62.6304 13.7469Z'
        fill='#70026D'
      />
      <path
        d='M62.6293 13.7436C60.7878 15.3816 58.8747 17.122 56.9842 18.8463C56.9842 18.8463 59.7263 26.8335 73.3087 36.0641C73.4715 32.9804 73.654 29.5074 73.8229 26.0272C65.7524 20.0001 63.038 14.6362 62.6285 13.7485L62.6293 13.7436Z'
        fill='#CB03AC'
      />
      <path
        d='M50.5535 24.799L50.6172 24.8642C50.6172 24.8642 55.2762 36.2496 72.7132 46.8092C72.7132 46.8092 72.9808 42.2224 73.3104 36.0607C59.728 26.83 56.9859 18.8429 56.9859 18.8429C54.7411 20.906 52.5293 22.9498 50.5543 24.7942L50.5535 24.799Z'
        fill='#70026D'
      />
      <path
        d='M42.6877 32.1777C42.6877 32.1777 46.7445 39.6074 56.2596 43.6625C65.7787 47.7233 72.712 46.8142 72.712 46.8142C55.275 36.2546 50.6161 24.8692 50.6161 24.8692L50.5523 24.804C46.0306 29.0044 42.6869 32.1825 42.6869 32.1825L42.6877 32.1777Z'
        fill='#CB03AC'
      />
      <ellipse
        cx='51.6498'
        cy='48.2388'
        rx='18.364'
        ry='6.99581'
        transform='rotate(21.4972 51.6498 48.2388)'
        fill='url(#paint0_radial_16410_74069)'
      />
      <ellipse
        cx='51.9097'
        cy='48.3714'
        rx='11.4416'
        ry='4.3587'
        transform='rotate(21.4972 51.9097 48.3714)'
        fill='#1AF479'
      />
      <defs>
        <radialGradient
          id='paint0_radial_16410_74069'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(51.6498 48.2388) rotate(90) scale(6.99581 18.364)'
        >
          <stop stopColor='#1AF479' />
          <stop offset='1' stopColor='#1AF479' stopOpacity='0' />
        </radialGradient>
      </defs>
    </svg>
  );
};
