import React, { type FC } from 'react';
import Head from 'adapter/next/head';
import type { SanityVideoStructuredData } from 'sanity.types';

type Clip = {
  '@type': 'Clip';
  name: string;
  url: string;
  startOffset: number;
  endOffset?: number;
};

export const VideoStructuredData: FC<SanityVideoStructuredData> = (props) => {
  const {
    name,
    description,
    thumbnailUrl,
    duration,
    uploadDate,
    contentUrl,
    embedUrl,
    hasPart,
    userInteractionCount
  } = props;

  const finalHasPart = hasPart?.map(({ name, url, startOffset, endOffset }) => {
    const clips: Clip = {
      '@type': 'Clip',
      name,
      url,
      startOffset
    };

    if (endOffset) {
      clips.endOffset = endOffset;
    }

    return clips;
  });

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name,
    description,
    thumbnailUrl,
    duration,
    uploadDate,
    contentUrl,
    embedUrl,
    hasPart: finalHasPart,
    interactionStatistic: {
      '@type': 'InteractionCounter',
      interactionType: { '@type': 'WatchAction' },
      userInteractionCount
    }
  };

  if (name && description && thumbnailUrl && uploadDate) {
    return (
      <Head>
        <script
          data-testid='video-structured-data'
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData)
          }}
        />
      </Head>
    );
  }

  return null;
};

export default VideoStructuredData;
