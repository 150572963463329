import React, { useState } from 'react';
import {
  Box,
  ExternalContentWrapper,
  RelativeBox,
  contentWrapperStyle
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import NextImage from '@konsus/image';
import { cn, Heading } from '@superside/ui';
import Superscript from '../../components/Superscript';
import { useSubscribeForm } from '../../utils/useSubscribeForm';
import SubscribeForm from '../SubscribeForm';
import {
  bannerBox,
  bannerBoxShadow,
  container,
  content,
  descriptionStyle,
  desktopBgImageStyle,
  emailCapturingBox,
  headingStyle,
  image,
  mobileThankYouImage,
  smallBannerBoxShadow,
  submittedContainerStyle,
  thankYouMessageContent
} from './styles';
import { CompactSubscribeSection } from './CompactSubscribeSection';
import { type BannerProps } from './types';

export const BannerSubscribeContent: React.FC<Omit<BannerProps, 'variant'>> = ({
  desktopBgImage,
  thankYouDesktopBgImage = desktopBgImage,
  thankYouMobileImage,
  backgroundColor,
  copy,
  thankYouCopy,
  form,
  boxShadow = true,
  smallBoxShadow,
  compact
}) => {
  const { superscriptText, headerText, subHeaderRichText } = copy;
  const {
    submitButtonText = 'Subscribe',
    placeholderText,
    hubspotFormId,
    trackingEvents
  } = form || {};

  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSuccess = () => {
    setFormSubmitted(true);
  };

  const { subscribeFormHandler } = useSubscribeForm({
    onSuccess,
    formId: hubspotFormId,
    trackingEvents
  });

  return compact ? (
    <CompactSubscribeSection
      thankYouMobileImage={thankYouMobileImage}
      backgroundColor={backgroundColor}
      thankYouCopy={thankYouCopy}
      copy={copy}
      form={form}
      formSubmitted={formSubmitted}
      subscribeFormHandler={subscribeFormHandler}
    />
  ) : (
    <RelativeBox
      className={cn(
        bannerBox,
        boxShadow && !smallBoxShadow && bannerBoxShadow,
        boxShadow && smallBoxShadow && smallBannerBoxShadow,
        formSubmitted && submittedContainerStyle,
        'light <1024:flex-col <1024:gap-y-4 <1024:p-6 1024:p-12 flex-row items-center overflow-visible rounded-lg'
      )}
      background={backgroundColor}
    >
      {desktopBgImage && !formSubmitted ? (
        <Box className={cn(image, desktopBgImageStyle)}>
          <NextImage
            layout='fill'
            objectFit='cover'
            objectPosition='center'
            image={desktopBgImage}
            alt=''
            loading='lazy'
          />
        </Box>
      ) : null}

      {thankYouDesktopBgImage && formSubmitted ? (
        <Box className={cn(image, desktopBgImageStyle)}>
          <NextImage
            layout='fill'
            objectFit='cover'
            objectPosition='center'
            image={thankYouDesktopBgImage}
            alt='Thanks for subscribing!'
            loading='lazy'
          />
        </Box>
      ) : null}

      {thankYouMobileImage && formSubmitted ? (
        <Box className={cn(mobileThankYouImage)}>
          <NextImage image={thankYouMobileImage} alt='Thanks for subscribing!' loading='lazy' />
        </Box>
      ) : null}

      <Box className={cn(container, '768:p-2 1024:p-4')}>
        {formSubmitted ? (
          <Box className={cn(content, thankYouMessageContent)}>
            <Heading className={headingStyle} level='h3'>
              <Box dangerouslySetInnerHTML={{ __html: thankYouCopy?.headerText as string }} />
            </Heading>
            {thankYouCopy?.subHeaderRichText ? (
              <Box className={descriptionStyle}>
                <ExternalContentWrapper
                  className={cn(contentWrapperStyle, 'contentWrapperStyle')}
                  margin={{ top: '4' }}
                >
                  <SanityBlockComponent blocks={thankYouCopy?.subHeaderRichText} />
                </ExternalContentWrapper>
              </Box>
            ) : null}
          </Box>
        ) : (
          <>
            <Box className={content}>
              {superscriptText ? <Superscript content={superscriptText} /> : null}
              <Heading className={headingStyle} level='h3'>
                <Box dangerouslySetInnerHTML={{ __html: headerText }} />
              </Heading>
              {subHeaderRichText ? (
                <Box className={descriptionStyle}>
                  <ExternalContentWrapper className={contentWrapperStyle} margin={{ top: '4' }}>
                    <SanityBlockComponent blocks={subHeaderRichText} />
                  </ExternalContentWrapper>
                </Box>
              ) : null}
            </Box>

            <Box margin={{ top: '6' }} className={emailCapturingBox}>
              <SubscribeForm
                onSubmit={subscribeFormHandler}
                background='transparent'
                placeholderText={placeholderText}
                buttonLabel={submitButtonText}
              />
            </Box>
          </>
        )}
      </Box>
    </RelativeBox>
  );
};
