import React from 'react';
import { Box, BreakpointBasisBox } from '@konsus/superside-kit';

export const CardsComponent = (props) => {
  const { ImageComponent } = props;

  const cards = props.node.cards;
  const size = (props.node.size || '').toLowerCase();

  let md, xxlg, xs;

  if (size === 'small') {
    md = 'calc(100% / 4 - 12px)';
    xxlg = 'calc(100% / 2 - 12px)';
    xs = 'calc(100% / 2 - 12px)';
  }
  if (size === 'three-in-a-row') {
    md = 'calc(100% / 2 - 12px)';
    xxlg = 'calc(100% / 3 - 16px)';
    xs = 'calc(100% / 2 - 12px)';
  }
  if (size === 'four-in-a-row') {
    md = 'calc(100% / 4 - 12px)';
    xxlg = 'calc(100% / 4 - 18px)';
    xs = 12;
  }

  return (
    <div>
      <Box flex direction='row' justify='between' wrap>
        {cards.map((card) => {
          let header = card.header;

          if (card.url) {
            header = (
              <a href={card.url} target='_blank' rel='noreferrer'>
                {header}
              </a>
            );
          }

          return (
            <BreakpointBasisBox
              key={card._key}
              xxlg={xxlg}
              md={md}
              sm={xs}
              margin={{ bottom: 'medium' }}
            >
              <div className='card-header-img'>
                {card.image && (
                  <ImageComponent
                    sanityConfig={props.options}
                    node={card.image}
                    alt={card.alt ? card.alt : card.header}
                    width={400}
                    mobileWidth={350}
                    fit='max'
                    className='card-img'
                  />
                )}
              </div>
              <div>
                {header && <span className={'font-bold'}>{header}</span>}
                <div>{card.description}</div>
              </div>
            </BreakpointBasisBox>
          );
        })}
      </Box>
    </div>
  );
};
