import React from 'react';
import { BingoGame } from './BingoGame';

export const RichTextGame = ({ node }) => {
  const { gameType = 'bingoGame', ...rest } = node;

  if (gameType === 'bingoGame') {
    return <BingoGame {...rest} />;
  }
};
