import React from 'react';
import { type TagProps, Tag } from '@superside/ui';

export interface RichTextTagsProps {
  node: {
    tags: TagProps[];
  };
}

export const RichTextTags: React.FC<RichTextTagsProps> = ({ node: { tags = [] } }) => {
  if (!Array.isArray(tags) || tags.length === 0) {
    return <></>;
  }

  return (
    <div className='my-6 flex flex-wrap gap-2'>
      {tags.map((tagProps, i) => (
        <Tag key={`${tagProps.label}-${i}`} {...tagProps} />
      ))}
    </div>
  );
};
