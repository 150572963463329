/* eslint-disable react/display-name */
import React from 'react';
// import LazyLoad from 'react-lazyload';

// const defaultCheckIsLazy = (props) => !props.isNotLazy;

export const createLazyLoadableComponent =
  (
    Component: React.FC
    // lazyLoadProps = {}
  ) =>
  (props: JSX.IntrinsicAttributes) => {
    return (
      <Component {...props} />

      // const height = props.height ? parseInt(props.height, 10) : 110;
      // const { checkIsLazy = defaultCheckIsLazy, ...restLazyLoadProps } = lazyLoadProps;

      // return checkIsLazy(props) ? (
      // <LazyLoad height={height} offset={100} once {...restLazyLoadProps}>
      // <Component {...props} />
      // ) : (
      // </LazyLoad>
    );
    // );
  };
