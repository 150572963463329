import React from 'react';
import { type TimeUnitsType } from '../types';
import { RichTextSuperscript } from '../../RichTextSuperscript';
import TimeUnit from './TimeUnit';
import TimeDivider from './TimeDivider';

export const Countdown: React.FC<{ timeUnits: TimeUnitsType; title: string }> = ({
  timeUnits,
  title
}) => {
  const { days, hours, minutes, seconds } = timeUnits;

  return (
    <>
      {title ? <RichTextSuperscript>{title}</RichTextSuperscript> : null}

      <div className='1024:py-4 1024:px-8 bg-white-100/10 mt-2 flex w-max flex-1 flex-wrap rounded-lg p-2 backdrop-blur-lg'>
        <TimeUnit title={days} subtitle='days' />
        <TimeDivider />
        <TimeUnit title={hours} subtitle='hours' />
        <TimeDivider />
        <TimeUnit title={minutes} subtitle='minutes' />
        <TimeDivider />
        <TimeUnit title={seconds} subtitle='seconds' />
      </div>
    </>
  );
};

export default Countdown;
