import React, { useState, useCallback, useEffect } from 'react';
import { cva } from 'class-variance-authority';
import { CheckSquare, Square } from 'phosphor-react';
import { cn } from '@superside/ui';

type IconSize = 'small' | 'medium' | 'large' | 'xlarge';

interface ContentCheckboxesProps {
  node: {
    items?: string[];
    listItems?: {
      text: string;
      link?: string;
    }[];
    icon?: string;
    iconSize?: IconSize;
    isStatic?: boolean;
    withState?: boolean;
    _key: string;
    marginTop?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
    marginBottom?: 'none' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'xxxlarge';
    gap?: 'xxsmall' | 'xsmall' | 'small' | 'medium';
  };
}

interface PseudoCheckboxProps {
  text: string;
  isStatic?: boolean;
  withState?: boolean;
  index?: number;
  _key: string;
}

interface ListItemProps {
  item: {
    link?: string;
    text: string;
  };
  icon?: string;
  iconSize?: IconSize;
}

const wrapperDivCva = cva(['flex flex-col items-start'], {
  variants: {
    marginTop: {
      none: 'mt-0',
      small: 'mt-4',
      medium: 'mt-6',
      large: 'mt-8',
      xlarge: 'mt-12',
      xxlarge: 'mt-16',
      xxxlarge: 'mt-22'
    },
    marginBottom: {
      none: 'mb-0',
      small: 'mb-4',
      medium: 'mb-6',
      large: 'mb-8',
      xlarge: 'mb-12',
      xxlarge: 'mb-16',
      xxxlarge: 'mb-22'
    },
    gap: {
      xxsmall: 'gap-1',
      xsmall: 'gap-2',
      small: 'gap-4',
      medium: 'gap-6'
    }
  }
});

const textSizeCva = cva(['mr-2'], {
  variants: {
    iconSize: {
      small: 'text-bor-sm leading-normal',
      medium: 'text-bor-base leading-normal',
      large: 'text-bor-lg leading-normal',
      xlarge: 'text-bor-xl leading-normal'
    }
  }
});

export const ContentCheckboxes: React.FC<ContentCheckboxesProps> = (props) => {
  const { node } = props;

  const {
    items,
    listItems,
    marginTop = 'none',
    marginBottom = 'none',
    icon,
    iconSize,
    isStatic,
    gap = 'small',
    withState = false,
    _key,
    ...rest
  } = node;

  if (!items && !listItems) {
    return null;
  }

  return (
    <div className={wrapperDivCva({ marginTop, marginBottom, gap })} {...rest}>
      {items?.length
        ? items.map((item, index) => (
            <PseudoCheckbox
              isStatic={isStatic}
              key={`checkbox-${item.replace(/\s+/g, '')}`}
              withState={withState}
              index={index}
              text={item}
              _key={_key}
            />
          ))
        : null}

      {listItems && listItems.length
        ? listItems.map((item, index) => (
            <ListItem
              key={`listItem-${index}-${item.text.replace(/\s+/g, '')}`}
              item={item}
              icon={icon}
              iconSize={iconSize}
            />
          ))
        : null}
    </div>
  );
};

const PseudoCheckbox: React.FC<PseudoCheckboxProps> = (props) => {
  const { text, isStatic = false, withState, index, _key } = props;

  const name = `${_key}-${index}`;
  const [checked, setChecked] = useState(isStatic);

  const clickHandler = useCallback(() => {
    if (!isStatic) {
      setChecked(!checked);
    }

    if (!isStatic && withState && !checked) {
      localStorage.setItem(name, '1');
    }

    if (!isStatic && withState && checked) {
      localStorage.removeItem(name);
    }
  }, [checked, isStatic]);

  useEffect(() => {
    if (withState && localStorage.getItem(name)) {
      setChecked(true);
    }
  }, []);

  return (
    <div
      onClick={clickHandler}
      className={cn('flex flex-row items-start gap-4', !isStatic && 'cursor-pointer')}
    >
      {checked ? (
        <CheckSquare size={24} className='text-green-700' />
      ) : (
        <Square size={24} className='text-green-700' />
      )}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

const ListItem: React.FC<ListItemProps> = (props) => {
  const { item, icon, iconSize = 'medium', ...rest } = props;

  const { link, text } = item;

  return (
    <div className='flex flex-row items-center' {...rest}>
      {icon ? (
        <span className={textSizeCva({ iconSize })} dangerouslySetInnerHTML={{ __html: icon }} />
      ) : null}

      {link ? (
        <a
          className='cursor-pointer font-normal text-indigo-500 no-underline shadow-none hover:font-medium hover:underline'
          href={link}
        >
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </a>
      ) : (
        <span dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </div>
  );
};
