export const getTextSelection = () => {
  let text: string | undefined = '';

  if (window.getSelection) {
    text = window.getSelection()?.toString();
  } else {
    // @ts-ignore
    if (document.selection) {
      // @ts-ignore
      text = document.selection.createRange().text;
    }
  }

  return text;
};

export default getTextSelection;
