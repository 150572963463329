import React from 'react';
import { Box, Text } from '@konsus/superside-kit';
import { Heading } from '@superside/ui';

export const SectionHeader = (props) => {
  const { ImageComponent, options, node } = props;
  const { heading, label, description, items } = node;

  return (
    <Box>
      <Text
        className={
          'max-[576px]:[html_&]:text-bor-sm [html_&]:text-[#5989f3] max-[576px]:[html_&]:mb-2 max-[576px]:[html_&]:uppercase max-[576px]:[html_&]:leading-[17px]'
        }
        alternative
        weight='bold'
        size='large'
      >
        {label}
      </Text>
      <Heading
        className={
          '[html_&]:!mb-0 [html_&]:!mt-0 [html_&]:text-[40px] [html_&]:font-bold [html_&]:leading-[1.5] max-[576px]:[html_&]:text-[24px] max-[576px]:[html_&]:leading-[1.4]'
        }
        level='h3'
      >
        {heading}
      </Heading>
      {items && items.length ? (
        <Box
          className={
            'border-b border-t border-[#dadee6] pt-6 max-[576px]:mb-0 max-[576px]:border-b-0 max-[576px]:border-t-0 max-[576px]:pt-0'
          }
          wrap
          direction='row'
          gap='xxlarge'
          margin={{ top: 'xlarge', bottom: 'large' }}
        >
          {items.map((item, indx) => (
            <Box className={'mb-6 [&_img]:h-14'} key={indx}>
              {item.label ? (
                <Text
                  className={'max-[576px]:text-base max-[576px]:font-normal'}
                  alternative
                  weight='bold'
                  size='large'
                  margin={{ bottom: 'xsmall' }}
                >
                  {item.label}
                </Text>
              ) : null}
              {item.image || item.heading || item.subHeading ? (
                <Box direction='row' align='center'>
                  {item.image ? (
                    <ImageComponent
                      sanityConfig={options}
                      {...item.image}
                      node={item.image.node}
                      fit='max'
                    />
                  ) : null}

                  {item.heading || item.subHeading ? (
                    <Box className={'!mt-0'} margin={{ left: 'medium' }}>
                      {item.heading ? (
                        <Text alternative weight='bold' size='large'>
                          {item.heading}
                        </Text>
                      ) : null}

                      {item.subHeading ? (
                        <Text className={'text-[#797b7f]'}>{item.subHeading}</Text>
                      ) : null}
                    </Box>
                  ) : null}
                </Box>
              ) : null}
            </Box>
          ))}

          {description ? <Text margin={{ bottom: 'medium' }}>{description}</Text> : null}
        </Box>
      ) : null}
    </Box>
  );
};
