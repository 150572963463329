import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Text } from '@konsus/superside-kit';
import BaseSanityImage, { type ImageComponentProps } from './ImageComponent';

export interface SanityImageProps
  extends Pick<ImageComponentProps, 'node' | 'width' | 'height' | 'isLazy'> {
  caption?: string;
}

export const SanityImage: React.FC<SanityImageProps> = (props) => {
  const { caption, node, width, height, isLazy } = props;

  if (!node) {
    return null;
  }

  return (
    <>
      <BaseSanityImage width={width} height={height} node={node} isLazy={isLazy} />
      {caption ? (
        <Text className={'italic'} color='neutral-dark' margin={{ top: 'xsmall' }}>
          {caption}
        </Text>
      ) : null}
    </>
  );
};

export const useSanityImageLoading = ({ initialLoading = false, onLoad }) => {
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(initialLoading);

  const handleLoad = useCallback(
    (e) => {
      if (e.currentTarget.classList.contains('lazyload')) {
        setLoading(true);

        return;
      }

      setLoading(false);

      if (onLoad) {
        onLoad(e);
      }
    },
    [onLoad]
  );

  useEffect(() => {
    const image = imageRef.current;

    if (!image) {
      setLoading(true);

      return;
    }

    if (image.classList.contains('lazyload') || image.classList.contains('lazyloading')) {
      setLoading(true);

      return;
    }

    if (image.classList.contains('lazyloaded') || (image.complete && image.naturalWidth !== 0)) {
      setLoading(false);
    }
  }, []);

  return {
    imageRef,
    loading,
    setLoading,
    handleLoad
  };
};

export default SanityImage;
