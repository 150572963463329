/* eslint-disable camelcase */
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'adapter/next/router';
import { nanoid } from 'nanoid';
import {
  baseTrackingFieldsGenerator,
  FORM_TRACKING_PROVIDERS,
  TRACKING_EVENT_NAMES,
  useAnalytics
} from '@konsus/analytics';
import { useAtomMutator } from '@konsus/atoms';
import {
  submitChiliPiperFormAndScheduleCall,
  submitChiliPiperForm,
  processAnswersForPayloads,
  generateExtraFields
} from '../../blocks/BookCall/bookCallUtils';
import { hubSpotApiPoint, portalId, isDev, FORM_CONFIG } from '../../blocks/BookCall/utils';
import { prepareHubspotFormData, useEventId } from '../../utils/form';
import { useChiliPiper } from '../../blocks/BookCall/useChiliPiper';
import { useRedirect } from '../../blocks/BookCall/useRedirect';
import GlobalContext from '../../contexts/GlobalContext';
import {
  type FormObjectType,
  SCHEDULING_PROVIDER,
  type PersonalizedVideoType
} from '../../types/form';
import { chilipiperLoaderAtom } from '../ChilipiperLoader/chilipiperLoaderAtom';
import { type Answers } from './type';
import { findVideo, personalizedVideoAtom } from './personalizedVideoUtils';

const trackingProvider = FORM_TRACKING_PROVIDERS.INTERNAL_PROGRESSIVE;

type FormSubmissionOptions = {
  answers: Answers;
  form: FormObjectType;
  onFormSuccess?: () => void;
  onFormError?: () => void;
};

export const useFormSubmission = ({
  answers,
  form: formOptions,
  onFormSuccess,
  onFormError
}: FormSubmissionOptions) => {
  const {
    formId = FORM_CONFIG.formId,
    routerName,
    formName = FORM_CONFIG.formName,
    trackingEvents,
    openScheduler,
    redirectLink,
    personalizedVideos,
    formOutput,
    // TODO remove after formOutput complete release
    isTicket: deprecatedIsTicket,
    isPersonalizedVideo: deprecatedIsPersonalizedVideo
  } = formOptions || {};

  const isPersonalizedVideo = formOutput ? formOutput === 'video' : deprecatedIsPersonalizedVideo;
  const isTicket = formOutput ? formOutput === 'ticket' : deprecatedIsTicket;
  const isRedirect = formOutput
    ? formOutput === 'redirect'
    : !deprecatedIsTicket && !deprecatedIsPersonalizedVideo;
  const isRedirectWithProps = formOutput
    ? formOutput === 'redirectProps'
    : !deprecatedIsTicket && !deprecatedIsPersonalizedVideo;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [extraGeneratedFields, setExtraGeneratedFields] = useState<Record<string, string>>();

  const setPersonalizedVideo = useAtomMutator<PersonalizedVideoType | null>(personalizedVideoAtom);

  const { query, asPath } = useRouter();
  const redirect = useRedirect();
  const { utm, gclid = '' } = useContext(GlobalContext) || {};
  const { track } = useAnalytics();
  const eventId = useEventId();
  const ticketId = nanoid(10);

  const { utm_source = '', utm_medium = '', utm_campaign = '', utm_content = '' } = utm || {};

  useChiliPiper(SCHEDULING_PROVIDER.CHILIPIPER);

  useEffect(() => {
    generateExtraFields(eventId, query.fbclid).then((fields) => {
      const fieldsObject = fields.reduce<Record<string, string>>((acc, field) => {
        acc[field.name] = field.value;

        return acc;
      }, {});

      setExtraGeneratedFields(fieldsObject);
    });
  }, [eventId, query.fbclid]);

  const setChiliPiperLoader = useAtomMutator(chilipiperLoaderAtom);

  const { hubspotPayload, chiliPiperPayload } = processAnswersForPayloads({ answers });

  const commonAnswers = useMemo(
    () => ({
      event_id: eventId,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      gclid,
      ...extraGeneratedFields
    }),
    [eventId, extraGeneratedFields, gclid, utm_campaign, utm_medium, utm_source, utm_content]
  );

  const filteredFields = useMemo(() => {
    const initialData: Answers = {
      ...hubspotPayload,
      ...commonAnswers,
      chilipiper_latest_form: formName
    };

    if (isTicket) {
      initialData.ticket_referral = ticketId;
    }

    if (query?.ticket_referred_by) {
      initialData.ticket_referred_by = query.ticket_referred_by as string;
    }

    delete initialData.fullname;

    return Object.entries(initialData).map(([name, value]) => ({
      name,
      value
    }));
  }, [
    commonAnswers,
    hubspotPayload,
    formName,
    eventId,
    gclid,
    utm_campaign,
    utm_medium,
    utm_source,
    utm_content,
    isTicket,
    query
  ]);

  const handleSubmit = useCallback(async () => {
    const lead: Answers = {
      ...chiliPiperPayload,
      ...commonAnswers,
      Chili_Piper_latest_form__c: formName
    };

    const baseTrackingFields = baseTrackingFieldsGenerator(eventId, formId, trackingEvents, asPath);
    const data = prepareHubspotFormData(filteredFields);

    setIsSubmitting(true);

    return fetch(`${hubSpotApiPoint}/${portalId}/${formId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(async () => {
        track(TRACKING_EVENT_NAMES.FORM_SUBMISSION, {
          ...baseTrackingFields,
          first_name: answers?.firstname,
          last_name: answers?.lastname,
          email: answers?.email,
          company_name: answers?.company,
          company_size: answers?.company_size,
          phone: answers?.phone,
          provider: trackingProvider
        });

        if (isPersonalizedVideo && personalizedVideos) {
          const { keysWithVideos: videoEntries, specificOptions } = personalizedVideos;
          const { persona_type: persona, main_capability_interest: service } = answers || {};

          if (!persona || !service) {
            setIsSubmitting(false);
            onFormError?.();

            throw new Error(`No persona: ${persona} or service: ${service} defined.`);
          }

          const personalizedVideo = findVideo({
            videoEntries,
            specificOptions,
            query: { persona, service }
          });

          setIsSubmitting(false);

          if (!personalizedVideo) {
            onFormError?.();
            throw new Error(`No personalized video found.`);
          } else {
            setPersonalizedVideo(personalizedVideo);
          }

          return;
        }

        const chiliPiperRouter =
          isDev || answers?.email?.includes('@superside.com')
            ? 'test_router'
            : routerName || 'inbound-router';

        if (isTicket) {
          localStorage.setItem(ticketId, `${lead.firstname} ${lead.lastname}`);
        }

        if (openScheduler) {
          await submitChiliPiperFormAndScheduleCall({
            lead,
            routerName: chiliPiperRouter,
            track,
            eventId,
            setChiliPiperLoader
          });
        } else {
          await submitChiliPiperForm({ lead, routerName: chiliPiperRouter, eventId });
        }

        setIsSubmitting(false);

        if (redirectLink && (isTicket || isRedirect)) {
          return redirect(
            isTicket
              ? `${redirectLink}${redirectLink.includes('?') ? '&' : '?'}ticket=${ticketId}`
              : redirectLink,
            false
          );
        } else if (redirectLink && isRedirectWithProps) {
          const queryParams = new URLSearchParams();

          if (answers?.firstname) {
            queryParams.append('first_name', answers.firstname);
          }
          if (answers?.lastname) {
            queryParams.append('last_name', answers.lastname);
          }
          if (answers?.email) {
            queryParams.append('email', answers.email);
          }
          queryParams.append('step', 'sign_up');

          return redirect(`${redirectLink}?${queryParams.toString()}`, false);
        }

        onFormSuccess?.();
      })
      .catch((err) => {
        setIsSubmitting(false);
        onFormError?.();

        track(TRACKING_EVENT_NAMES.FORM_SUBMISSION_FAILURE, {
          ...baseTrackingFields,
          first_name: answers?.firstname,
          last_name: answers?.lastname,
          email: answers?.email,
          company_name: answers?.company,
          company_size: answers?.employees,
          error: err?.message,
          provider: trackingProvider
        });
      });
  }, [
    answers,
    commonAnswers,
    chiliPiperPayload,
    formName,
    eventId,
    filteredFields,
    formId,
    onFormError,
    onFormSuccess,
    openScheduler,
    redirect,
    redirectLink,
    routerName,
    setChiliPiperLoader,
    track,
    trackingEvents,
    isPersonalizedVideo,
    personalizedVideos,
    ticketId,
    isTicket,
    isRedirect,
    setPersonalizedVideo,
    asPath
  ]);

  return { isSubmitting, handleSubmit };
};
