import React from 'react';
import {
  Box,
  ExternalContentWrapper,
  RelativeBox,
  contentWrapperStyle
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import NextImage from '@konsus/image';
import { cn, Heading } from '@superside/ui';
import Superscript from '../../components/Superscript';
import SubscribeForm from '../SubscribeForm';
import {
  bannerBoxShadow,
  compactBannerBox,
  compactContainer,
  content,
  descriptionStyle,
  emailCapturingBox,
  headingStyle,
  mobileThankYouImage,
  thankYouMessageContent
} from './styles';

export const CompactSubscribeSection = ({
  thankYouMobileImage,
  backgroundColor,
  thankYouCopy,
  copy,
  form,
  formSubmitted,
  subscribeFormHandler
}: any) => {
  const { superscriptText, headerText, subHeaderRichText } = copy;
  const { submitButtonText, placeholderText } = form;

  return (
    <RelativeBox className={cn(compactBannerBox, bannerBoxShadow)} background={backgroundColor}>
      {thankYouMobileImage && formSubmitted ? (
        <Box className={cn(mobileThankYouImage)}>
          <NextImage image={thankYouMobileImage} alt='Thanks for subscribing!' loading='lazy' />
        </Box>
      ) : null}
      <Box className={compactContainer} align='start'>
        {formSubmitted ? (
          <Box className={cn(content, thankYouMessageContent)}>
            <Heading className={headingStyle} level='h3'>
              <Box dangerouslySetInnerHTML={{ __html: thankYouCopy.headerText }} />
            </Heading>
            {thankYouCopy?.subHeaderRichText ? (
              <Box className={descriptionStyle}>
                <ExternalContentWrapper
                  className={cn(contentWrapperStyle, 'contentWrapperStyle')}
                  margin={{ top: '4' }}
                >
                  <SanityBlockComponent blocks={thankYouCopy.subHeaderRichText} />
                </ExternalContentWrapper>
              </Box>
            ) : null}
          </Box>
        ) : (
          <Box height='100%' justify='between'>
            <Box className={content}>
              {superscriptText ? <Superscript content={superscriptText} /> : null}
              <Heading className={headingStyle} level='h3'>
                <Box dangerouslySetInnerHTML={{ __html: headerText }} />
              </Heading>
              {subHeaderRichText ? (
                <Box className={descriptionStyle}>
                  <ExternalContentWrapper className={contentWrapperStyle} margin={{ top: '4' }}>
                    <SanityBlockComponent blocks={subHeaderRichText} />
                  </ExternalContentWrapper>
                </Box>
              ) : null}
            </Box>

            <Box margin={{ top: '6' }} className={emailCapturingBox}>
              <SubscribeForm
                onSubmit={subscribeFormHandler}
                background='transparent'
                placeholderText={placeholderText}
                buttonLabel={submitButtonText}
                small
              />
            </Box>
          </Box>
        )}
      </Box>
    </RelativeBox>
  );
};
