import { type QuestionItemType } from './type';

export const defaultQuestions: QuestionItemType[] = [
  {
    label: "What's your full name?",
    type: 'text',
    name: 'fullname',
    placeholder: 'Enter your full name'
  },
  {
    label: "What's your email address?",
    type: 'text',
    name: 'email',
    placeholder: 'Enter your email address'
  },
  {
    label: "What's your company name?",
    type: 'text',
    name: 'company',
    placeholder: 'Enter your company name'
  },
  {
    label: 'Select the size of your company',
    type: 'dropdown',
    options: [
      { value: '1-10', title: '1-10' },
      { value: '11-50', title: '11-50' },
      { value: '51-200', title: '51-200' },
      { value: '201-500', title: '201-500' },
      { value: '501-1000', title: '501-1000' },
      { value: '1000-5000', title: '1000-5000' },
      { value: '5000+', title: '5000+' }
    ],
    name: 'company_size',
    placeholder: 'Enter your company size'
  },
  {
    label: "What's your phone number?",
    type: 'phone',
    name: 'phone',
    placeholder: 'Enter your phone'
  }
];

export const extendedQuestions: QuestionItemType[] = [
  {
    label: 'Select the size of your company',
    type: 'dropdown',
    options: [
      { value: '1-10', title: '1-10' },
      { value: '11-50', title: '11-50' },
      { value: '51-200', title: '51-200' },
      { value: '201-500', title: '201-500' },
      { value: '501-1000', title: '501-1000' },
      { value: '1000-5000', title: '1000-5000' },
      { value: '5000+', title: '5000+' }
    ],
    name: 'company_size',
    placeholder: 'Enter your company size',
    dependentFields: [
      {
        hidden: false,
        name: 'funding',
        options: [{ title: 'Bootstrapped', value: 'Bootstrapped' }],
        label: 'How are you funded?',
        type: 'dropdown',
        required: true,
        placeholder: 'How are you funded?',
        dependentConditionValues: ['1-10']
      },
      {
        required: true,
        dependentConditionValues: ['1-10'],
        hidden: false,
        name: 'design_needs',
        options: [
          { title: 'Yes', value: 'Yes' },
          { title: 'No', value: 'No' }
        ],
        label: 'Do you have ongoing design needs?',
        placeholder: 'Do you have ongoing design needs?',
        type: 'dropdown'
      }
    ]
  },
  {
    label: "What's your phone number?",
    type: 'phone',
    name: 'phone',
    placeholder: 'Enter your phone'
  }
];
