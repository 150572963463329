import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { type Swiper as SwiperType } from 'swiper/types';
import { Autoplay } from 'swiper/modules';
import { CaretLeft, CaretRight } from '@konsus/superside-kit/src/images/icons';
import { PaginationDotsAnimated, Quote } from '../../components';
import { SWITCH_QUOTES_INTERVAL } from '../Quotes/utils';
import { type QuoteSliderProps } from './types';

import 'swiper/css';

export const MultipleQuotes: React.FC<QuoteSliderProps> = ({
  quotes,
  intervalTime = SWITCH_QUOTES_INTERVAL,
  rte
}) => {
  const isSingleQuote = quotes?.length === 1;
  const [swiper, setSwiper] = useState<SwiperType>();
  const [index, setIndex] = useState(0);

  if (!quotes[0]) {
    return null;
  }

  if (isSingleQuote) {
    return (
      <Quote
        quote={quotes[0].quoteRichText}
        author={{
          title: quotes[0].author,
          image: quotes[0].image,
          position: quotes[0].authorContextText
            ? `${quotes[0].authorContextText}, ${quotes[0].company}`
            : ''
        }}
        className='min-h-fit'
        isLight={rte}
      />
    );
  }

  return (
    <>
      <div className='relative flex min-h-fit w-full min-w-0' data-testid='swiper'>
        <Swiper
          onSwiper={(swiper) => setSwiper(swiper)}
          modules={[Autoplay]}
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: intervalTime,
            disableOnInteraction: false
          }}
          className='ml-auto mr-auto h-full w-full'
          onSlideChange={({ realIndex }) => setIndex(realIndex)}
        >
          {quotes.map(({ _key, quoteRichText, author, image, authorContextText, company }) => (
            <SwiperSlide className='h-full w-full' key={_key}>
              <Quote
                quote={quoteRichText}
                author={{
                  title: author,
                  image,
                  position: authorContextText ? `${authorContextText}, ${company}` : ''
                }}
                className='h-full'
                isLight={rte}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div />
      <div className='row 1280:mx-0 mx-auto flex items-center gap-4'>
        {quotes.length > 1 ? (
          <CaretLeft
            className='<1280:hidden cursor-pointer'
            color={rte && '#b7b9bc'}
            size='16px'
            onClick={() => swiper?.slidePrev()}
          />
        ) : null}
        <PaginationDotsAnimated
          index={index}
          changeIndex={(id) => swiper?.slideTo(id)}
          length={quotes.length}
          isLight={rte}
        />
        {quotes.length > 1 ? (
          <CaretRight
            className='<1280:hidden cursor-pointer '
            color={rte && '#b7b9bc'}
            size='16px'
            onClick={() => swiper?.slideNext()}
          />
        ) : null}
      </div>
    </>
  );
};

export default MultipleQuotes;
