import { useState, useEffect, type CSSProperties } from 'react';
import { cn } from '@superside/ui';
import { type IVisualAsset } from '../types';

export const STANDART_16_9_ASPECT_RATIO = '56.25%';
const MAX_HEIGHT = 700;

export const useWistiaAspectRatio = (
  wistiaVideoId: string,
  className: string,
  maxWidth: IVisualAsset['maxWidth'] = '100%'
) => {
  const [wistiaAspectRatio, setWistiaAspectRatio] = useState(STANDART_16_9_ASPECT_RATIO);

  useEffect(() => {
    async function logJSONData() {
      if (wistiaVideoId) {
        await fetch(`https://fast.wistia.com/embed/medias/${wistiaVideoId}.jsonp`)
          .then(async (res) => {
            const data = await res.text();
            const { width, height } = JSON.parse(data.split(';')[0].split('=')[1]).media.assets[0];

            if (maxWidth === 'optimized') {
              setWistiaAspectRatio(`${MAX_HEIGHT / width}%`);
            } else {
              const numericMaxWidth = parseFloat(maxWidth) / 100;

              setWistiaAspectRatio(`${(100 * height) / (width / numericMaxWidth)}%`);
            }
          })
          .catch((err) => {
            setWistiaAspectRatio(STANDART_16_9_ASPECT_RATIO);

            // eslint-disable-next-line no-console
            console.error(err);
          });
      }
    }

    logJSONData();
  }, [wistiaVideoId]);

  return wistiaVideoId
    ? {
        className: cn(
          'w-full',
          className,
          maxWidth === 'optimized' && `h-[${MAX_HEIGHT}px] [&_*]:!max-h-[${MAX_HEIGHT}px]`
        ),
        style: { '--aspect-ratio-var-f0d3': `0 0 ${wistiaAspectRatio} 0` } as CSSProperties
      }
    : {};
};

export default useWistiaAspectRatio;
