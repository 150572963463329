import React, { type FC } from 'react';
import type { SanityVisualAsset } from 'sanity.types';
import { cn } from '@superside/ui';
import { VideoStructuredData } from '../../VideoStructuredData';
import { hiddenDesktopStyle, hiddenMobileStyle } from './commonStyles';

export interface AssetTypeYoutubeVideo
  extends Pick<
    SanityVisualAsset,
    'youtubeVideoData' | 'youtubeVideoDataMobile' | 'youtubeVideoId' | 'youtubeVideoIdMobile'
  > {
  maxWidthClassName?: string;
}

export const YouTube: FC<AssetTypeYoutubeVideo> = (props) => {
  const {
    youtubeVideoId,
    youtubeVideoIdMobile,
    youtubeVideoData,
    youtubeVideoDataMobile,
    maxWidthClassName
  } = props;

  return (
    <>
      <YoutubeWrapper
        youtubeId={youtubeVideoId}
        youtubeData={youtubeVideoData}
        className={cn(hiddenMobileStyle, 'aspect-[1.77] w-full border-0', maxWidthClassName)}
        maxWidthClassName={maxWidthClassName}
      />
      <YoutubeWrapper
        youtubeId={youtubeVideoIdMobile || youtubeVideoId}
        youtubeData={youtubeVideoDataMobile || youtubeVideoData}
        className={cn(hiddenDesktopStyle, 'w-full')}
      />
    </>
  );
};

interface YoutubeWrapperProps {
  youtubeId: AssetTypeYoutubeVideo['youtubeVideoId'];
  youtubeData?: AssetTypeYoutubeVideo['youtubeVideoData'];
  className?: string;
  maxWidthClassName?: string;
}

const YoutubeWrapper: FC<YoutubeWrapperProps> = ({
  youtubeId,
  youtubeData,
  className,
  maxWidthClassName
}) =>
  youtubeId ? (
    <span className={className}>
      {youtubeData && <VideoStructuredData {...youtubeData} />}
      <iframe
        className={cn('aspect-[1.77] w-full border-0', maxWidthClassName)}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      />
    </span>
  ) : null;

export default YouTube;
