import React from 'react';
import { Widget } from '@typeform/embed-react';

export class TypeformComponent extends React.Component<any, any> {
  render() {
    const { id, height = 500, width = 100, opacity = 100, isNotLazy = false } = this.props.node;

    return id ? (
      <Widget
        id={id}
        style={{ width: `${width}%`, height: `${height}px` }}
        lazy={!isNotLazy}
        opacity={opacity}
        // onSubmit={handleSubmit}
        // TODO: Integrate ChiliPiper
      />
    ) : null;
  }
}

export const Typeform = TypeformComponent;
