import React from 'react';
import Link from 'adapter/next/link';
import { Anchor } from '@konsus/superside-kit';
import { type SanityImage } from '@konsus/image';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

const startsWithHttpRe = /^https?:\/\//;

export const ImageLinkComponentContent = (props: {
  options?: any;
  node?: {
    align?: string;
    width?: string;
    url?: string;
    image: SanityImage;
  };
  ImageComponent?: any;
}) => {
  const { ImageComponent } = props;

  const image = (
    <ImageComponent
      sanityConfig={props.options}
      node={props.node.image}
      style={{ maxWidth: '100%' }}
    />
  );

  const style = {};

  if (props.node.align) {
    style.textAlign = props.node.align;
  }
  if (props.node.width) {
    style.width = props.node.width;
  }

  const isExternalUrl = startsWithHttpRe.test(props.node.url);

  return (
    <div style={style}>
      <Link legacyBehavior href={props.node.url} passHref>
        <Anchor
          target={isExternalUrl ? '_blank' : undefined}
          rel={isExternalUrl ? 'noreferrer' : undefined}
        >
          {image}
        </Anchor>
      </Link>
    </div>
  );
};

export const ImageLinkComponent = createLazyLoadableComponent(ImageLinkComponentContent);
