import React from 'react';
import { SanityBlockComponent } from './SanityBlockComponent';

export class TabsComponent extends React.Component<any, any> {
  constructor(props) {
    super(props);

    let key = '';

    if (props.node.tabs && props.node.tabs.length > 0) {
      key = props.node.tabs[0]._key;
    }
    this.state = { key };
  }

  render() {
    const tabs = this.props.node.tabs || [];
    const tab = this.props.node.tabs.find((t) => t._key === this.state.key);

    return (
      <div>
        {tabs.map((node, i) => (
          <button
            key={`tab-${i}`}
            onClick={() => this.setState({ key: node._key })}
            className={'btn btn-default' + (this.state.key === node._key ? ' active' : '')}
            style={{ marginRight: 10 }}
          >
            {node.title}
          </button>
        ))}
        <br />
        <br />
        <SanityBlockComponent blocks={tab.content} sanityConfig={this.props.options} />
      </div>
    );
  }
}
