import React, { useEffect, useRef, useState } from 'react';
import { createLazyLoadableComponent } from '../../utlis/createLazyLoadableComponent';

interface FacebookPostComponentProps {
  node: {
    code: string;
    maxWidth?: number;
  };
  prepareCode?: (code) => string;
}

export const FacebookPostComponentContent: React.FC<FacebookPostComponentProps> = (props) => {
  const { code, maxWidth } = props.node;
  const __html = props.prepareCode ? props.prepareCode(code) : code;
  const facebookPostRef = useRef<HTMLDivElement>(null);
  const [facebookPostHeight, setFacebookPostHeight] = useState(null);

  useEffect(() => {
    const facebookPost = facebookPostRef?.current;

    if (__html && facebookPost) {
      const attrs = __html.split(' ');
      const height = +attrs
        .find((attr) => attr.includes('height') && !attr.includes('src'))
        .split('=')[1]
        .match(/\d+/);

      const width = +attrs
        .find((attr) => attr.includes('width') && !attr.includes('src'))
        .split('=')[1]
        .match(/\d+/);

      setFacebookPostHeight(`${(facebookPost.clientWidth * height) / width}px`);
    }
  }, [__html]);

  return (
    <div
      style={{
        '--facebook-post-height-var-e3ee': facebookPostHeight,
        '--facebook-max-width-var-5998': maxWidth ? `${maxWidth}px` : '100%'
      }}
      className={
        'mx-auto flex w-full max-w-[var(--facebook-max-width-var-5998)] justify-center [&_iframe]:h-[var(--facebook-post-height-var-e3ee)] [&_iframe]:w-full [&_iframe]:max-w-full'
      }
      ref={facebookPostRef}
      dangerouslySetInnerHTML={{
        __html
      }}
    />
  );
};

export const FacebookPostComponent = createLazyLoadableComponent(FacebookPostComponentContent);
