import React from 'react';
import dynamic from 'adapter/next/dynamic';
import { Box, Modal, RelativeBox } from '@konsus/superside-kit';
import { Close } from '@konsus/superside-kit/src/images/icons';
import { outsideStyle } from '@konsus/lego/src/blocks/ExitPopup';
import { VisualAsset } from '@konsus/lego/src/components/VisualAsset';

import { Heading } from '@superside/ui';

const EmailCapturingForm = dynamic(
  () => import(/* webpackChunkName: "tier-0" */ '@konsus/lego/src/components/EmailCapturingForm')
);

interface BingoGameModalProps {
  popupTitle: string;
  popupSubTitle?: string;
  backgroundColor?: string;
  popupVisualAsset: any;
  closePopup;
}

export const BingoGameModal: React.FC<BingoGameModalProps> = ({
  popupVisualAsset,
  backgroundColor,
  popupTitle = 'Well done, you`re already making a lot of smart bets for your brand!',
  popupSubTitle,
  closePopup
}) => {
  return (
    <Modal full fill disableHeader pad='none' width='full' responsive={false}>
      <RelativeBox gap='12'>
        <Box className={outsideStyle} onClick={closePopup} />
        <RelativeBox className={'h-screen w-screen'}>
          <Box
            background={backgroundColor}
            className={
              'text-light absolute inset-0 z-[9999] m-auto h-fit w-[564px] rounded-[8px] shadow-[0_32px_48px_rgba(0,0,0,0.3)]'
            }
            flexBox={false}
          >
            <RelativeBox>
              <Box
                className={
                  'bg-light border-light hover:text-light text-dark absolute right-4 top-4 z-[10000] rounded-md border-2 p-1 transition-all duration-200 ease-in hover:cursor-pointer hover:bg-none hover:transition-all hover:duration-200 hover:ease-out'
                }
                onClick={closePopup}
              >
                <Close color='inherit' size='24' />
              </Box>
              <Box>
                <VisualAsset
                  {...popupVisualAsset}
                  layout='intrinsic'
                  objectFit='cover'
                  hideLoadingBar
                />
              </Box>
            </RelativeBox>

            <Box className={'p-8'}>
              <Heading className='mb-2' level='h5'>
                {popupTitle}
              </Heading>
              {popupSubTitle ? <span className='text-bor-lg mb-6'>{popupSubTitle}</span> : null}
              <EmailCapturingForm />
            </Box>
          </Box>
        </RelativeBox>
      </RelativeBox>
    </Modal>
  );
};
