import React from 'react';
import { useRouter } from 'adapter/next/router';
import { ShareButtons } from '@konsus/lego/src/blocks/ShareButtons';
import { Box } from '@konsus/superside-kit';
import { RichTextSuperscript } from '../RichTextSuperscript';

export type RichTextSocialShareButtonsPropType = {
  node: Partial<{
    title: string;
    url: string;
  }>;
};

export const RichTextSocialShareButtons: React.FC<RichTextSocialShareButtonsPropType> = ({
  node
}) => {
  const { asPath } = useRouter();
  const { title, url = `https://www.superside.com${asPath}` } = node;

  return (
    <Box gap='2'>
      {title ? <RichTextSuperscript>{title}</RichTextSuperscript> : null}

      <ShareButtons size='small' variant='light' shareUrl={url} includeCopyLink />
    </Box>
  );
};
