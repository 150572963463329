import { useEffect, useState } from 'react';

export const isChromatic = typeof window !== 'undefined' && window.isChromatic;

export const useChromatic = () => {
  const [isChromatic, setIsChromatic] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsChromatic(window.isChromatic);
    }
  }, []);

  return isChromatic;
};
