import React, { useEffect, useRef, useState, useMemo } from 'react';
import Link from 'adapter/next/link';
import { cn, Logo } from '@superside/ui';
import { Box, Modal, RelativeBox, Text } from '@konsus/superside-kit';
import {
  useAnalytics,
  userEventsTracking,
  getPromoEventProps,
  type PromoEventType
} from '@konsus/analytics';
import { Close } from '@konsus/superside-kit/src/images/icons';
import { useLocalStorage } from '@konsus/hooks';
import useReadingProgress from '../../hooks/useReadingProgress';
import { VisualAsset, type IVisualAsset } from '../../components/VisualAsset';
import { buildSanityUrl } from '../../components/VisualAsset/utils';
import styles from './index.module.css';

export interface ExitPopupProps {
  imageVisualAsset: IVisualAsset;
  imageVisualAssetConfirm: IVisualAsset;
  imageVisualAssetDecline: IVisualAsset;
  timeSpent?: number;
  titleDecline?: string;
  ctaButton?: {
    ctaText?: string;
    ctaLink?: string;
    isTargetBlank?: boolean;
  };
  copyVariant: 'standard' | 'no-copy' | 'custom-copy';
  customCopy?: string;
  promoEvents?: PromoEventType;
  variant: 'exitIntent' | 'scrollDepth';
  scrollDepth?: number;
  progressBarTarget: any;
  // cookie settings
  cookie?: string;
  expires?: number;
}

const debouncePadding = 60;

export const ExitPopup: React.FC<ExitPopupProps> = (props) => {
  const {
    imageVisualAsset,
    imageVisualAssetConfirm,
    imageVisualAssetDecline,
    timeSpent = 15,
    titleDecline = 'No, thank you',
    ctaButton,
    copyVariant = 'standard',
    customCopy,
    promoEvents,
    variant = 'exitIntent',
    scrollDepth = 70,
    progressBarTarget,
    cookie: cookieId = 'pricing-popup',
    expires: cookieExpires
  } = props;

  const { track } = useAnalytics();

  const readingProgress = useReadingProgress(progressBarTarget);

  const {
    ctaText = 'Book a Demo',
    ctaLink = '/book-call',
    isTargetBlank = false
  } = ctaButton || {};

  const ref = useRef<HTMLDivElement>(null);
  const declineButtonRef = useRef<HTMLDivElement>(null);
  const confirmButtonRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLDivElement>(null);
  const neutralRef = useRef<HTMLVideoElement>(null);
  const confirmRef = useRef<HTMLVideoElement>(null);
  const negativeRef = useRef<HTMLVideoElement>(null);
  const [isIntent, setIntent] = useState(false);
  const [mouseout, setMouseOut] = useState(false);
  const [enoughTimeSpent, setEnoughTimeSpent] = useState(false);
  const [enoughScrolled, setEnoughScrolled] = useState(false);
  const [generalState, setGeneralState] = useState<'neutral' | 'negative' | 'positive'>('neutral');

  const [exitPopupShowInfo, setExitPopupShowInfo] = useLocalStorage({
    key: cookieId,
    initialValue: { disable: 0 },
    daysToExpire: cookieExpires
  });

  const closeModal = () => {
    setExitPopupShowInfo({ disable: 1 });
    setIntent(false);
  };

  const ecommerce = useMemo(() => promoEvents && getPromoEventProps(promoEvents), [promoEvents]);

  useEffect(() => {
    track('promo.viewed', {
      user: {
        ...userEventsTracking
      },
      ecommerce: { ...ecommerce }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecommerce]);

  useEffect(() => {
    if (variant === 'exitIntent') {
      const spentTime = requestAnimationFrame(function updateTimeSpent() {
        const seconds = Math.round(window.performance.now() / 1000);

        if (seconds > timeSpent) {
          setEnoughTimeSpent(true);
          cancelAnimationFrame(spentTime);
        } else {
          requestAnimationFrame(updateTimeSpent);
        }
      });
    }
  }, [variant, timeSpent]);

  useEffect(() => {
    if (variant === 'scrollDepth' && !enoughScrolled && readingProgress > scrollDepth) {
      setEnoughScrolled(true);
    }
  }, [variant, readingProgress, scrollDepth, enoughScrolled]);

  useEffect(() => {
    if (variant !== 'exitIntent') {
      return;
    }

    const mouseouthandler = (e: any) => {
      if (!e.toElement && !e.relatedTarget) {
        setGeneralState('negative');

        if (!isIntent) {
          setIntent(true);
        }
      }
    };

    document.addEventListener('mouseout', mouseouthandler);

    return () => {
      document.removeEventListener('mouseout', mouseouthandler);
    };
  }, [isIntent, mouseout, variant]);

  useEffect(() => {
    const mouseenterhandler = () => {
      if (mouseout) {
        setMouseOut(false);
      }
    };

    document.addEventListener('mouseenter', mouseenterhandler);

    return () => {
      document.removeEventListener('mouseout', mouseenterhandler);
    };
  }, [mouseout]);

  useEffect(() => {
    onmousemove = (e) => {
      if (exitPopupShowInfo?.disable || !enoughTimeSpent || !isIntent) {
        return;
      }

      const { x: clientX, y: clientY } = e;

      const confirmButtonRect = confirmButtonRef?.current?.getBoundingClientRect();

      if (!confirmButtonRect) {
        return false;
      }
      const { x, y, width, height } = confirmButtonRect;
      const {
        x: xDecline,
        y: yDecline,
        width: widthDecline,
        height: heightDecline
      } = declineButtonRef?.current?.getBoundingClientRect()!;

      const closeButtonRect = closeButtonRef?.current?.getBoundingClientRect();

      if (!closeButtonRect) {
        return false;
      }
      const { x: xClose, y: yClose, width: widthClose, height: heightClose } = closeButtonRect;

      const confirm = {
        x1: x,
        y1: y - debouncePadding * 2.5,
        x2: x + width + debouncePadding,
        y2: y + height + debouncePadding
      };

      const decline = {
        x1: xDecline - debouncePadding,
        y1: yDecline - debouncePadding,
        x2: xDecline + widthDecline,
        y2: yDecline + heightDecline + debouncePadding
      };

      const close = {
        x1: xClose - debouncePadding / 2,
        y1: yClose - debouncePadding / 2,
        x2: xClose + widthClose + debouncePadding / 2,
        y2: yClose + heightClose + debouncePadding / 2
      };

      if (
        clientX > confirm.x1 &&
        clientX < confirm.x2 &&
        clientY > confirm.y1 &&
        clientY < confirm.y2
      ) {
        setGeneralState('positive');
      } else if (
        clientX > decline.x1 &&
        clientX < decline.x2 &&
        clientY > decline.y1 &&
        clientY < decline.y2
      ) {
        setGeneralState('negative');
      } else if (
        clientX > close.x1 &&
        clientX < close.x2 &&
        clientY > close.y1 &&
        clientY < close.y2
      ) {
        setGeneralState('negative');
      } else {
        setGeneralState('neutral');
      }
    };
  });

  const replay = (elementRef: React.RefObject<HTMLVideoElement>) => {
    const video = elementRef?.current;

    if (!video) {
      return;
    }

    video.load();
    video.play();
  };

  useEffect(() => {
    if (generalState === 'neutral') {
      replay(neutralRef);
    } else if (generalState === 'positive') {
      replay(confirmRef);
    } else if (generalState === 'negative') {
      replay(negativeRef);
    }
  }, [generalState]);

  useEffect(() => {
    [imageVisualAsset, imageVisualAssetConfirm, imageVisualAssetDecline].forEach(async (item) => {
      if (!item?.inlineVideo) {
        return;
      }

      const preloadUrl = buildSanityUrl(item?.inlineVideo);

      if (!preloadUrl) {
        return;
      }

      const res = await fetch(preloadUrl);
      const blob = await res.blob();

      URL.createObjectURL(blob);
    });
  }, [imageVisualAsset, imageVisualAssetConfirm, imageVisualAssetDecline]);

  const showPopup = useMemo(() => {
    const popupIsReady = variant === 'exitIntent' ? isIntent && enoughTimeSpent : enoughScrolled;

    return !exitPopupShowInfo?.disable && popupIsReady;
  }, [exitPopupShowInfo, isIntent, enoughTimeSpent, variant, enoughScrolled]);

  return showPopup ? (
    <Modal scrollableRef={ref} full fill disableHeader pad='none' width='full' responsive={false}>
      <RelativeBox gap='12'>
        <Box className={styles.outsideStyle} onClick={() => closeModal()} />
        <RelativeBox className={'h-screen w-screen'}>
          <Box
            className={
              'bg-darker text-light absolute inset-0 z-[9999] m-auto h-fit w-[792px] rounded-lg shadow-[0_32px_48px_#0000004d]'
            }
            flexBox={false}
          >
            <RelativeBox className={'isolate h-[445px] overflow-hidden rounded-[8px_8px_0_0]'}>
              <Box
                className={
                  'text-dark absolute right-4 top-4 z-[10000] rounded border-2 border-solid border-blue-100 bg-blue-100 p-1 transition-all duration-[0.2s] ease-[ease-in] hover:cursor-pointer hover:border-blue-200 hover:bg-blue-200 hover:transition-all hover:duration-[0.2s] hover:ease-[ease-out]'
                }
                ref={closeButtonRef}
                onClick={() => closeModal()}
              >
                <Close color='inherit' size='24' />
              </Box>
              <Box
                className={cn(
                  'absolute inset-0 rounded-[8px_8px_0_0] opacity-0 shadow-[0_4px_48px_#5cff8500] transition-all duration-[0.5s] ease-[ease-in-out]',
                  generalState === 'neutral' &&
                    '!opacity-100 transition-all duration-[0.5s] ease-[ease-in-out]'
                )}
              >
                <VisualAsset
                  {...imageVisualAsset}
                  layout='intrinsic'
                  objectFit='cover'
                  hideLoadingBar
                  ref={neutralRef}
                />
              </Box>
              <Box
                className={cn(
                  'absolute inset-0 rounded-[8px_8px_0_0] opacity-0 shadow-[0_4px_48px_#5cff8500] transition-all duration-[0.5s] ease-[ease-in-out]',
                  generalState === 'positive' &&
                    '!opacity-100 transition-all duration-[0.5s] ease-[ease-in-out]'
                )}
              >
                <VisualAsset
                  {...imageVisualAssetConfirm}
                  layout='intrinsic'
                  objectFit='cover'
                  hideLoadingBar
                  ref={confirmRef}
                />
              </Box>
              <Box
                className={cn(
                  'absolute inset-0 rounded-[8px_8px_0_0] opacity-0 shadow-[0_4px_48px_#5cff8500] transition-all duration-[0.5s] ease-[ease-in-out]',
                  generalState === 'negative' &&
                    '!opacity-100 transition-all duration-[0.5s] ease-[ease-in-out]'
                )}
              >
                <VisualAsset
                  {...imageVisualAssetDecline}
                  layout='intrinsic'
                  objectFit='cover'
                  hideLoadingBar
                  ref={negativeRef}
                />
              </Box>
            </RelativeBox>
            <Box direction='row'>
              <Box className={'w-6/12'} ref={declineButtonRef} flexBox={false}>
                <Box
                  className={cn(
                    'h-[86px] cursor-pointer select-none font-semibold transition-all duration-[0.3s] ease-[ease-out]',
                    'bg-dark w-full transition-all duration-[0.3s] ease-[ease-in-out]',
                    generalState === 'negative' &&
                      'bg-darker transition-all duration-[0.3s] ease-[ease-in-out]'
                  )}
                  align='center'
                  justify='center'
                >
                  <Box onClick={() => closeModal()}>
                    <Text size='large' className={'cursor-pointer'}>
                      {titleDecline}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Link legacyBehavior href={ctaLink} passHref>
                <Box
                  className={'w-6/12 no-underline'}
                  ref={confirmButtonRef}
                  flexBox={false}
                  as='a'
                  onClick={() => setExitPopupShowInfo({ disable: 1 })}
                  // @ts-expect-error target doesn't exist on Box, but rendering as anchor here
                  target={isTargetBlank ? '_blank' : '_self'}
                >
                  <Box
                    className={cn(
                      'h-[86px] select-none font-semibold transition-all duration-[0.3s] ease-[ease-out]',
                      'text-dark w-full cursor-pointer bg-green-500',
                      generalState === 'positive' &&
                        'relative shadow-[0_4px_48px_#5cff8580] transition-all duration-[1s] ease-[ease-in]'
                    )}
                    align='center'
                    justify='center'
                  >
                    <Text size='large'>{ctaText}</Text>
                  </Box>
                </Box>
              </Link>
            </Box>

            {copyVariant === 'no-copy' ? null : (
              <Box
                direction='row'
                className={'[&_a]:text-light mx-0 my-8 cursor-default select-none'}
                align='center'
                justify='center'
              >
                {copyVariant === 'standard' ? (
                  <>
                    <Text semibold size='medium'>
                      Launch your design into space with
                    </Text>
                    <Box margin={{ left: '16px' }}>
                      <Logo className='h-8 w-40 [&>div>svg]:max-h-8' />
                    </Box>
                  </>
                ) : null}

                {copyVariant === 'custom-copy' && customCopy ? (
                  <Text semibold size='medium'>
                    {customCopy}
                  </Text>
                ) : null}
              </Box>
            )}
          </Box>
        </RelativeBox>
      </RelativeBox>
    </Modal>
  ) : null;
};

export default ExitPopup;
export const outsideStyle = styles.outsideStyle;
