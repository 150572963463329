import React, { useCallback } from 'react';
import { Button } from '../Button';

export const HashButton = ({ to, smooth, ...props }) => {
  const onClick = useCallback(
    (e) => {
      e.preventDefault();

      const id = e.currentTarget.getAttribute('href').split('#').slice(1)[0];
      const anchor = document.getElementById(id);

      if (anchor) {
        anchor.scrollIntoView({
          behavior: smooth ? 'smooth' : 'auto'
        });
      }

      props.onClick && props.onClick(e);
    },
    [props.onClick]
  );

  return <Button {...props} href={to} onClick={onClick} />;
};

export default HashButton;
