import React, { useState, useMemo, useEffect } from 'react';
import { useRouter } from 'adapter/next/router';
import { useResponsiveContext } from '@konsus/superside-kit';
import { Box, cn } from '@superside/ui';
import { ShareButtons } from '@konsus/lego/src/blocks/ShareButtons';
import { possibleVariant, checkIfVariantFullySelected } from './utils';
import { BingoSchmoonImage } from './BingoSchmoonImage';
import { BingoPartySchmoonImage } from './BingoPartySchmoonImage';
import { BingoGameModal } from './BingoGameModal';

interface BingoGameElementProps {
  element: string;
  clickHandler: (active: boolean) => void;
  winnerVariants: string[];
}

interface BingoGameProps {
  popupTitle: string;
  popupSubTitle?: string;
  subscribeText?: string;
  backgroundColor?: string;
  popupVisualAsset: any;
  bingoGame: {
    cards: string[];
  };
}

const BingoGameElement: React.FC<BingoGameElementProps> = ({
  element,
  clickHandler,
  winnerVariants
}) => {
  const [active, setActive] = useState(false);
  const isBingoElement = winnerVariants.some((winnerVariant) => winnerVariant.includes(element));

  const onClickHandler = () => {
    if (!isBingoElement) {
      clickHandler(active);
      setActive(!active);
    }
  };

  return (
    <button
      onClick={onClickHandler}
      className={cn(
        '768:[&_>_*]:text-base 768:h-[104px] h-[126px] overflow-hidden whitespace-normal rounded-md bg-blue-100 px-2 py-4 text-center font-normal hover:bg-blue-200 [&_>_*]:text-sm',
        active && 'text-light hover:text-light bg-indigo-500 hover:bg-indigo-500',
        isBingoElement && 'bg-dark text-light hover:bg-dark hover:text-light'
      )}
    >
      <span className={cn(active && 'text-light')}>{element}</span>
    </button>
  );
};

export const BingoGame: React.FC<BingoGameProps> = ({
  bingoGame,
  popupVisualAsset,
  backgroundColor,
  subscribeText,
  popupTitle = 'Well done, you`re already making a lot of smart bets for your brand!',
  popupSubTitle
}) => {
  const size = useResponsiveContext();
  const isSmallScreen =
    size === 'xsmall' || size === 'small' || size === 'medium' || size === 'large';
  const { cards: initialCards } = bingoGame;
  const [cards, setCards] = useState<string[]>();
  const [selected, setSelected] = useState(['BINGO']);
  const [isBingo, setIsBingo] = useState(false);
  const [winnerVariants, setWinnerVariants] = useState<string[]>([]);
  const { asPath } = useRouter();

  const [showPopup, setShowPopup] = useState(false);
  const [popupWasShown, setPopupWasShown] = useState(false);

  const closePopup = () => {
    setPopupWasShown(true);
  };

  useEffect(() => {
    if (isSmallScreen) {
      setCards(initialCards.slice(0, 8));
    } else {
      setCards(initialCards);
    }
  }, [isSmallScreen, initialCards]);

  const allCards = useMemo(() => {
    const currentCards = cards || initialCards;
    const middleIndex = Math.floor(currentCards.length / 2);

    return [...currentCards.slice(0, middleIndex), 'BINGO', ...currentCards.slice(middleIndex)];
  }, [cards]);

  const possibleBingoVariants = useMemo(() => {
    return possibleVariant(allCards, isSmallScreen ? 3 : 5);
  }, [allCards, isSmallScreen]);

  useEffect(() => {
    const count = [];

    for (let i = 0; i < possibleBingoVariants.length; i++) {
      if (checkIfVariantFullySelected(selected, possibleBingoVariants[i])) {
        count.push(possibleBingoVariants[i]);
      }
    }

    if (count.length) {
      setIsBingo(true);
      setWinnerVariants(count);
      setTimeout(() => {
        setShowPopup(true);
      }, 1000);
    } else {
      setIsBingo(false);
      setWinnerVariants([]);
    }
  }, [selected, possibleBingoVariants]);

  const elementClickHandler = (el: string, active: boolean) => {
    if (active) {
      setSelected(selected.filter((item) => item !== el));
    } else {
      setSelected([...selected, el]);
    }
  };

  if (!cards) {
    return null;
  }

  return (
    <>
      {isBingo && showPopup && !popupWasShown ? (
        <BingoGameModal
          closePopup={closePopup}
          popupVisualAsset={popupVisualAsset}
          backgroundColor={backgroundColor}
          popupTitle={popupTitle}
          popupSubTitle={popupSubTitle}
        />
      ) : null}
      <Box className='rte-sub rte-sub-bingo'>
        <Box
          className={cn(
            'mb-2 items-center rounded-lg bg-indigo-500 py-4 text-base font-semibold leading-6',
            isBingo && 'bg-dark'
          )}
        >
          <span className='text-light'>{isBingo ? 'BINGO!' : 'Nothing so far...'}</span>
        </Box>
        <Box className='1280:grid-cols-5 grid grid-cols-3 gap-2'>
          {allCards.map((el, i) => {
            if (el === 'BINGO') {
              return (
                <Box
                  key={`bingo-el-${i}`}
                  className={cn(
                    '768:h-[104px] h-[126px] items-center justify-center rounded-md bg-blue-200 text-center',
                    isBingo && '[&_>_svg]:-mr-[1px] [&_>_svg]:-mt-[36px]'
                  )}
                >
                  {isBingo ? <BingoPartySchmoonImage /> : <BingoSchmoonImage />}
                </Box>
              );
            }

            return (
              <BingoGameElement
                winnerVariants={winnerVariants}
                clickHandler={(active) => elementClickHandler(el, active)}
                key={`bingo-el-${i}`}
                element={el}
              />
            );
          })}
        </Box>
        {isBingo ? (
          <Box
            className={
              '1280:flex 1280:flex-row 1280:items-center 1280:justify-between 1280:p-6 mt-2 rounded-md bg-indigo-500 p-4'
            }
          >
            <Box className={'1280:[&_>_span]:text-bor-lg 1280:mb-0 768:[&_>_span]:text-base mb-4'}>
              <span className='text-light text-lg leading-normal'>
                Congratulations, you’ve hit <b>{winnerVariants.length} bingo</b>! <br />
                {subscribeText ? <span>{subscribeText}</span> : null}
              </span>
            </Box>
            <Box>
              <ShareButtons
                size='small'
                variant='lightBlue'
                shareUrl={`https://www.superside.com${asPath}`}
                includeCopyLink
              />
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
