export const TWO_WORDS_REGEX = /(\w.+\s).+/i;
export const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(\.[a-zA-Z]{2,3})+$/;

export enum FORM_ERRORS {
  REQUIRED = 'Required',
  MIN_TWO_WORDS = 'Full name must be at least two words',
  INVALID_WORK_EMAIL = 'Please provide your work email',
  INVALID_EMAIL = 'Please enter a valid email'
}

export const FREE_EMAIL_SERVICES = [
  'gmail.com',
  'googlemail.com',
  'protonmail.com',
  'protonmail.ch',
  'outlook.com',
  'hotmail.com',
  'yahoo.com',
  'zoho.com',
  'aol.com',
  'aim.com',
  'gmx.com',
  'gmx.us',
  'icloud.com',
  'yandex.com',
  'me.com',
  'hotmail.co.uk',
  'live.co.uk',
  'yahoo.co.uk',
  'givmail.com',
  'getnada.com',
  'pm.me'
];
