import React from 'react';
import { cn, Heading } from '@superside/ui';
import {
  Box,
  classForExcludingFromRichEditorRules,
  RelativeBox,
  Text
} from '@konsus/superside-kit';
import { type SanityImage, NextFutureImage } from '@konsus/image';
import Superscript from '@konsus/lego/src/components/Superscript';
import { Results, type ResultsType } from './Results';

interface RichTextKeyTakeawaysProps {
  node: {
    _key: string;
    _type: string;
    content: {
      headerText: string;
      superscriptText?: string;
      bulletPoints: string[];
    };
    backgroundElements: {
      left: SanityImage;
      right: SanityImage;
    };
    results?: ResultsType[];
  };
}

export const RichTextKeyTakeaways: React.FC<RichTextKeyTakeawaysProps> = ({ node }) => {
  const {
    content: { headerText, superscriptText, bulletPoints } = {},
    backgroundElements: { left, right } = {},
    results
  } = node;

  const bulletPointsLeft = bulletPoints?.slice(0, bulletPoints.length / 2);
  const bulletPointsRight = bulletPoints?.slice(bulletPoints.length / 2);

  return (
    <Box className={cn('rte-sub', 'rte-sub-banner')}>
      <RelativeBox
        className={
          '1280:p-14 768:p-10 bg-nebula-indigo-light bg-nebula-indigo-light gap-8 rounded-md p-10 px-6'
        }
      >
        {left ? (
          <NextFutureImage
            className={cn(
              classForExcludingFromRichEditorRules,
              '1024:[&.left]:block 1024:[&.left]:right-[30%] 768:[&.right]:max-w-full absolute right-0 top-0 rounded-[8px] [&.left]:hidden [&.right]:right-0 [&.right]:max-w-[140px]',
              'left'
            )}
            image={left}
          />
        ) : null}
        {right ? (
          <NextFutureImage
            className={cn(
              classForExcludingFromRichEditorRules,
              '1024:[&.left]:block 1024:[&.left]:right-[30%] 768:[&.right]:max-w-full absolute right-0 top-0 rounded-[8px] [&.left]:hidden [&.right]:right-0 [&.right]:max-w-[140px]',
              'right'
            )}
            image={right}
          />
        ) : null}

        {headerText || superscriptText ? (
          <Box>
            {superscriptText ? (
              <Superscript margin={{ bottom: '3' }} content={superscriptText} />
            ) : null}
            {headerText ? (
              <Heading level='h1' as='h3'>
                {headerText}
              </Heading>
            ) : null}
          </Box>
        ) : null}

        {bulletPoints ? (
          <Box justify='between' className={'1024:flex-row 1024:gap-10 flex-col gap-4'}>
            <BulletsWrapper bullets={bulletPointsLeft} />
            <BulletsWrapper bullets={bulletPointsRight} />
          </Box>
        ) : null}

        {results ? <Results results={results} /> : null}
      </RelativeBox>
    </Box>
  );
};

const BulletsWrapper = ({ bullets }: { bullets: string[] }) => (
  <Box className={'1024:w-1/2 flex w-full flex-1 flex-col'} gap='4'>
    {bullets.map((bulletPoint, i) => (
      <Text
        key={`${bulletPoint}-${i}`}
        className={
          'relative ml-5 pl-8 before:absolute before:top-[9px] before:ml-[-20px] before:mr-3 before:block before:h-2 before:w-2 before:rounded-full before:bg-indigo-500'
        }
      >
        {bulletPoint}
      </Text>
    ))}
  </Box>
);

export default RichTextKeyTakeaways;
