import React from 'react';
import { cn } from '@superside/ui';
import { FaqListItem } from '@konsus/lego/src/blocks/Faq/FaqListItem';
import { createLazyLoadableComponent } from '../../utlis/createLazyLoadableComponent';
import { AccordionCarousel } from './AccordionCarousel';

const RichTextFaqAccordionContent = ({ node }) => {
  const { items, variant = 'default', topAlignImages } = node;

  if (variant === 'withCarousel') {
    return <AccordionCarousel items={items} topAlignImages={topAlignImages} />;
  }

  return (
    <div
      className={cn(
        'rte-sub',
        'my-12',
        variant === 'withBackground' &&
          '1280:pl-6 1280:pr-6 768:ml-0 768:mr-0 768:w-auto ml-[-16px] mr-[-16px] w-full rounded-md bg-[rgba(79,79,200,0.08)] pl-4 pr-4 [&_.faqListItem:last-child]:border-b-0'
      )}
    >
      {items.map(({ title, text, descriptionRichText, asset }, i) => (
        <FaqListItem
          key={`question-${i}`}
          question={title}
          answer={text}
          richAnswer={descriptionRichText}
          className={
            'faqListItem [&_h3,h4,h5,h6]:font-bold 768:[&_h4]:text-[26px] 768:[&_h4]:leading-[36px] 1280:[&_h4]:text-[30px] 1280:[&_h4]:leading-[44px] [&_h4]:text-[23px] [&_h4]:leading-[32px]'
          }
          asset={asset}
          topAlignImages={topAlignImages}
        />
      ))}
    </div>
  );
};

export const RichTextFaqAccordion = createLazyLoadableComponent(RichTextFaqAccordionContent);
