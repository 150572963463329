import React, { useMemo } from 'react';
import { Box, cn } from '@superside/ui';
import { usePaginationTracker } from '@konsus/lego/src/components/ResponsiveCarousel/usePaginationTracker';
import {
  itemsCountToken,
  DEFAULT_SLIDE_HORIZONTAL_MARGIN
} from '@konsus/lego/src/components/ResponsiveCarousel/types';
import { PaginationDots } from '@konsus/lego/src/components/ResponsiveCarousel/PaginationDots';
import { type VisualAssetProps } from '@konsus/lego/src/components';
import { AccordionItem } from './AccordionItem';

export type AccordionItemProps = {
  _key: string;
  title: string;
  descriptionText?: string;
  descriptionRichText: { content: Array<any> };
  asset?: VisualAssetProps;
};

interface AccordionCarouselProps {
  items: AccordionItemProps[];
  topAlignImages?: boolean;
}

export const AccordionCarousel: React.FC<AccordionCarouselProps> = ({ items, topAlignImages }) => {
  const [activeIndex, setActiveIndex, scrollContainerRef] = usePaginationTracker({
    slideHorizontalMargin: DEFAULT_SLIDE_HORIZONTAL_MARGIN
  });

  const styles = useMemo(
    () => ({
      [itemsCountToken.name]: items.length
    }),
    [items.length]
  );

  return (
    <div className='rte-sub rte-sub-accordion'>
      <Box
        ref={scrollContainerRef}
        className={cn(
          '[&:after]:content-empty m-0 -mx-6 box-content snap-x snap-mandatory flex-row overflow-scroll px-6 [&::-webkit-scrollbar]:hidden [&:after]:flex [&:after]:w-6 [&:after]:flex-1 [&_>_*]:mx-[4px] [&_>_*]:snap-center',
          '768:grid 768:grid-cols-1 768:!m-0 768:!p-0 768:w-full'
        )}
        style={styles}
      >
        {items.map((item, i) => (
          <Box
            key={item._key}
            className={cn(
              'cardStyles',
              activeIndex === i + 1 && 'cardPreviousStyles',
              activeIndex === i && 'cardActiveStyles',
              activeIndex === i - 1 && 'cardNextStyles'
            )}
          >
            <AccordionItem
              item={item}
              index={i}
              setActive={setActiveIndex}
              topAlignImages={topAlignImages}
              hideActiveSetter={items?.length === 1}
            />
          </Box>
        ))}
      </Box>
      <PaginationDots
        isDark
        index={activeIndex}
        onChangePage={setActiveIndex}
        totalItems={items.length}
        className={'768:hidden'}
      />
    </div>
  );
};
