import React, { forwardRef } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { cn } from '@superside/ui';
import { defaultSanityClient } from '@konsus/sanity-client';
import Video from '../../Video';
import { VideoStructuredData } from '../../VideoStructuredData';
import { buildSanityUrl } from '../utils';
import type { IVisualAsset } from '../types';
import { cursorMagnifier, hiddenDesktopStyle, hiddenMobileStyle } from './commonStyles';

export interface AssetTypeInlineVideo
  extends Pick<
    IVisualAsset,
    | 'inlineVideo'
    | 'inlineVideoThumbnail'
    | 'inlineVideoData'
    | 'inlineVideoDataMobile'
    | 'inlineVideoMobile'
    | 'inlineVideoThumbnailMobile'
    | 'hideLoadingBar'
    | 'className'
    | 'objectPosition'
    | 'videoOnClickEnabled'
  > {
  maxWidthClassName?: string;
  onClick: () => void;
}

export const InlineVideo = forwardRef<HTMLVideoElement, AssetTypeInlineVideo>((props, ref) => {
  const {
    inlineVideo,
    inlineVideoThumbnail,
    inlineVideoData,
    inlineVideoMobile,
    inlineVideoThumbnailMobile,
    inlineVideoDataMobile,
    hideLoadingBar,
    className,
    objectPosition,
    videoOnClickEnabled,
    maxWidthClassName,
    onClick
  } = props;

  if (!inlineVideo) {
    return null;
  }

  const videoUrl = buildSanityUrl(inlineVideo);
  const videoUrlMobile = buildSanityUrl(inlineVideoMobile || inlineVideo);

  const videoThumbnailUrl = inlineVideoThumbnail
    ? imageUrlBuilder(defaultSanityClient).image(inlineVideoThumbnail).quality(50).url()
    : undefined;

  const videoThumbnailUrlMobile = inlineVideoThumbnailMobile
    ? imageUrlBuilder(defaultSanityClient)
        .image(inlineVideoThumbnailMobile)
        .quality(50)
        .width(720)
        .url()
    : videoThumbnailUrl;

  return (
    <>
      {videoUrl && (
        <span className={maxWidthClassName}>
          {inlineVideoData && <VideoStructuredData {...inlineVideoData} />}
          <Video
            videoUrl={videoUrl}
            videoThumbnailUrl={videoThumbnailUrl || undefined}
            videoPosition={objectPosition}
            hideLoadingBar={hideLoadingBar}
            className={cn(className, hiddenMobileStyle, videoOnClickEnabled && cursorMagnifier)}
            ref={ref}
            onClick={onClick}
            maxWidthClassName={maxWidthClassName}
          />
        </span>
      )}
      {videoUrlMobile && (
        <>
          {inlineVideoDataMobile && <VideoStructuredData {...inlineVideoDataMobile} />}
          <Video
            videoUrl={videoUrlMobile}
            videoThumbnailUrl={videoThumbnailUrlMobile || undefined}
            videoPosition={objectPosition}
            hideLoadingBar={hideLoadingBar}
            className={cn(className, hiddenDesktopStyle, videoOnClickEnabled && cursorMagnifier)}
            ref={ref}
            onClick={onClick}
          />
        </>
      )}
    </>
  );
});

InlineVideo.displayName = 'InlineVideo';

export default InlineVideo;
