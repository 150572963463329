import React from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';

export interface RichTextTableOfContentsProps {
  node: {
    links: LinkType[];
    hidden?: boolean;
  };
}

type LinkType = {
  _key: string;
  url: string;
  label: string;
};

export const RichTextTableOfContents: React.FC<RichTextTableOfContentsProps> = (props) => {
  const { links, hidden } = props.node;

  if (!links || hidden) {
    return null;
  }

  return (
    <nav
      className={cn(
        'mb-8 flex flex-col items-start gap-4 [&_.blockTitle]:text-[26px] [&_.blockTitle]:leading-[36px]',
        'rte-sub'
      )}
      id='blog-post-navigation'
    >
      {links
        .filter((link) => !!link.url)
        .map((link) => (
          <Link key={link._key} href={link.url} passHref legacyBehavior>
            <a className='text-bor-lg font-semibold leading-7'>{link.label}</a>
          </Link>
        ))}
    </nav>
  );
};
