import React from 'react';
import { CaretDown } from 'phosphor-react';
import Link from 'adapter/next/link';
import { Box, cn } from '@superside/ui';
import { ExternalContentWrapper, contentWrapperStyle } from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import VisualAsset, { MediaTypes } from '../VisualAsset';
import { type QuotePropsType } from './types';
import { QuotationMark } from './quotationMark';

export const Quote: React.FC<QuotePropsType> = ({
  author,
  quote,
  isLight,
  className,
  linkText = 'Read the story',
  link
}) => {
  return (
    <Box
      className={cn(
        'bg-black-500 768:p-8 relative flex w-full flex-col justify-between rounded-lg p-6',
        isLight && 'bg-blue-100',
        className
      )}
    >
      {quote ? (
        <ExternalContentWrapper
          className={cn(
            contentWrapperStyle,
            '1650:[&>p]:text-3xl 1650:[&>p]:leading-[45px] 768:mb-8 768:[&>p]:text-2xl 768:[&>p]:leading-9 1280:[&>p]:text-3xl 1280:[&>p]:leading-[45px] z-[2] mb-6 font-bold [&>p]:text-xl [&>p]:leading-8'
          )}
        >
          <SanityBlockComponent blocks={quote} />
        </ExternalContentWrapper>
      ) : null}
      <Box className='z-[2] mb-0 mt-auto w-full flex-shrink flex-row items-center gap-4'>
        {author?.image ? (
          <Box className='relative h-12 w-12 overflow-hidden rounded-lg'>
            <VisualAsset
              mediaType={MediaTypes.IMAGE}
              image={author.image}
              alt={author?.position}
              layout='fill'
            />
          </Box>
        ) : null}
        <Box className='flex-[1]'>
          {author?.title ? (
            <span className='1280:text-lg text-sm font-semibold leading-normal'>
              {author.title}
            </span>
          ) : null}
          {author?.position ? (
            <span className={cn('1280:text-lg text-sm', !isLight && 'text-alabaster-white-1')}>
              {author.position}
            </span>
          ) : null}
        </Box>
      </Box>
      {link ? (
        <Link href={link} className='group relative z-30 mb-0 mt-4 flex flex-row items-center'>
          <div className='after:bg-black-600 relative block font-semibold group-hover:text-indigo-500'>
            {linkText}
          </div>
          <CaretDown
            size={32}
            className='ml-2 h-3 w-3 rotate-[270deg] transition-all duration-300 group-hover:translate-x-0.5 group-hover:text-indigo-500'
          />
        </Link>
      ) : null}
      <Box
        className={cn(
          'text-black-600 768:h-[188px] 768:w-[237.14px] absolute left-4 top-4 z-[1] h-[117.33px] w-[148px]',
          isLight && 'text-blue-200'
        )}
      >
        <QuotationMark className='w-full rotate-180' />
      </Box>
      <Box
        className={cn(
          'text-black-600 768:h-[188px] 768:w-[237.14px] absolute bottom-4 right-4 z-[1] h-[117.33px] w-[148px]',
          isLight && 'text-blue-200'
        )}
      >
        <QuotationMark className='w-full' />
      </Box>
    </Box>
  );
};
