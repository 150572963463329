import { errorToState } from './errors';

export enum actions {
  SANITY_REQUEST = 'SANITY_REQUEST',
  SANITY_SUCCESS = 'SANITY_SUCCESS',
  SANITY_FAILURE = 'SANITY_FAILURE'
}

export function createInitialState(cachedData, options) {
  if (cachedData) {
    return {
      isLoading: false,
      data: cachedData
    };
  }

  return {
    isLoading: !options.manual
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case actions.SANITY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case actions.SANITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null
      };
    case actions.SANITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        data: null,
        error: errorToState(action.payload)
      };
    default:
      return state;
  }
}
