import React from 'react';
import { Box, RelativeBox, Button, Text } from '@konsus/superside-kit';
import { cn, Heading } from '@superside/ui';
import styles from './InfoBlock.module.css';

export interface InfoBlockProps {
  headerText: string;
  descriptionText: string;
  ctaCardText?: string;
  ctaCardUrl?: string;
  style?: { [key: string]: unknown };
}

export const InfoBlock: React.FC<InfoBlockProps> = (props) => {
  const { headerText, descriptionText, ctaCardText, ctaCardUrl, style } = props;

  return (
    <RelativeBox
      gap='5'
      justify='center'
      align='center'
      height={{ min: '388px' }}
      pad={{ horizontal: '6' }}
      className={cn(styles.bg, 'flex-1 rounded-lg text-center')}
      background='#1f3244'
      style={style}
    >
      <Box gap='4' width={{ max: '490px' }}>
        <Heading
          className={cn(styles.textOverflowStyle, `overflow-hidden text-ellipsis`)}
          level='h4'
          as='h3'
        >
          {headerText}
        </Heading>
        <Text
          className={cn(styles.textOverflowStyle, `overflow-hidden text-ellipsis`)}
          size='large'
          bold
        >
          {descriptionText}
        </Text>
      </Box>
      {ctaCardText && ctaCardUrl ? <Button primary href={ctaCardUrl} label={ctaCardText} /> : null}
    </RelativeBox>
  );
};
