import React from 'react';
import {
  Button as BaseButton,
  type ButtonProps as BaseButtonProps,
  HashButton
} from '@konsus/superside-kit';

export interface SectionButtonProps extends BaseButtonProps {
  hash?: boolean;
  target?: string;
}

export const SectionButton: React.FC<SectionButtonProps> = (props) => {
  const { href, hash = href?.startsWith('#'), onClick, ...rest } = props;

  if (hash) {
    return <HashButton smooth to={href} {...rest} />;
  }

  if (onClick) {
    return <BaseButton onClick={onClick} {...rest} />;
  }

  return <BaseButton href={href} {...rest} />;
};

export default SectionButton;
