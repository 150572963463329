import React, { useEffect, useReducer, useState } from 'react';
import { Box, Button, RelativeBox } from '@konsus/superside-kit';
import { cn } from '@superside/ui';
import styles from './index.module.css';
import { type DotsWrapperProps, type PaginationDotsProps } from './types';
import {
  PAGINATION_DOTS_GRADIENTS,
  PAGINATION_DOT_WIDTH_WITH_MARGIN,
  SPEED_ANIMATION,
  MAXIMUM_WIDTH_PAGINATION_DOTS
} from './constants';

type DotsReducerType = {
  maskImage: string;
  translateX: number;
};

type ActionDotsReducerType = {
  type: 'start' | 'center' | 'end';
  bounce?: number;
};

const dotsReducer = (_state: any, action: ActionDotsReducerType): DotsReducerType => {
  switch (action.type) {
    case 'start':
      return { maskImage: 'start', translateX: 0 };

    case 'center':
      return { maskImage: 'center', translateX: action.bounce || 0 };

    case 'end':
      return { maskImage: 'end', translateX: action.bounce || 0 };

    default:
      throw Error('Unknown action.');
  }
};

export const PaginationDotsAnimated: React.FC<PaginationDotsProps & { className?: string }> = ({
  index,
  length,
  changeIndex,
  isLight,
  className
}) => {
  const [animatedIndex, setAnimatedIndex] = useState(index);
  const [dotsStyle, setDotsStyle] = useReducer(dotsReducer, {
    maskImage: 'start',
    translateX: 0
  });

  const wrapperWidth =
    length * PAGINATION_DOT_WIDTH_WITH_MARGIN > MAXIMUM_WIDTH_PAGINATION_DOTS
      ? MAXIMUM_WIDTH_PAGINATION_DOTS
      : length * PAGINATION_DOT_WIDTH_WITH_MARGIN;

  useEffect(() => {
    const thirdIndexFromEnd = length > 5 ? length - 3 : length - 1;

    setTimeout(() => {
      setAnimatedIndex(index);
    }, SPEED_ANIMATION * 1000);

    if (index <= 2) {
      setDotsStyle({ type: 'start' });
    } else if (index > 2 && index < thirdIndexFromEnd) {
      const bounce = -1 * (2 + (index - 3) * PAGINATION_DOT_WIDTH_WITH_MARGIN);

      setDotsStyle({ type: 'center', bounce });
    } else if (index >= thirdIndexFromEnd) {
      const bounce = -1 * (2 + (thirdIndexFromEnd - 3) * PAGINATION_DOT_WIDTH_WITH_MARGIN);

      setDotsStyle({ type: 'end', bounce });
    }
  }, [index, length]);

  const dotsWrapperProps = {
    length,
    index,
    dotsTranslateXCalculated: dotsStyle.translateX,
    animatedIndex,
    isLight,
    changeIndex
  };

  return (
    <RelativeBox
      className={cn('h-4 max-w-[220px]', className)}
      style={{
        width: `${wrapperWidth}px`
      }}
    >
      <DotsWrapper gradient={null} opacity={1} {...dotsWrapperProps} />
      {Object.entries(PAGINATION_DOTS_GRADIENTS).map((gradient, idx) => (
        <DotsWrapper
          key={idx}
          gradient={gradient[1]}
          opacity={dotsStyle.maskImage === gradient[0] ? 1 : 0}
          {...dotsWrapperProps}
        />
      ))}
    </RelativeBox>
  );
};

const DotsWrapper: React.FC<DotsWrapperProps> = ({
  gradient,
  length,
  index,
  dotsTranslateXCalculated,
  animatedIndex,
  opacity,
  isLight,
  changeIndex
}) => {
  return (
    <Box
      className={
        'absolute top-0 h-4 w-[220px] overflow-hidden transition-all duration-[0.25s] ease-in-out'
      }
      style={{
        maskImage: gradient ?? undefined,
        opacity,
        zIndex: !gradient ? 3 : 1
      }}
    >
      <Box
        className={cn(
          `${styles.dotsContainer} absolute top-0 opacity-100 transition-transform duration-500 ease-in-out`
        )}
        style={{
          // @ts-expect-error
          '--dots-tx': `translate3d(${dotsTranslateXCalculated}px, 0, 0)`
        }}
      >
        {length > 1 ? (
          <RelativeBox wrap={false} gap='4' direction='row' justify='start'>
            {Array.from(Array(length)).map((_item, i) => {
              const isFirst = i === 0;
              const afterAnimation = i === animatedIndex;
              const direction = index > animatedIndex ? 'right' : 'left';
              const isDirectionRight = direction === 'right';
              const isDirectionLeft = direction === 'left';
              const nextIndex = i + 1;
              const prevIndex = i - 1;

              const isActive = index === 0 && isDirectionRight ? isFirst : afterAnimation;
              const isActiveNext = afterAnimation && isDirectionRight;
              const isActiveBack = afterAnimation && isDirectionLeft;
              const isNext = prevIndex === animatedIndex && isDirectionRight;
              const isPrev = nextIndex === animatedIndex && isDirectionLeft;
              const isAnimationInProgress = index !== animatedIndex && direction;
              const isLast = nextIndex === length;

              return (
                <Button
                  id={i}
                  key={i}
                  plain
                  onClick={() => {
                    if (!isActive && changeIndex) {
                      changeIndex(i);
                    }
                  }}
                >
                  <RelativeBox
                    className={cn(
                      'bg-bor-foreground inset-x-0 h-4 w-4 rounded-lg opacity-10',
                      isLight && 'bg-dark',
                      isActive && 'w-8 opacity-100',
                      isAnimationInProgress
                        ? [
                            'transition-all duration-500 ease-in-out',
                            isActiveNext && !isLast && styles.activeMovingDot,
                            isActiveBack && !isFirst && styles.activeMovingBackDot,
                            isNext && '-left-12',
                            isPrev && 'left-12'
                          ]
                        : '',
                      !gradient && !isActive && '!opacity-0'
                    )}
                    style={{
                      // @ts-expect-error
                      '--dots-space': `${Math.abs(
                        PAGINATION_DOT_WIDTH_WITH_MARGIN * (animatedIndex - index)
                      )}px`
                    }}
                  />
                </Button>
              );
            })}
          </RelativeBox>
        ) : null}
      </Box>
    </Box>
  );
};
