import React, { useEffect, useMemo } from 'react';
import dynamic from 'adapter/next/dynamic';
import { useInView } from 'react-intersection-observer';
import { useAnalytics, userEventsTracking, getPromoEventProps } from '@konsus/analytics';
import { cn, Heading } from '@superside/ui';
import {
  Box,
  ExternalContentWrapper,
  RelativeBox,
  Text,
  contentWrapperStyle,
  classForExcludingFromRichEditorRules
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import NextImage from '@konsus/image';
import Superscript from '../../components/Superscript';
import { SectionButton } from '../../components/SectionButton';
import { ProgressiveModalEnabledCta } from '../../components/HeroCtaGroup';
import {
  bannerBox,
  bannerBoxShadow,
  buttonBox,
  centeredBox,
  centeredContainer,
  centeredContent,
  container,
  content,
  descriptionStyle,
  desktopBgImageStyle,
  emailCapturingBox,
  halfDesktopBgImageStyle,
  headingStyle,
  image,
  mobileBgImageStyle,
  smallBannerBoxShadow,
  buttonCtaContainyStyle
} from './styles';
import { BannerSubscribeContent } from './BannerSubscribeContent';
import { type BannerProps } from './types';
import { BannerWithProgressiveForm } from './BannerWithProgressiveForm';

const EmailCapturingForm = dynamic(
  () => import(/* webpackChunkName: "tier-0" */ '../../components/EmailCapturingForm')
);

export const BannerContent: React.FunctionComponent<BannerProps> = (props) => {
  const {
    copy,
    variant = 'withButton',
    button,
    ctaButton,
    secondaryButton,
    form,
    backgroundColor = '#0F2133',
    desktopBgImage,
    mobileBgImage = desktopBgImage,
    backgroundVariant,
    isLazy = true,
    boxShadow = false,
    thankYouMobileImage,
    thankYouCopy,
    smallBoxShadow = false,
    compact,
    promoEvents
  } = props;

  const { track } = useAnalytics();

  const [ref, isVisible] = useInView({ rootMargin: '0px 0px', triggerOnce: true });

  const ecommerce = useMemo(() => promoEvents && getPromoEventProps(promoEvents), [promoEvents]);

  useEffect(() => {
    if (isVisible) {
      track('promo.viewed', {
        user: {
          ...userEventsTracking
        },
        ecommerce: { ...ecommerce }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecommerce, isVisible]);

  const { superscriptText, headerText, subHeaderText, subHeaderRichText } = copy || {};
  const { submitButtonText, placeholderText, hubspotFormId, trackingEvents } = form || {};
  const { ctaText, ctaLink, isTargetBlank = false } = button || {};

  const isCentered = variant === 'centeredWithButton';

  const isMarble = backgroundColor === '#D2CAC0';
  const isTransparentMarble = backgroundColor === 'rgba(250, 250, 250, 0.15)';
  const isGalaxyBlue = backgroundColor === '#E5EAFF';

  const isSuperads = useMemo(() => {
    if (typeof document !== 'undefined') {
      return document.body.dataset.theme === 'superads';
    }
  }, []);

  return (
    <div ref={ref} data-theme={isMarble || isTransparentMarble ? 'marble' : 'aurora'}>
      {variant === 'subscribeForm' ? (
        <BannerSubscribeContent
          desktopBgImage={desktopBgImage}
          thankYouMobileImage={thankYouMobileImage}
          backgroundColor={backgroundColor}
          copy={copy}
          thankYouCopy={thankYouCopy}
          form={form}
          boxShadow={boxShadow}
          smallBoxShadow={smallBoxShadow}
          compact={compact}
        />
      ) : null}
      {variant === 'withProgressiveForm' ? <BannerWithProgressiveForm {...props} /> : null}
      {variant !== 'subscribeForm' && variant !== 'withProgressiveForm' ? (
        <RelativeBox
          align={isCentered ? 'center' : 'start'}
          className={cn(
            bannerBox,
            boxShadow && !smallBoxShadow && bannerBoxShadow,
            boxShadow && smallBoxShadow && smallBannerBoxShadow,
            isCentered && centeredBox,
            '<1024:flex-col 768:p-10 1024:p-12 flex-row items-center overflow-visible rounded-lg'
          )}
          background={backgroundColor}
        >
          {desktopBgImage ? (
            <Box
              className={cn(
                image,
                desktopBgImageStyle,
                backgroundVariant === 'halfWidthBg' && halfDesktopBgImageStyle
              )}
            >
              <NextImage
                layout='fill'
                objectFit={backgroundVariant === 'halfWidthBg' ? 'contain' : 'cover'}
                objectPosition='center'
                image={desktopBgImage}
                alt={headerText}
                loading={isLazy ? 'lazy' : 'eager'}
                className={classForExcludingFromRichEditorRules}
              />
            </Box>
          ) : null}
          {mobileBgImage ? (
            <Box className={cn(image, mobileBgImageStyle)}>
              <NextImage
                image={mobileBgImage}
                alt={headerText}
                layout='responsive'
                loading={isLazy ? 'lazy' : 'eager'}
                className={classForExcludingFromRichEditorRules}
              />
            </Box>
          ) : null}
          <Box
            className={cn(
              container,
              isCentered && centeredContainer,
              'p-4',
              isMarble || isTransparentMarble || isGalaxyBlue ? '' : 'dark'
            )}
            align='start'
          >
            <Box
              className={cn(
                content,
                isCentered && centeredContent,
                (isMarble || isTransparentMarble) &&
                  '!text-bor-foreground [&_p]:!text-bor-foreground'
              )}
            >
              {superscriptText ? (
                <Superscript margin={{ bottom: '3' }} content={superscriptText} />
              ) : null}
              <Heading
                className={cn(
                  classForExcludingFromRichEditorRules,
                  headingStyle,
                  (isMarble || isTransparentMarble) && isSuperads ? '!font-bold' : ''
                )}
                level={isCentered ? 'h4' : 'h5'}
                as='h3'
              >
                <Box dangerouslySetInnerHTML={{ __html: headerText }} />
              </Heading>
              {subHeaderRichText || subHeaderText ? (
                <Box className={descriptionStyle}>
                  {subHeaderRichText ? (
                    <ExternalContentWrapper className={contentWrapperStyle} margin={{ top: '3' }}>
                      <SanityBlockComponent blocks={subHeaderRichText} />
                    </ExternalContentWrapper>
                  ) : subHeaderText ? (
                    <Text
                      className={classForExcludingFromRichEditorRules}
                      maxWidth='590px'
                      margin={{ top: '3' }}
                    >
                      {subHeaderText}
                    </Text>
                  ) : null}
                </Box>
              ) : null}
            </Box>

            {variant === 'withForm' ? (
              <Box margin={{ top: '6' }} className={emailCapturingBox}>
                <EmailCapturingForm
                  redirectLink='/get-started'
                  buttonLabel={submitButtonText}
                  placeholder={placeholderText}
                  formId={hubspotFormId}
                  trackingEvents={trackingEvents}
                />
              </Box>
            ) : (
              <Box className={buttonCtaContainyStyle} margin={{ top: '6' }}>
                <Box className={buttonBox}>
                  {ctaButton?.ctaText && (ctaButton?.ctaLink || ctaButton?.ctaUrl) ? (
                    <ProgressiveModalEnabledCta
                      cta={ctaButton}
                      className='marble:hover:!shadow-none'
                    />
                  ) : ctaText && ctaLink ? (
                    <SectionButton
                      target={isTargetBlank ? '_blank' : '_self'}
                      primary={backgroundColor !== '#E5EAFF' && !isMarble && !isTransparentMarble}
                      secondary={backgroundColor === '#E5EAFF' || isMarble || isTransparentMarble}
                      href={ctaLink}
                      label={ctaText}
                    />
                  ) : null}
                </Box>
                {secondaryButton?.ctaText &&
                (secondaryButton?.ctaLink || secondaryButton?.ctaUrl) ? (
                  <Box className={buttonBox}>
                    <ProgressiveModalEnabledCta cta={secondaryButton} />
                  </Box>
                ) : null}
              </Box>
            )}
          </Box>
        </RelativeBox>
      ) : null}
    </div>
  );
};

export default BannerContent;
