import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';

const AMOUNT_OF_AVATARS = 9;

export const useAvatarAnimationRerender = (
  animateAvatarStack: boolean,
  setAnimateAvatarStack: Dispatch<SetStateAction<boolean>>,
  otherPeopleNumber: number
) => {
  const [peopleNumber, setPeopleNumber] = useState(otherPeopleNumber);
  const [avatarStackIndexes, setAvatarStackIndexes] = useState([0, 1, 2, 3]);

  useEffect(() => {
    setPeopleNumber(otherPeopleNumber);
  }, [otherPeopleNumber]);

  useEffect(() => {
    setTimeout(() => {
      setAvatarStackIndexes((prevState) => {
        const nums = [...prevState];
        const diff = peopleNumber + 1 - nums.length;

        if (diff > 0) {
          const existingElements = new Set();

          nums.forEach((element) => existingElements.add(element));
          const newElements = [];

          while (newElements.length < diff) {
            const randomNumber = Math.floor(Math.random() * AMOUNT_OF_AVATARS);

            if (!existingElements.has(randomNumber)) {
              newElements.push(randomNumber);
              existingElements.add(randomNumber);
            }
          }

          nums.push(...newElements);
        } else if (diff < 0) {
          nums.splice(0, -diff);
        }

        return nums;
      });
    }, 500);
  }, [peopleNumber]);

  useEffect(() => {
    if (animateAvatarStack) {
      setTimeout(() => {
        setAnimateAvatarStack(false);
      }, 500);
    }
  }, [animateAvatarStack]);

  return avatarStackIndexes;
};
