import type { SanityVisualAsset, SanityVisualAssetBackground } from 'sanity.types';
import { defaultSanityClient } from '@konsus/sanity-client';
import type { SanityFileAsset, SanityImageAsset } from '../types';

export function buildSanityUrl(asset: SanityFileAsset | SanityImageAsset | undefined) {
  const type = `${asset?._type}s`;
  const ref = asset?.asset?._ref || '';
  const refArray = ref.split('-');
  const ext = refArray[refArray.length - 1];
  const name = refArray.slice(1, -1).join('-');

  if (name && ext) {
    return `https://cdn.sanity.io/${type}/${defaultSanityClient.clientConfig.projectId}/${defaultSanityClient.clientConfig.dataset}/${name}.${ext}`;
  }

  return null;
}

export const sanitizeAsset = <
  T extends SanityVisualAsset | SanityVisualAssetBackground | undefined
>(
  asset: T,
  legacyImage: SanityImageAsset | undefined,
  legacyMobileImage: SanityImageAsset | undefined
): T => {
  const mediaType = asset?.mediaType;

  if (!mediaType || (mediaType === 'image' && !asset.image)) {
    return {
      ...asset,
      mediaType: 'image',
      image: legacyImage,
      imageMobile: legacyMobileImage
    };
  }

  return asset;
};
