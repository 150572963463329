import React from 'react';
import { cn } from '@superside/ui';
import { isDark } from '@konsus/superside-kit';
import { BannerContent } from '@konsus/lego/src/blocks/Banner/BannerContent';
import { type BannerProps } from '@konsus/lego/src/blocks/Banner/types';

export const RichTextBanner: React.FC<{ node: BannerProps }> = (props) => {
  return (
    <div
      className={cn(
        'flex',
        'flex-col',
        'rte-sub',
        'rte-sub-banner',
        isDark(props.node.backgroundColor) ? 'dark' : 'light'
      )}
    >
      <BannerContent small {...props.node} />
    </div>
  );
};
