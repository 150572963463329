import React from 'react';
import { SanityBlockComponent } from './SanityBlockComponent';

export const ListComponent = (props) => {
  const { ImageComponent } = props;

  return (
    <ul className={'p-0 [&_li.media]:mb-5'}>
      {props.node.list.map((card, index) => {
        let header = card.title;

        if (card.url) {
          header = (
            <a href={card.url} target='_blank' rel='noreferrer'>
              {card.title}
            </a>
          );
        }

        return (
          <li className={'block border border-[#eee] p-5 [&_.media-left]:mb-2.5'} key={index}>
            <div className='media-left' style={{ marginRight: 10 }}>
              {card.image && (
                <ImageComponent
                  sanityConfig={props.options}
                  node={card.image}
                  width={100}
                  mobileWidth={100}
                  fit='max'
                  alt={card.title ? card.title : ''}
                />
              )}
            </div>
            <div className='media-body'>
              <h4 className='media-heading' style={{ fontSize: 18 }}>
                {header}
              </h4>
              <SanityBlockComponent blocks={card.content} sanityConfig={props.options} />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
