import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'throttle-debounce';

const useReadingProgress = (target: any) => {
  const [readingProgress, setReadingProgress] = useState(0);

  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element = target.current;
    const totalHeight = element.clientHeight - element.offsetTop - window.innerHeight;
    const windowScrollTop =
      window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (windowScrollTop === 0) {
      return setReadingProgress(0);
    }

    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100);
    }

    setReadingProgress((windowScrollTop / totalHeight) * 100);
  };

  const debouncedScrollListener = useMemo(() => debounce(10, scrollListener), []);

  useEffect(() => {
    window.addEventListener('scroll', debouncedScrollListener);

    return () => window.removeEventListener('scroll', debouncedScrollListener);
  }, [debouncedScrollListener]);

  return readingProgress;
};

export default useReadingProgress;
