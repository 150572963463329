import React from 'react';
import Link from 'adapter/next/link';
import { cn } from '@superside/ui';
import styles from './RIchTextExternalLink.module.css';

export const RichTextExternalLink: React.FC<{
  node: {
    superscript?: string;
    title: string;
    link?: string;
    hideLink?: boolean;
    isTargetBlank?: boolean;
  };
}> = (props) => {
  const { superscript, title, link, hideLink = false, isTargetBlank = true } = props.node;

  if (!link || !title) {
    return null;
  }

  return (
    <Link href={link} target={isTargetBlank ? '_blank' : '_self'}>
      <div
        className={cn(
          'flex flex-col',
          'relative mt-8 rounded-lg bg-blue-100 p-6 pr-14',
          'before:content-empty before:absolute before:right-6 before:top-2/4 before:-mt-4 before:block before:h-8 before:w-8',
          styles.bg,
          'rte-sub'
        )}
      >
        {superscript ? (
          <span className='mb-1.5 text-xs font-semibold uppercase not-italic leading-[150%] tracking-[1.8px]'>
            {superscript}
          </span>
        ) : null}
        <span className='text-2xl font-bold leading-[150%]'>{title}</span>
        <span
          className={cn(
            'text-left before:absolute before:left-0 before:top-0 before:block before:h-full before:w-full before:content-[""]',
            !hideLink && 'mt-[5px]'
          )}
        >
          {!hideLink ? <span>{new URL(link).hostname}</span> : null}
        </span>
      </div>
    </Link>
  );
};
