import React, { type PropsWithChildren } from 'react';
import { cn, Text } from '@superside/ui';
import styles from '../components/RichTextQuote/RichTextQuote.module.css';

export const QuoteComponent: React.FC<PropsWithChildren> = (props) => (
  <blockquote
    className={cn(
      styles.bgV2,
      '1024:[&:before]:h-[24px] 1024:[&:before]:w-[30px] 1024:[&_p::first-letter]:ml-[46px] 1024:[&_p]:!text-[23px] 1280:[&:before]:h-[30px] 1280:[&:before]:w-[38px] 1280:[&_p::first-letter]:ml-[54px] 1280:[&_p]:!text-[28px] 1280:[&_p]:font-semibold text-bor-lg relative font-semibold before:content-[""] [&:before]:absolute [&:before]:left-0 [&:before]:top-0 [&:before]:block [&:before]:h-[21px] [&:before]:w-[26px] [&:before]:bg-cover [&_p::first-letter]:ml-[38px]'
    )}
    {...props}
  >
    <Text as='p' className='1024:!leading-[32px] 1280:!leading-[40px]'>
      {props.children}
    </Text>
  </blockquote>
);
