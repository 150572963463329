import React from 'react';
import { cn } from '@superside/ui';
import { Box, theme } from '@konsus/superside-kit';
import { SanityBlockComponent } from './SanityBlockComponent';

const COLORS = {
  green: {
    bg: '#E9FFEE',
    link: '#0CC63A'
  },
  pink: {
    bg: '#FFF5F7',
    link: '#FA676F'
  },
  blue: {
    bg: '#EFF7FF',
    link: '#43A1FF'
  }
};

interface TextCtaComponentProps {
  style?: string;
  color?: string;
  content: any;
}

export const TextCtaComponent = (props: TextCtaComponentProps) => {
  const { style = 'green', color = COLORS[style], content, ...rest } = props;
  const { st, className } = useColorStyles({ color }, theme);

  return (
    <Box
      pad='large'
      background={color.bg}
      linkColor={color.link}
      margin={{ bottom: 'medium' }}
      style={st}
      className={cn(
        '[&_h2,h3,h4]:text-bor-lg [&_h2,h3,h4_a]:!font-bold [&_p:last-child]:mb-0 [&_p]:mt-2 [&_p]:leading-[22px] [&_h2,h3,h4]:leading-[26px]',
        className
      )}
      {...rest}
    >
      <SanityBlockComponent blocks={content} />
    </Box>
  );
};

const useColorStyles = (props, theme) => {
  if (props.color) {
    return {
      st: { '--color-0f4e': theme.colors[props.color] || props.color },
      className: '[&_a]:!text-[var(--color-0f4e)]'
    };
  }

  return {};
};
