import React from 'react';
import { cn, Heading } from '@superside/ui';
import { Box, classForExcludingFromRichEditorRules } from '@konsus/superside-kit';
import { RichTextSuperscript } from '../RichTextSuperscript';

interface RichTextStatisticsProps {
  node: {
    items: {
      allItemsHaveIcon?: boolean;
      icon?: string;
      superscript: string;
      title: string;
      _key: string;
    }[];
  };
}

export const RichTextStatistics: React.FC<RichTextStatisticsProps> = ({ node }) => {
  const { items } = node;

  const allItemsHaveIcon = items.every((obj) => !!obj.icon);

  return (
    <Box
      className={cn(
        'rte-sub',
        'rte-sub-banner',
        '768:p-[38px] <1280:flex-col <1280:gap-10 overflow-hidden rounded-md bg-[#F4F4F9] p-6',
        'dark:absolute dark:bottom-[5%] dark:w-full dark:max-w-[90%] dark:flex-1 dark:flex-row dark:flex-wrap dark:items-end dark:justify-start dark:gap-3 dark:bg-transparent dark:p-0',
        'marble:w-full marble:max-w-[90%] marble:flex-row marble:flex-wrap marble:items-end marble:justify-start marble:gap-3 marble:bg-transparent marble:p-0 marble:!mt-auto'
      )}
      direction='row'
      justify='around'
    >
      {items?.map((item) => (
        <Box
          className={cn(
            '1280:[&:not(:last-child)]:mr-[5%] 1280:[&:not(:last-child)]:pr-[5%] 1280:[&:not(:last-child)]:relative 1280:[&:not(:last-child)_:after]:absolute 1280:[&:not(:last-child)_:after]:bg-[#DDDEE5] 1280:marble:[&:not(:last-child)_:after]:bg-black-500/10 1280:[&:not(:last-child)_:after]:block 1280:[&:not(:last-child)_:after]:content-empty 1280:[&:not(:last-child)_:after]:h-full 1280:[&:not(:last-child)_:after]:right-0 1280:[&:not(:last-child)_:after]:top-0 1280:[&:not(:last-child)_:after]:w-[2px] 1280:[&:not(:last-child)_:after]:z-1 768:gap-8 1280:[&:not(:last-child)_:after]:dark:bg-light/10 box-border flex-auto gap-6 dark:shrink-0',
            items.length > 2 && '<1280:dark:[&:last-child]:hidden'
          )}
          key={item._key}
          justify='start'
          align='center'
          direction='row'
        >
          {allItemsHaveIcon && item.icon ? (
            <Box className={'h-16 w-16'} dangerouslySetInnerHTML={{ __html: item.icon }} />
          ) : null}
          <Box className='768:flex-col flex flex-col-reverse'>
            <RichTextSuperscript className='dark:<768:text-xs dark:<768:font-normal dark:<768:normal-case dark:<768:tracking-normal'>
              {item.superscript}
            </RichTextSuperscript>
            <Heading className={classForExcludingFromRichEditorRules} level='h5' as='h2'>
              {item.title}
            </Heading>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
