import React, { useCallback, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import NextImage from '@konsus/image';
import { Check } from '@konsus/superside-kit/src/images/icons';
import useSanity, { defaultSanityClient } from '@konsus/sanity-client';
import SubscribeForm from '@konsus/lego/src/blocks/SubscribeForm';
import {
  Box,
  Button,
  Icon,
  Text,
  RelativeBreakpointBasisBox,
  RelativeBreakpointShowBox
} from '@konsus/superside-kit';
import { useAnalytics } from '@konsus/analytics';
import { useSubscribeForm } from '@konsus/lego/src/utils/useSubscribeForm';
import { type SanityBlockContentProps } from '../SanityBlockComponent';

export interface CtaWithImageComponentProps {
  SanityBlockComponent: React.FC<SanityBlockContentProps>;
  ImageComponent: React.FC;
  options: any;
  node: any;
}

export const CtaWithImageComponent = (props: CtaWithImageComponentProps) => {
  const { SanityBlockComponent, node } = props;

  const { content, buttonLabel, buttonUrl, withForm = false, imageAltText, image } = node;

  const bgImage = image ? imageUrlBuilder(defaultSanityClient).image(image) : null;

  return (
    <Box
      className={
        '[html_&_p]:text-bor-light [html_&_h2,h3,h4]:mb-4 [html_&_h2,h3,h4]:text-[22px] [html_&_h2,h3,h4]:font-bold [html_&_h2,h3,h4]:leading-[28px] [html_&_h2,h3,h4]:text-white [html_&_p:last-child]:mb-0 [html_&_p]:mt-2 [html_&_p]:leading-[22px]'
      }
      margin={{ bottom: 'medium' }}
      direction='row'
    >
      {image ? (
        <RelativeBreakpointShowBox width='240px' background='#1F154A' sm={false}>
          <NextImage image={image} objectFit='cover' alt={imageAltText} />
        </RelativeBreakpointShowBox>
      ) : null}
      <RelativeBreakpointBasisBox
        background='#1F154A'
        pad={{ horizontal: 'large', vertical: 'large' }}
        flex={{ grow: 1, shrink: 0 }}
        xxlg={bgImage ? 'calc(100% - 240px)' : '100%'}
        sm='100%'
        align='start'
      >
        {withForm ? (
          <SubscribeBlock />
        ) : (
          <>
            <SanityBlockComponent blocks={content} />
            <Button
              margin={{ top: 'medium' }}
              className='BlogPostButton'
              primary
              small
              bold
              href={buttonUrl}
              label={buttonLabel}
              clickArea
            />
          </>
        )}
      </RelativeBreakpointBasisBox>
    </Box>
  );
};

const SubscribeBlock = () => {
  const { track } = useAnalytics();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [blogSubscribeComponent] = useSanity(`*[_type == 'blogSubscribeComponent'][0]`);

  const onSuccess = useCallback(() => {
    track('Blog Subscribed', { source: 'inside content' });
    setFormSubmitted(true);
  }, [track]);

  const { subscribeFormHandler } = useSubscribeForm({ onSuccess });

  return (
    <Box align='stretch'>
      <Text size='large' weight='bold' alternative margin={{ bottom: 'large' }}>
        {blogSubscribeComponent?.data?.title ||
          'Join our community of 15,000 strong and receive the best design and marketing content, biweekly'}
      </Text>

      {formSubmitted ? (
        <Box gap='xsmall' pad={{ top: 'small', bottom: 'medium' }} direction='row' align='center'>
          <Icon icon={Check} color='primary' size='48px' />
          <Text size='large' weight='bold' margin={{ top: 'xsmall' }}>
            Subscription created!
          </Text>
        </Box>
      ) : (
        <>
          <SubscribeForm onSubmit={subscribeFormHandler} />
          <Text size='small' margin={{ bottom: 'xsmall' }}>
            No charge. Unsubscribe anytime
          </Text>
        </>
      )}
    </Box>
  );
};
