import React, { useState } from 'react';
import { cn, Heading } from '@superside/ui';
import {
  Box,
  ExternalContentWrapper,
  RelativeBox,
  Text,
  classForExcludingFromRichEditorRules,
  contentWrapperStyle,
  theme
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import NextImage from '@konsus/image';
import { defaultQuestions } from '../../components/ProgressiveForm/mock';
import { ProgressiveForm } from '../../components/ProgressiveForm/ProgressiveForm';
import {
  bannerBox,
  content,
  descriptionStyle,
  desktopBgImageStyle,
  desktopOverflowImageContainerStyle,
  desktopOverflowImageStyle,
  headingStyle,
  image,
  mobileOverflowImageContainerStyle,
  mobileOverflowImageImageStyle,
  subHeaderTextBoxStyle
} from './styles';
import { BannerSuccessContent } from './BannerSuccessContent';
import { type BannerProps } from './types';
import { BannerFailureContent } from './BannerFailureContent';

export const BannerWithProgressiveForm: React.FC<BannerProps> = ({
  copy: { headerText, subHeaderRichText, subHeaderText } = {},
  desktopBgImage,
  isLazy,
  desktopOverflowImage,
  mobileOverflowImage,
  successContent,
  form,
  imageOverflowType,
  onlyCompanyEmail,
  richTextProgressiveForm,
  questions = defaultQuestions,
  formSource,
  predefinedForm
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFormError, setIsFormError] = useState(false);

  const isPredefinedForm = formSource === 'predefinedForm';

  const finalForm =
    isPredefinedForm && predefinedForm?.questions
      ? predefinedForm.form
      : form || richTextProgressiveForm;

  if (isSuccess) {
    return <BannerSuccessContent successContent={successContent} />;
  }

  if (isFormError) {
    return <BannerFailureContent onRetryClick={() => setIsFormError(false)} />;
  }

  return (
    <RelativeBox
      className={cn(
        bannerBox,
        '<1024:flex-col <1024:gap-y-4 768:px-10 1024:px-12 text-white-700 flex-row gap-x-12 overflow-visible rounded-lg'
      )}
      background={theme.colors['space-black']}
    >
      {desktopBgImage ? (
        <Box className={cn(image, desktopBgImageStyle)}>
          <NextImage
            layout='fill'
            objectFit={'cover'}
            objectPosition='center'
            image={desktopBgImage}
            alt={headerText ?? 'image background'}
            loading={isLazy ? 'lazy' : 'eager'}
            className={classForExcludingFromRichEditorRules}
          />
        </Box>
      ) : null}
      {mobileOverflowImage ? (
        <Box className={mobileOverflowImageContainerStyle}>
          <NextImage
            image={mobileOverflowImage}
            alt={headerText ?? 'image background'}
            loading={isLazy ? 'lazy' : 'eager'}
            className={cn(classForExcludingFromRichEditorRules, mobileOverflowImageImageStyle)}
          />
          <div
            className={
              'absolute bottom-0 left-0 h-5 w-full bg-[linear-gradient(360deg,#0f2133_0%,rgba(15,33,51,0)_100%)]'
            }
          />
        </Box>
      ) : null}
      <Box
        className={cn(content, '1280:my-12 768:my-10 <=768:pb-5 <=768:pt-0 <=768:px-[15px] flex-1')}
      >
        {headerText && (
          <Heading className={cn(classForExcludingFromRichEditorRules, headingStyle)} level='h3'>
            <Box dangerouslySetInnerHTML={{ __html: headerText }} />
          </Heading>
        )}
        {subHeaderRichText || subHeaderText ? (
          <Box maxWidth={'100%'} className={cn(descriptionStyle, 'dark !max-w-full')}>
            {subHeaderRichText ? (
              <ExternalContentWrapper
                className={cn(contentWrapperStyle, subHeaderTextBoxStyle)}
                margin={{ top: '3' }}
              >
                <SanityBlockComponent blocks={subHeaderRichText} />
              </ExternalContentWrapper>
            ) : subHeaderText ? (
              <Text
                className={cn(classForExcludingFromRichEditorRules, subHeaderTextBoxStyle)}
                maxWidth='590px'
                margin={{ top: '3' }}
              >
                {subHeaderText}
              </Text>
            ) : null}
            <ProgressiveForm
              form={finalForm}
              onFormSuccess={() => setIsSuccess(true)}
              onFormError={() => setIsFormError(true)}
              questions={
                isPredefinedForm && predefinedForm?.questions ? predefinedForm.questions : questions
              }
              otherProps={{
                inputClassName: 'text-bor-lg <768:text-bor-sm',
                onlyCompanyEmail
              }}
            />
          </Box>
        ) : null}
      </Box>
      <div className='<768:hidden w-80' />
      {desktopOverflowImage ? (
        <Box
          className={cn(
            desktopOverflowImageContainerStyle,
            imageOverflowType === 'top' && 'bottom-0',
            imageOverflowType === 'top|bottom' && '-top-10'
          )}
        >
          <NextImage
            image={desktopOverflowImage}
            alt={headerText || 'image-background'}
            loading={isLazy ? 'lazy' : 'eager'}
            className={cn(classForExcludingFromRichEditorRules, desktopOverflowImageStyle)}
          />
        </Box>
      ) : null}
    </RelativeBox>
  );
};
