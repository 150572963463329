import React, { useState } from 'react';
import { cn, Heading } from '@superside/ui';
import {
  Box,
  type BoxProps,
  Collapsible,
  type HeadingProps,
  RelativeBox,
  Text,
  type TextProps,
  Icon,
  ExternalContentWrapper,
  contentWrapperStyle
} from '@konsus/superside-kit';
import { ChevronDown, ChevronUp } from '@konsus/superside-kit/src/images/icons';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import VisualAsset, { type VisualAssetProps } from '../../components/VisualAsset';

export interface FaqListItemProps extends BoxProps {
  question: string;
  answer: string;
  richAnswer?: { content: Array<any> };
  size?: TextProps['size'];
  headingLevel?: HeadingProps['level'];
  asset?: VisualAssetProps;
  topAlignImages?: boolean;
}

export const FaqListItem: React.FC<FaqListItemProps> = (props) => {
  const { question, answer, richAnswer, asset, topAlignImages, ...rest } = props;

  const [questionVisibility, setQuestionVisibility] = useState(false);

  return (
    <RelativeBox
      pad={{ top: '8', bottom: '8' }}
      {...rest}
      className={cn(
        '[&_a]:text-light [.light_&]:border-b-dark/10 [.light_&_a]:text-initial border-b-light/10 w-full border-b border-solid last:border-b-[none] [.light_&]:border-b [.light_&]:border-solid [.rte-sub_&_a]:text-indigo-500 [.rte-sub_&_a]:underline [.rte-sub_&_a_&:hover]:text-indigo-600 [.rte-sub_&_a_&:hover]:no-underline',
        rest.className
      )}
    >
      <button
        className={'[.dark_&]:text-light [.dark_&_&:hover]:text-light'}
        onClick={() => setQuestionVisibility(!questionVisibility)}
      >
        <Box direction='row' justify='between' gap='small' align='center'>
          <Heading className='max-w-[1136px] text-start' level='h5'>
            {question}
          </Heading>
          <Icon icon={questionVisibility ? ChevronUp : ChevronDown} size='medium' />
        </Box>
      </button>
      <Collapsible open={questionVisibility}>
        <Box direction='row' gap='large' align='center' className={topAlignImages && ''}>
          <Box className={'flex-1'}>
            {richAnswer ? (
              <ExternalContentWrapper
                maxWidth='1200px'
                margin={{ top: '4' }}
                className={cn(
                  contentWrapperStyle,
                  '[&_p,ul,ol]:text-bor-lg [&_ul_li:before]:hidden'
                )}
              >
                <SanityBlockComponent blocks={richAnswer} />
              </ExternalContentWrapper>
            ) : answer ? (
              <Text
                maxWidth='1200px'
                size='large'
                margin={{ top: '4' }}
                // @ts-ignore
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            ) : null}
          </Box>
          <Box className={'1280:block mt-4 hidden max-w-[352px] [&_div]:h-full [&_div]:w-full'}>
            <VisualAsset {...asset} />
          </Box>
        </Box>
      </Collapsible>
    </RelativeBox>
  );
};
