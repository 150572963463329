import React from 'react';
import { cn } from '@superside/ui';
import { useScript } from '../../hooks/useScript';

const wistiaScriptUrl = `https://fast.wistia.com/assets/external/E-v1.js`;

export const WistiaVideo = ({ id, square }: { id: string; square?: boolean }) => {
  useScript(`//fast.wistia.com/embed/medias/${id}.jsonp`);
  useScript(wistiaScriptUrl);

  return (
    <div
      className={cn('wistia_responsive_padding relative w-full', square && 'px-0 pb-0 pt-[100%]')}
    >
      <div className='wistia_responsive_wrapper marble:[&_.w-video-wrapper]:!bg-transparent absolute left-0 top-0 h-full w-full'>
        <div className={`wistia_embed h-full w-full wistia_async_${id} seo=false videoFoam=true`}>
          &nbsp;
        </div>
      </div>
    </div>
  );
};

export default WistiaVideo;
