import React, { forwardRef } from 'react';
import { cn } from '@superside/ui';
import { Box, RelativeBox, type BoxProps } from '../Box';
import { useBreakpointShowStyle } from './breakpointShowStyles';

export interface breakpointShowProps {
  xxlg?: boolean;
  xlg?: boolean;
  lg?: boolean;
  md?: boolean;
  sm?: boolean;
  xsm?: boolean;
}

export interface BreakpointShowBoxProps extends BoxProps, breakpointShowProps {
  className?: string;
}

export const BreakpointShowBox: React.FC<BreakpointShowBoxProps> = forwardRef((props, ref) => {
  const { className } = useBreakpointShowStyle(props);

  return <Box ref={ref} {...props} className={cn(props.className, className)} />;
});

export const RelativeBreakpointShowBox: React.FC<BreakpointShowBoxProps> = forwardRef(
  (props, ref) => {
    const { className } = useBreakpointShowStyle(props);

    return <RelativeBox ref={ref} {...props} className={cn(props.className, className)} />;
  }
);

export default BreakpointShowBox;
