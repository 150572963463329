import React, { type ChangeEvent, type FormEvent, useCallback, useState } from 'react';
import { Envelope as EmailIcon } from 'phosphor-react';
import { TextInput, cn, Box, Button, Text } from '@superside/ui';
import { VALID_EMAIL_REGEX, FORM_ERRORS } from '@konsus/apps-common';

export const SubscribeForm: React.FunctionComponent<{
  onSubmit: (form: any) => Promise<any>;
  background?: string;
  buttonLabel?: string;
  placeholderText?: string;
  small?: boolean;
  legacy?: boolean;
}> = (props) => {
  const {
    buttonLabel = 'Subscribe',
    placeholderText = 'john@superside.com',
    small = false,
    legacy = true
  } = props;

  const [email, setEmail] = useState<string>();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState<Boolean | null>(null);
  const inputOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);
  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const errors: any = validate({ email });

      if (errors.email) {
        setError(errors.email);

        return;
      }
      setLoading(true);
      props.onSubmit({ email }).finally(() => setLoading(false));
    },
    [email, props]
  );

  return (
    <form id='subscribe-form' onSubmit={handleSubmit}>
      <Box className='light relative'>
        <Box className={cn(!small && '768:flex-row 768:gap-4', 'flex-col gap-0')}>
          <Box className='flex-grow'>
            <Box
              className={cn(
                'relative mb-2 bg-transparent [&_input]:pl-12 [input_&:focus]:px-[47.5px] [input_&:focus]:py-[15.5px]',
                error &&
                  '[&_input]:border-coral-500 [input_&:hover,&:focus]:border-coral-500 [input_&:hover,&:focus]:border [input_&:hover,&:focus]:border-solid [input_&:hover,&:focus]:px-12 [input_&:hover,&:focus]:py-4'
              )}
            >
              <EmailIcon size='22' className={'absolute left-4 top-2/4 -translate-y-2/4 '} />
              <TextInput placeholder={placeholderText} onChange={inputOnChange} />
            </Box>
          </Box>

          <Box className='flex-shrink'>
            <Button
              variant='primary'
              type='submit'
              disabled={Boolean(loading)}
              label={loading ? 'Submitting...' : buttonLabel}
            />
          </Box>
        </Box>

        {error ? (
          <Text
            className={cn(
              'text-coral-500 mt-3 block w-full text-sm leading-normal',
              legacy && 'absolute left-0 top-full mt-1'
            )}
          >
            {error}
          </Text>
        ) : null}
      </Box>
    </form>
  );
};

type FormValues = {
  email?: string;
};

const validate = (values: FormValues) => {
  const errors: FormValues = { email: undefined };

  if (!values.email) {
    errors.email = FORM_ERRORS.REQUIRED;

    return errors;
  }

  if (!VALID_EMAIL_REGEX.test(values.email)) {
    errors.email = FORM_ERRORS.INVALID_EMAIL;

    return errors;
  }

  return errors;
};

export default SubscribeForm;
