import { useEffect, useState } from 'react';

const MAXIMUM_PEOPLE_NUMBER = 5;
const MINIMUM_PEOPLE_NUMBER = 3;

export const generateRandomNumber = (prevState: number) => {
  let randomNumber =
    Math.floor(Math.random() * (MAXIMUM_PEOPLE_NUMBER - MINIMUM_PEOPLE_NUMBER + 1)) +
    MINIMUM_PEOPLE_NUMBER;

  if (randomNumber > prevState + 1 || randomNumber === prevState) {
    randomNumber = prevState + 1;
  } else if (randomNumber < prevState - 1) {
    randomNumber = prevState - 1;
  }

  return randomNumber;
};

export const usePeopleSignups = (avatarsInterval: number) => {
  const [otherPeopleNumber, setOtherPeopleNumber] = useState(MINIMUM_PEOPLE_NUMBER);
  const [animateAvatarStack, setAnimateAvatarStack] = useState(false);

  useEffect(() => {
    const peopleSignupInterval = setInterval(() => {
      setOtherPeopleNumber((prevState) => {
        const newState = generateRandomNumber(prevState);

        if (newState < prevState) {
          setAnimateAvatarStack(true);
        }

        return newState;
      });
    }, avatarsInterval * 1000);

    return () => {
      clearInterval(peopleSignupInterval);
    };
  }, [avatarsInterval]);

  return { otherPeopleNumber, animateAvatarStack, setAnimateAvatarStack };
};
