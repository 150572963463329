import { useEffect, useState } from 'react';
import { type TimeUnitsType } from '../types';

const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;
const MILLISECONDS_PER_MINUTE = SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;
const MILLISECONDS_PER_HOUR = MINUTES_PER_HOUR * MILLISECONDS_PER_MINUTE;
const MILLISECONDS_PER_DAY = HOURS_PER_DAY * MILLISECONDS_PER_HOUR;

export const getTimeDifferenceString = (ms: number) => Math.floor(ms).toString().padStart(2, '0');

export const useCountdownInterval = (eventDate: string) => {
  const parsedDate = Date.parse(eventDate);
  const [timeUnits, setTimeUnits] = useState<TimeUnitsType>();

  useEffect(() => {
    function refreshTimeUnits() {
      const nowDate = Date.now();
      const timeDifference = Math.max(parsedDate - nowDate, 0);
      const days = getTimeDifferenceString(timeDifference / MILLISECONDS_PER_DAY);
      const hours = getTimeDifferenceString(
        (timeDifference % MILLISECONDS_PER_DAY) / MILLISECONDS_PER_HOUR
      );
      const minutes = getTimeDifferenceString(
        (timeDifference % MILLISECONDS_PER_HOUR) / MILLISECONDS_PER_MINUTE
      );

      const seconds = getTimeDifferenceString(
        (timeDifference % MILLISECONDS_PER_MINUTE) / MILLISECONDS_PER_SECOND
      );

      setTimeUnits({ days, hours, minutes, seconds });
    }

    const countdownInterval = setInterval(() => {
      refreshTimeUnits();
    }, MILLISECONDS_PER_SECOND);

    refreshTimeUnits();

    return () => {
      clearInterval(countdownInterval);
    };
  }, [parsedDate]);

  const isZeroTimeLeft =
    timeUnits?.days === '00' && timeUnits?.hours === '00' && timeUnits?.minutes === '00';

  return { timeUnits, isZeroTimeLeft };
};
