import { type SanityVisualAssetBackground } from 'sanity.types';
import { type SanityImage } from '@konsus/image';
import { type PaddingSize } from '../components/PageSection';
import { type TrackingFormData } from '../utils/useSubscribeForm';

export type PersonalizedVideoType = {
  key: string;
  persona: string;
  service: string;
  wistiaVideoId: string;
};

export type FormObjectType = {
  formOutput?: 'toast' | 'redirect' | 'video' | 'ticket' | 'redirectProps';
  hubspotFormId?: string;
  placeholderText?: string;
  submitButtonText?: string;
  openScheduler?: boolean;
  routerName?: string;
  formName?: string;
  formId?: string;
  redirectLink?: string;
  trackingEvents?: TrackingFormData;
  onlyCompanyEmail?: boolean;
  showTopLeftAnimation?: boolean;
  isTicket?: boolean; // TODO remove after formOutput complete release
  isPersonalizedVideo?: boolean; // TODO remove after formOutput complete release
  personalizedVideos?: {
    keysWithVideos: PersonalizedVideoType[];
    specificOptions: string[];
  };
  toastSuccessText?: string;
  toastErrorText?: string;
};

export interface IForm {
  formId?: string;
  buttonLabel?: string;
  eventText?: string;
  hasFullNameField?: boolean;
}

export interface HubspotForm extends IForm {
  removePhone?: boolean;
  hideMinimalCostNote?: boolean;
  schedulingProvider?: SCHEDULING_PROVIDER;
  useEmbedForm?: boolean;
}

export enum FORM_TYPES {
  HUBSPOT = 'hubspot'
}

export type FormType = FORM_TYPES.HUBSPOT;

export enum SCHEDULING_PROVIDER {
  CHILIPIPER = 'chilipiper'
}

export interface BookACallFormProps {
  superscriptText?: string;
  headerText?: string;
  headerRichText?: { content: Array<any> };
  richText?: any;
  formType?: FormType;
  form?: HubspotForm;
  background?: SanityVisualAssetBackground;
  paddingFields?: {
    paddingTop?: PaddingSize;
    paddingBottom?: PaddingSize;
  };
  removeTopMargin?: boolean;
  contentImage?: SanityImage;
}
