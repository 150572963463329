import React from 'react';
import { cn } from '@superside/ui';

export interface RichTextDividerProps {
  node: {
    isDark?: boolean;
  };
}

export const RichTextDivider: React.FC<RichTextDividerProps> = ({ node: { isDark } }) => {
  return (
    <div className={cn('bg-dark dark:bg-light my-6 h-px w-full opacity-10', isDark && 'dark')} />
  );
};
