import React, { type RefObject, forwardRef, useEffect, useRef, useState } from 'react';
import { cn } from '@superside/ui';
import { isChromatic } from '../../utils';

export interface VideoProps {
  videoUrl: string;
  videoThumbnailUrl?: string;
  videoPosition?: NonNullable<JSX.IntrinsicElements['video']['style']>['objectPosition'];
  hideLoadingBar?: boolean;
  className?: string;
  onClick?: any;
  maxWidthClassName?: string;
}

export const Video = forwardRef<HTMLVideoElement, VideoProps>((props, ref) => {
  const {
    videoUrl,
    videoThumbnailUrl,
    videoPosition = 'center',
    hideLoadingBar = !!videoThumbnailUrl,
    className,
    maxWidthClassName,
    onClick
  } = props;

  const videoRefDuplicate = useRef<HTMLVideoElement>(null);
  const videoRef = (ref ?? videoRefDuplicate) as RefObject<HTMLVideoElement>;

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoUrlArr = videoUrl.split('.');
  const extension = videoUrlArr[videoUrlArr.length - 1];
  const [loadPercentage, setLoadPercentage] = useState(0);
  const [videoHeight, setVideoHeight] = useState('100%');

  useEffect(() => {
    const videoRefCurrent = videoRef?.current;

    const { videoWidth, videoHeight } = videoRefCurrent || { videoWidth: 0, videoHeight: 0 };
    const { clientWidth } = videoContainerRef?.current! || {};

    const calculatedVideoHeight =
      videoWidth > 0 && videoHeight > 0 ? `${(clientWidth * videoHeight) / videoWidth}px` : '100%';

    setVideoHeight(calculatedVideoHeight);

    videoRefCurrent?.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );

    videoRefCurrent?.addEventListener('progress', function () {
      try {
        const r = videoRefCurrent.buffered;
        const total = videoRefCurrent.duration;
        const end = r.end(0);
        const lp = end / total;

        setLoadPercentage(lp * 100);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });
  }, [ref, videoRef]);

  return videoUrl ? (
    <div
      className={cn(
        'relative inset-0 h-[var(--video-container-height-c0fa)] max-h-full min-h-full w-full min-w-full max-w-full',
        className,
        maxWidthClassName
      )}
      style={
        {
          '--video-container-height-c0fa': videoHeight
        } as React.CSSProperties
      }
      ref={videoContainerRef}
      onClick={onClick}
    >
      {!hideLoadingBar ? (
        <div
          className={
            'absolute inset-x-0 bottom-0 h-1 w-0 bg-green-500 transition-all duration-[1s] ease-[ease-in-out]'
          }
          data-testid='loading-bar-data-testid'
          style={{
            width: loadPercentage < 100 ? `${loadPercentage}%` : 0,
            display: loadPercentage < 100 ? 'block' : 'none'
          }}
        />
      ) : null}
      <video
        autoPlay={!isChromatic}
        playsInline
        muted={true}
        loop
        controls={false}
        disablePictureInPicture
        controlsList='nodownload nofullscreen noremoteplayback'
        poster={videoThumbnailUrl}
        ref={videoRef}
        className={cn(
          'h-full max-h-full min-h-full w-full min-w-full max-w-full object-cover object-[var(--inline-video-position-8b47)]',
          maxWidthClassName
        )}
        data-testid='video-data-testid'
        style={
          {
            '--inline-video-position-8b47': videoPosition
          } as React.CSSProperties
        }
        src={videoUrl}
      >
        <source data-testid='video-source-data-testid' src={videoUrl} type={`video/${extension}`} />
      </video>
    </div>
  ) : null;
});

Video.displayName = 'Video';

export default Video;
