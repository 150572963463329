import React from 'react';
import { cn } from '@superside/ui';
import atom, { useAtomValue } from '@konsus/atoms';

export const plainLayoutAtom = atom<boolean | null>(null);

interface AnchorPointProps {
  node: {
    id: string;
  };
  plain?: boolean;
  mark?: {
    id: string;
  };
  children?;
}

export const AnchorPoint: React.FC<AnchorPointProps> = (props) => {
  const plainLayout = useAtomValue(plainLayoutAtom);
  const { node, plain = false, mark } = props;

  if (plain) {
    return (
      <span
        id={mark.id?.trim()}
        className={cn(
          `before:content-empty font-bold before:pointer-events-none before:invisible before:inline-block before:w-[1px]`,
          plainLayout
            ? 'before:mt-0 before:h-0'
            : '1024:before:h-24 1024:before:-mt-24 before:-mt-6 before:h-6'
        )}
      >
        {props.children}
      </span>
    );
  }

  return <div id={node.id} />;
};
