import React from 'react';
import NextImage from '@konsus/image';
import { Box, cn } from '@superside/ui';
import { SanityBlockComponent } from '../../SanityBlockComponent';
import styles from './RichTextQuote.module.css';
import { type QuoteProps } from './index';

export const QuoteVariant2 = (props: QuoteProps) => {
  const { author, text } = props;

  if (!text) {
    return null;
  }

  return (
    <Box className='rte-sub my-15 flex-row'>
      <Box className='1280:justify-between 1280:pl-12 1280:pl-0 flex-shrink'>
        <Box
          className={cn(
            styles.bgV2,
            '1024:[&:before]:h-[24px] 1024:[&:before]:w-[30px] 1024:[&_p::first-letter]:ml-[46px] 1024:[&_p]:!text-[23px] 1280:[&:before]:h-[30px] 1280:[&:before]:w-[38px] 1280:[&_p::first-letter]:ml-[54px] 1280:[&_p]:!text-[28px] 1280:[&_p]:font-semibold text-bor-lg relative font-semibold before:content-[""] [&:before]:absolute [&:before]:left-0 [&:before]:top-0 [&:before]:block [&:before]:h-[21px] [&:before]:w-[26px] [&:before]:bg-cover [&_p::first-letter]:ml-[38px]'
          )}
        >
          <Box className='1024:[&_p]:!leading-[32px] 1280:[&_p]:!leading-[40px]'>
            {typeof text === 'string' ? <p>{text}</p> : <SanityBlockComponent blocks={text} />}
          </Box>
        </Box>

        {author?.name ? (
          <Box className='mt-6 flex-row items-center gap-6'>
            {author.image ? (
              <Box className='1280:hidden 1280:flex h-14 w-14'>
                <NextImage layout='responsive' image={author.image} alt={author.name} />
              </Box>
            ) : null}

            <Box className='shrink grow'>
              <span className='768:text-bor-lg font-semibold'>{author.name}</span>
              {author.position ? <span>{author.position}</span> : null}
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
