import React, { useMemo, forwardRef, type Ref } from 'react';
// @ts-ignore implicit 'any' type
import BlockContent from '@sanity/block-content-to-react';
import { sanityConfig } from '@konsus/sanity-client';
import { richTextContainerClass } from '@konsus/superside-kit';
import type { SlideType } from '@konsus/lego/src/components/VisualAsset/types';
import atom from '@konsus/atoms';
import { AnimatedText } from '@superside/ui';
import { HighlightBlockBlue } from './HighlightBlockBlue';
import { SectionHeader } from './SectionHeader';
import { AdaptiveImageComponent } from './AdaptiveImageComponent';
import { AnchorPoint } from './AnchorPoint';
import { BlockQuote } from './BlockQuote';
import { CardsComponent } from './CardsComponent';
import { CtaWithImageComponent } from './CtaWithImageComponent';
import { ImageComponent } from './ImageComponent';
import { ImageLinkComponent } from './ImageLinkComponent';
import { ImageWithCreditContent } from './ImageWithCredit';
import { InstagramComponent } from './InstagramComponent';
import { LinkNewWindowComponent } from './LinkNewWindowComponent';
import { ListComponent } from './ListComponent';
import { RichTextWistiaVideo } from './RichTextWistiaVideo';
import { TabsComponent } from './TabsComponent';
import { TextCtaComponent } from './TextCtaComponent';
import { TwitterPostComponent } from './TwitterPostComponent';
import { GoogleSlidesComponent } from './GoogleSlidesComponent';
import { QuoteComponent } from './QuoteComponent';
import { Typeform } from './Typeform';
import { HighlightText } from './HighlightText';
import { GreenColorText } from './GreenColorText';
import { GreenBgText } from './GreenBgText';
import { BlueColorText } from './BlueColorText';
import { NebulaIndigoBgText } from './NebulaIndigoBgText';
import { TweetThisWrapper } from './TweetThisWrapper';
import { LinkedInComponent } from './LinkedInComponent';
import { TikTokComponent } from './TikTokComponent';
import SanityImage from './SanityImage';
import { SerifText } from './SerifText';
import { ResourcePreviewComponent } from './ResourcePreviewComponent';
import {
  ContentCheckboxes,
  FacebookPostComponent,
  IframeComponent,
  RichTextTableOfContents,
  RichTextExternalLink,
  RichTextImage,
  RichTextBanner,
  RichTextStatisticBanner,
  RichTextQuote,
  RichTextPeopleCards,
  RichTextAssetsGrid,
  RichTextVideoWithQuote,
  RichTextStatistics,
  SectionTitle,
  RichTextVisualAsset,
  RichTextSuperscript,
  RichTextKeyTakeaways,
  RichTextSocialShareButtons,
  RichTextFomo,
  RichTextGame,
  RichTextTableComponent,
  RichTextLogoSalad,
  RichTextDivider,
  RichTextMeetingInfo,
  RichTextCollapsible,
  RichTextTags,
  RichTextFaqAccordion
} from './components';
import { TextBlockRenderer, type TextBlockRendererProps } from './TextBlockRenderer';
import { ListItemRenderer } from './ListItemRenderer';
import { ListRenderer } from './ListRenderer';

export const tweetThisValueAtom = atom<{ text?: string; coordinates?: { x: number; y: number } }>(
  {}
);

export interface SanityBlockContentProps {
  blocks?: any;
  components?: any;
  typeSerializers?: any;
  markSerializers?: any;
  options?: any;
  enableTweetThis?: boolean;
  tweetThisTarget?: React.RefObject<HTMLDivElement>;
  showPreviewModal?: boolean;
  slides?: SlideType[];
  title?: string;
  enableNewBlockRenderer?: boolean;
  /** `normal` type will be rendered as the specified Heading (added as part of RTE revamp) */
  renderNormalAsHeading?: TextBlockRendererProps['renderNormalAsHeading'];
  /** Currently only passed down to TextBlockRenderer */
  className?: string;
}

interface BlockRendererProps {
  QuoteComponent: any;
  RichTextSuperscript: any;
  enableTweetThis?: boolean;
  node: {
    style?: string;
  };
  children: any;
}

export const defaultImageOptions = { w: 1000, fit: 'max', quality: 90 };

/**
 * @deprecated Use {@link TextBlockRenderer} instead
 */
const BlockRenderer: React.FC<BlockRendererProps> = forwardRef<Ref<any>, BlockRendererProps>(
  (props, ref) => {
    const { QuoteComponent, RichTextSuperscript, enableTweetThis, node, children } = props;
    const { style = 'normal' } = node;

    if (style === 'disclaimer') {
      return <p className='!text-sm'>{children}</p>;
    }

    if (style === 'normal' && enableTweetThis) {
      return <TweetThisWrapper ref={ref}>{children}</TweetThisWrapper>;
    }

    if (style === 'blockquote' && QuoteComponent) {
      return <QuoteComponent>{children}</QuoteComponent>;
    }

    if (style === 'superscript') {
      return <RichTextSuperscript>{children}</RichTextSuperscript>;
    }

    // @ts-ignore
    return BlockContent.defaultSerializers.types.block(props);
  }
);

BlockRenderer.displayName = 'BlockRenderer';

export const SanityBlockComponent: React.FC<SanityBlockContentProps> = (props) => {
  const {
    typeSerializers,
    markSerializers,
    components,
    enableTweetThis = false,
    tweetThisTarget,
    showPreviewModal,
    slides,
    title,
    enableNewBlockRenderer = true,
    renderNormalAsHeading,
    className,
    ...rest
  } = props;

  const serializers = useMemo(() => {
    const finalComponents = {
      ImageComponent,
      SanityBlockComponent,
      QuoteComponent,
      RichTextSuperscript,
      ...components
    };

    const types = {
      block: enableNewBlockRenderer
        ? (props: any) => (
            <TextBlockRenderer
              {...props}
              className={className}
              renderNormalAsHeading={renderNormalAsHeading}
              // TODO
              enableTweetThis={enableTweetThis}
              ref={tweetThisTarget}
            />
          )
        : (props: any) => (
            <BlockRenderer {...props} enableTweetThis={enableTweetThis} ref={tweetThisTarget} />
          ),
      tabs: TabsComponent,
      list: ListComponent,
      cards: CardsComponent,
      image: (props: any) => <SanityImage sanityConfig={sanityConfig} {...props} />,
      richTextImage: (props: any) => (
        <RichTextImage
          showPreviewModal={showPreviewModal}
          slides={slides}
          previewModalTitle={title}
          {...props}
        />
      ),
      richTextTable: RichTextTableComponent,
      iframe: IframeComponent,
      imageLink: ImageLinkComponent,
      adaptiveImage: AdaptiveImageComponent,
      instagramPost: InstagramComponent,
      linkedinPost: LinkedInComponent,
      tiktokPost: TikTokComponent,
      facebookPostIframe: FacebookPostComponent,
      twitterPost: TwitterPostComponent,
      richTextGoogleSlides: GoogleSlidesComponent,
      blockQuote: BlockQuote,
      imageWithCredit: ImageWithCreditContent,
      contentCheckboxes: ContentCheckboxes,
      textCta: TextCtaComponent,
      ctaWithImage: CtaWithImageComponent,
      richTextWistiaVideo: RichTextWistiaVideo,
      richTextFaqAccordion: RichTextFaqAccordion,
      anchorPoint: AnchorPoint,
      sectionHeader: SectionHeader,
      typeform: Typeform,
      richTextTableOfContents: RichTextTableOfContents,
      richTextExternalLink: RichTextExternalLink,
      richTextBanner: RichTextBanner,
      richTextStatisticBanner: RichTextStatisticBanner,
      keyTakeawaysBanner: RichTextKeyTakeaways,
      richTextSocialShareButtons: RichTextSocialShareButtons,
      richTextFomo: RichTextFomo,
      richTextQuote: RichTextQuote,
      richTextPeopleCards: RichTextPeopleCards,
      assetsGrid: RichTextAssetsGrid,
      videoWithQuote: RichTextVideoWithQuote,
      richTextStatistics: RichTextStatistics,
      richTextSectionTitle: SectionTitle,
      visualAsset: (props: any) => (
        <RichTextVisualAsset
          showPreviewModal={showPreviewModal}
          slides={slides}
          previewModalTitle={title}
          {...props}
        />
      ),
      richTextGame: RichTextGame,
      richTextLogoSalad: RichTextLogoSalad,
      richTextDivider: RichTextDivider,
      richTextMeetingInfo: RichTextMeetingInfo,
      richTextCollapsible: RichTextCollapsible,
      richTextTags: RichTextTags,
      resourcePreview: ResourcePreviewComponent,
      ...typeSerializers
    };

    const marks = {
      link: LinkNewWindowComponent,
      anchorId: (props: any) => <AnchorPoint plain {...props} />,
      highlightBlockBlue: HighlightBlockBlue,
      highlight: HighlightText,
      serif: SerifText,
      galaxyBlueColor: BlueColorText,
      greenColor: GreenColorText,
      greenBg: GreenBgText,
      nebulaIndigoBg: NebulaIndigoBgText,
      animatedText: AnimatedText,
      ...markSerializers
    };

    return {
      types: enhanceComponents(types, finalComponents),
      marks: enhanceComponents(marks, finalComponents),
      listItem: ListItemRenderer,
      list: ListRenderer
    };
  }, [components, typeSerializers, markSerializers, sanityConfig, enableTweetThis]);

  return (
    <BlockContent
      projectId={sanityConfig.projectId}
      dataset={sanityConfig.dataset}
      imageOptions={defaultImageOptions}
      serializers={serializers}
      className={richTextContainerClass}
      {...rest}
    />
  );
};

export const enhanceComponents = (store: any, components: any) =>
  Object.keys(store).reduce((acc, key) => {
    const Component = store[key];

    return {
      ...acc,
      [key]: (props: any) => <Component {...components} {...props} />
    };
  }, {});

export default SanityBlockComponent;
