import { type ForwardedRef, useEffect, useRef, type MutableRefObject } from 'react';

// https://medium.com/the-non-traditional-developer/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
export const useForwardedRef = <T extends HTMLElement>(
  ref: ForwardedRef<T> | Function
): MutableRefObject<T | null> => {
  const innerRef = useRef<T>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });

  return innerRef;
};
