import React from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '@superside/ui';
import { type RichTextFomoType } from './types';
import { useAvatarAnimationRerender, useCountdownInterval, usePeopleSignups } from './utils';
import { Countdown, PeopleSignup } from './components';

const alignCva = cva('flex flex-col', {
  variants: {
    alignment: {
      left: 'items-start',
      center: 'items-center'
    }
  }
});

export const RichTextFomo: React.FC<RichTextFomoType> = ({
  node: {
    title = 'Event starts in',
    eventDate,
    enablePeopleSignup = true,
    avatarsInterval = 10,
    alignment = 'left'
  },
  className
}) => {
  const { otherPeopleNumber, animateAvatarStack, setAnimateAvatarStack } =
    usePeopleSignups(avatarsInterval);

  const avatarStackIndexes = useAvatarAnimationRerender(
    animateAvatarStack,
    setAnimateAvatarStack,
    otherPeopleNumber
  );

  const { timeUnits, isZeroTimeLeft } = useCountdownInterval(eventDate);

  const alignClass = alignCva({ alignment });

  return eventDate && timeUnits ? (
    <div className={cn(alignClass, 'mt-8', className)}>
      <Countdown timeUnits={timeUnits} title={title} />

      {enablePeopleSignup && !isZeroTimeLeft ? (
        <PeopleSignup
          otherPeopleNumber={otherPeopleNumber}
          avatarStackIndexes={avatarStackIndexes}
          animateAvatarStack={animateAvatarStack}
          className={alignClass}
        />
      ) : null}
    </div>
  ) : null;
};
