import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@superside/ui';

export interface ProgressiveSelectProps {
  options: Array<{ title: string; value: string }>;
  onChange: (value: string) => void;
  placeholder: string;
  className?: string;
}

export const ProgressiveSelect: React.FC<ProgressiveSelectProps> = ({
  options,
  onChange,
  placeholder,
  className
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const listRef = useRef(null);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (isOpen && event.key === 'Escape') {
        setIsOpen(false);
      }
    }
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const handleToggle = () => {
    setIsOpen((oldIsOpen) => !oldIsOpen);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
    onChange(option);
  };

  return (
    <div
      role='combobox'
      aria-expanded={isOpen}
      aria-controls='select-option-lists-testid'
      aria-haspopup='listbox'
    >
      <button
        data-testid='toggle-select-button-testid'
        className={cn('text-light w-full text-left text-lg font-semibold opacity-60', className)}
        onClick={handleToggle}
        aria-haspopup='true'
        aria-expanded={isOpen}
      >
        {selectedOption || placeholder}
      </button>
      <ul
        role='listbox'
        data-testid='select-option-lists-testid'
        className={cn(
          'bg-light border-white-100 absolute top-full z-10 mt-2 w-full rounded-b-lg shadow-lg transition-all',
          !isOpen && 'invisible opacity-0'
        )}
        ref={listRef}
      >
        {options?.map(({ value, title }) => (
          <li
            key={value}
            role='option'
            aria-selected={value === selectedOption}
            className={cn(
              'text-dark cursor-pointer px-4 py-2 hover:bg-blue-200',
              value === selectedOption && 'bg-blue-200',
              isOpen && 'block'
            )}
            onClick={() => handleOptionClick(value)}
          >
            {title}
          </li>
        ))}
      </ul>
    </div>
  );
};
