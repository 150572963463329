import { useEffect } from 'react';
import { SCHEDULING_PROVIDER } from '../../types/form';

export interface ChiliPiperType {
  submit: (domain: string, routerName: string, options: {}) => void;
  scheduling: any;
  showCalendar: any;
  widget: any;
  bookMeeting: any;
}

export function useChiliPiper(schedulingProvider: SCHEDULING_PROVIDER) {
  useEffect(() => {
    if (schedulingProvider !== SCHEDULING_PROVIDER.CHILIPIPER) {
      return;
    }

    // ChiliPiper code snippet
    if (!window.ChiliPiper) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.src = 'https://superside.chilipiper.com/concierge-js/cjs/concierge.js';
      script.async = true;
      document.body.appendChild(script);
    }

    function q(a: keyof ChiliPiperType) {
      return function () {
        // eslint-disable-next-line no-undef
        window.ChiliPiper[a].q = (window.ChiliPiper[a].q || []).concat([arguments]);
      };
    }

    const chiliPiperMethods: (keyof ChiliPiperType)[] = [
      'submit',
      'scheduling',
      'showCalendar',
      'widget',
      'bookMeeting'
    ];

    window.ChiliPiper =
      window.ChiliPiper ||
      chiliPiperMethods.reduce((acc, method) => {
        acc[method] = q(method);

        return acc;
      }, {} as ChiliPiperType);
  }, [schedulingProvider]);
}
