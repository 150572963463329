import React from 'react';

export const LinkIcon = ({ color = '#0B1926' }) => (
  <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.31641 15.1811L15.6804 8.81714'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0909 16.7729L11.4392 19.4245C10.5953 20.2682 9.45077 20.7421 8.25743 20.742C7.06409 20.7419 5.91965 20.2678 5.07583 19.424C4.23201 18.5802 3.75791 17.4357 3.75781 16.2424C3.75771 15.0491 4.23161 13.9045 5.07529 13.0606L7.72694 10.4089'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.2741 13.5904L19.9258 10.9388C20.7694 10.0948 21.2433 8.95028 21.2432 7.75694C21.2431 6.5636 20.769 5.41916 19.9252 4.57534C19.0814 3.73152 17.937 3.25743 16.7436 3.25732C15.5503 3.25722 14.4058 3.73112 13.5618 4.5748L10.9102 7.22645'
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
