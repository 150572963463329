import { useCallback } from 'react';
import { get as getCookie } from 'js-cookie';
import { useRouter } from 'adapter/next/router';
import {
  FORM_TRACKING_PROVIDERS,
  TRACKING_EVENT_NAMES,
  baseTrackingFieldsGenerator,
  useAnalytics
} from '@konsus/analytics';
import { useEventId } from './form';

export type TrackingFormData = {
  form: string;
  // eslint-disable-next-line camelcase
  form_type: string;
  item: string;
};

type SubscribeFormConfig = {
  onSuccess?: () => void;
  portalId?: string;
  formId?: string;
  hubSpotApiPoint?: string;
  trackingEvents?: TrackingFormData;
};

export const useSubscribeForm = (props: SubscribeFormConfig) => {
  const {
    onSuccess,
    portalId = '6380455',
    formId = '5c3579e9-7453-4329-ad87-9c7c57a06d25',
    hubSpotApiPoint = 'https://api.hsforms.com/submissions/v3/integration/submit',
    trackingEvents
  } = props;

  const { track: baseTrack } = useAnalytics();
  const { asPath } = useRouter();
  const eventId = useEventId();

  const baseTrackingFields = baseTrackingFieldsGenerator(eventId, formId, trackingEvents, asPath);

  const subscribeFormHandler = useCallback(
    (form) => {
      const data = JSON.stringify({
        submittedAt: Date.now(),
        fields: [
          {
            name: 'email',
            value: form.email
          }
        ],
        context: {
          hutk: getCookie('hubspotutk'),
          pageUri: window.location.href
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: 'I agree to receive marketing emails from Superside.'
          }
        },
        skipValidation: true
      });

      const track = (eventName, extraProperties = {}) =>
        baseTrack(eventName, {
          ...baseTrackingFields,
          ...(form?.blog_subscription ? { blog_subscription: true } : {}),
          ...extraProperties,
          provider: FORM_TRACKING_PROVIDERS.INTERNAL_INLINE
        });

      return fetch(`${hubSpotApiPoint}/${portalId}/${formId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: data
      })
        .then(() => {
          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION, {
            email: form.email
          });

          onSuccess && onSuccess();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          track(TRACKING_EVENT_NAMES.FORM_SUBMISSION_FAILURE, {
            email: form.email,
            error: err.message
          });
        });
    },
    [hubSpotApiPoint, portalId, formId, baseTrack, baseTrackingFields, onSuccess]
  );

  return {
    subscribeFormHandler
  };
};
