import { createCache as createSanityCache } from './sanityCache';
import { createClient as createSanityClient } from './createClient';
import { createSimpleClient as createSimpleSanityClient } from './createSimpleClient';
import { createAsyncClient as createAsyncSanityClient } from './createAsyncClient';
import { configureDefaultClient, defaultClient } from './defaultClient';
import { useSanity } from './useSanity';
import { useSanityClient } from './useSanityClient';
import { NotFoundError } from './errors';
import { sanityConfig } from './sanityConfig';
import { previewSanityConfig } from './previewSanityConfig';
import { sanityPatchConfig } from './sanityPatchConfig';

const defaultFetch = defaultClient.fetch;
const defaultLoadCache = defaultClient.cache.restore;
const defaultClearCache = defaultClient.cache.clear;
const defaultSerializeCache = defaultClient.cache.serialize;

export {
  configureDefaultClient,
  createSanityCache,
  createSanityClient,
  createAsyncSanityClient,
  createSimpleSanityClient,
  defaultClient as defaultSanityClient,
  defaultClearCache as clearCache,
  defaultFetch as fetch,
  defaultLoadCache as loadCache,
  defaultSerializeCache as serializeCache,
  useSanityClient,
  NotFoundError,
  sanityConfig,
  previewSanityConfig,
  sanityPatchConfig
};

export default useSanity;
