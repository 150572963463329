import React from 'react';

export const QuotationMark = ({ className }: { className?: string }) => {
  return (
    <svg
      width='238'
      height='188'
      viewBox='0 0 238 188'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M152.102 188L168.254 98.7549L138.887 98.7549L138.887 1.24865e-05L238 3.82183e-06L238 100.218L211.57 188L152.102 188ZM14.0786 188L30.2303 98.7549L0.863628 98.7549L0.863619 2.45529e-05L99.9763 1.58882e-05L99.9763 100.218L73.5463 188L14.0786 188Z'
        fill='currentColor'
      />
    </svg>
  );
};
