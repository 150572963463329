import React from 'react';
import { BreakpointShowBox } from '@konsus/superside-kit';

export const AdaptiveImageComponent = (props) => {
  const { ImageComponent, node, options } = props;
  const { image, mobileImage, ...rest } = node;

  if (!image || !image.asset) {
    return null;
  }

  return (
    <>
      <BreakpointShowBox xxlg md={!mobileImage}>
        <ImageComponent node={image} sanityConfig={options} {...rest} />
      </BreakpointShowBox>
      {mobileImage ? (
        <BreakpointShowBox xxlg={false} md>
          <ImageComponent node={mobileImage} sanityConfig={options} {...rest} />
        </BreakpointShowBox>
      ) : null}
    </>
  );
};
