import React from 'react';
import { cn } from '@superside/ui';

export const RichTextSuperscript: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className
}) => (
  <span
    className={cn(
      'tracking-15 text-bor-sm mb-2 block w-full font-semibold uppercase [&_a]:text-inherit [&_a]:no-underline',
      'rteSuperscriptClass',
      className
    )}
  >
    {children}
  </span>
);
