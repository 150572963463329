import atom from '@konsus/atoms';
import { type PersonalizedVideoType } from '../../types/form';

type FindVideoOptions = {
  query: Pick<PersonalizedVideoType, 'persona' | 'service'>;
  videoEntries: PersonalizedVideoType[];
  specificOptions: string[];
};

export function findVideo({
  query,
  videoEntries,
  specificOptions
}: FindVideoOptions): PersonalizedVideoType | null {
  const { persona, service } = query;

  const finalPersona = specificOptions.includes(persona) ? persona : 'General';

  for (const key in videoEntries) {
    const entry = videoEntries[key];

    if (entry.persona === finalPersona && entry.service === service) {
      return {
        ...entry,
        persona
      };
    }
  }

  return null;
}

export const personalizedVideoAtom = atom<PersonalizedVideoType | null>(null);
