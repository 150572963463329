import React from 'react';
// @ts-ignore Could not find a declaration file for module
import BlockContent from '@sanity/block-content-to-react';

export const ListRenderer: React.FC<any> = (props) => {
  const { type } = props;

  // skip default `ol` and `ul` render
  if (type === 'alignCenter' || type === 'alignRight') {
    return props.children;
  }

  if (type === 'bullet') {
    return <ul className='list-disc pl-6'>{props.children}</ul>;
  }

  if (type === 'number') {
    return <ol className='list-decimal pl-6'>{props.children}</ol>;
  }

  // @ts-ignore
  return BlockContent.defaultSerializers.list(props);
};
