import React from 'react';
import { cn } from '@superside/ui';
import { Box, RelativeBox } from '@konsus/superside-kit';
import { NextFutureImage } from '@konsus/image';
import { type SanityAsset } from '../types';
import { hiddenDesktopStyle, hiddenMobileStyle } from './commonStyles';

type SquareVideoBackgroundProps = {
  squareVideoBg: { background: SanityAsset; squareVideoBgEnable?: boolean };
  children: React.ReactNode;
};

export const SquareVideoBackground = ({ children, squareVideoBg }: SquareVideoBackgroundProps) => {
  const { background } = squareVideoBg;

  return (
    <>
      <RelativeBox
        className={cn(
          '1280:h-[534px] 1280:mx-0 1280:my-14 1650:h-[592px] 768:h-[402px] bg-nebula-indigo-light mx-0 my-10 aspect-[1] w-full',
          hiddenMobileStyle
        )}
      >
        {background ? (
          <NextFutureImage
            image={background}
            className={'768:block absolute z-0 hidden h-full w-full object-cover object-center'}
          />
        ) : null}
        <Box
          className={
            '1280:h-[calc(100%_-_32px)] 1280:mx-auto 1280:my-4 1650:h-[calc(100%_-_48px)] 1650:mx-auto 1650:my-6 768:h-[calc(100%_-_30px)] 768:mx-auto 768:my-[15px] [&_iframe,video]:!h-full [&_iframe,video]:!m-0 [&_iframe,video]:!w-full [&_iframe,video]:aspect-[1] absolute inset-0 z-[1] m-auto aspect-[1] h-full max-h-full overflow-hidden rounded-lg'
          }
        >
          {children}
        </Box>
      </RelativeBox>
      <span className={hiddenDesktopStyle}>{children}</span>
    </>
  );
};

export default SquareVideoBackground;
