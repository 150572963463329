export const createMatrix = (array, size) => {
  const matrix = [];

  for (let i = 0; i < array.length; i += size) {
    matrix.push(array.slice(i, i + size));
  }

  return matrix;
};

export const findAllColumns = (matrix) => matrix[0].map((_, col) => matrix.map((row) => row[col]));

export const findAllDiagonals = (matrix) => {
  const diagonals = [];

  const numRows = matrix.length;
  const numCols = matrix[0].length;

  const mainDiagonal = [];

  for (let i = 0; i < Math.min(numRows, numCols); i++) {
    mainDiagonal.push(matrix[i][i]);
  }
  diagonals.push(mainDiagonal);

  const secondaryDiagonal = [];

  for (let i = 0; i < Math.min(numRows, numCols); i++) {
    secondaryDiagonal.push(matrix[i][numCols - 1 - i]);
  }
  diagonals.push(secondaryDiagonal);

  return diagonals;
};

export const checkIfVariantFullySelected = (selected, variant) =>
  variant.every((el) => selected.includes(el));

export const possibleVariant = (arr, num) => {
  const matrix = createMatrix(arr, num);

  return [...matrix, ...findAllColumns(matrix), ...findAllDiagonals(matrix)];
};
