import React, { type FC } from 'react';
import type { SanityVisualAsset } from 'sanity.types';
import { WistiaVideo } from '@konsus/superside-kit';
import { cn } from '@superside/ui';
import { VideoStructuredData } from '../../VideoStructuredData';
import useWistiaAspectRatio from '../utils/useWistiaAspectRatio';

export interface AssetTypeWistiaVideo
  extends Pick<
    SanityVisualAsset,
    | 'wistiaVideoId'
    | 'wistiaVideoData'
    | 'wistiaVideoIdMobile'
    | 'wistiaVideoDataMobile'
    | 'maxWidth'
  > {
  square?: boolean;
  maxWidthClassName?: string;
}

export const Wistia: FC<AssetTypeWistiaVideo> = (props) => {
  const {
    wistiaVideoId,
    wistiaVideoIdMobile,
    wistiaVideoData,
    wistiaVideoDataMobile,
    square = false,
    maxWidthClassName,
    maxWidth
  } = props;

  return (
    <>
      <WistiaWrapper
        wistiaId={wistiaVideoId}
        wistiaData={wistiaVideoData}
        square={square}
        className={cn('<768:hidden', maxWidthClassName)}
        maxWidth={maxWidth}
      />

      <WistiaWrapper
        wistiaId={wistiaVideoIdMobile || wistiaVideoId}
        wistiaData={wistiaVideoDataMobile || wistiaVideoData}
        square={square}
        className='768:hidden'
      />
    </>
  );
};

interface WistiaWrapperProps extends Pick<AssetTypeWistiaVideo, 'square' | 'maxWidth'> {
  wistiaId: AssetTypeWistiaVideo['wistiaVideoId'];
  wistiaData?: AssetTypeWistiaVideo['wistiaVideoData'];
  className: string;
}

const WistiaWrapper: FC<WistiaWrapperProps> = ({
  wistiaId,
  wistiaData,
  square = false,
  className,
  maxWidth
}) => {
  const wistiaAspectRatio = useWistiaAspectRatio(wistiaId, className, maxWidth);

  return wistiaId ? (
    <span {...wistiaAspectRatio}>
      {wistiaData ? <VideoStructuredData {...wistiaData} /> : null}
      <WistiaVideo id={wistiaId} square={square} />
    </span>
  ) : null;
};

export default Wistia;
