import React from 'react';
import { cn } from '@superside/ui';
import { Box } from '@konsus/superside-kit';
import { ProgressiveModalEnabledCta } from '../HeroCtaGroup';
import { type SectionHeaderProps } from '.';

export const wrapperMaxWidthVar = {
  name: '--wrapper-max-width-var-a38d',
  val: 'var(--wrapper-max-width-var-a38d)'
};

export const SectionHeaderWithCta: React.FunctionComponent<
  Pick<SectionHeaderProps, 'ctaButton' | 'className' | 'style' | 'children'>
> = (props) => {
  const { ctaButton, className, children, ...rest } = props;

  return (
    <Box
      gap='8'
      margin={{ bottom: '10' }}
      {...rest}
      className={cn('1024:flex-row 1024:justify-between flex-col items-start', className)}
    >
      <Box
        className={
          '1024:max-w-[914px] 1920:max-w-[var(--wrapper-max-width-var-a38d)] 768:max-w-[720px] flex-1'
        }
      >
        {children}
      </Box>
      {ctaButton && <ProgressiveModalEnabledCta cta={ctaButton} />}
    </Box>
  );
};
