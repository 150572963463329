import React from 'react';
import {
  classForExcludingFromRichEditorRules,
  ExternalContentWrapper,
  isDark
} from '@konsus/superside-kit';
import { Box, cn } from '@superside/ui';
import NextImage, { type SanityImage } from '@konsus/image';
import { VisualAsset, type IVisualAsset } from '@konsus/lego/src/components/VisualAsset';
import { SanityBlockComponent } from '../../SanityBlockComponent';

export interface RichTextStatisticBannerType {
  node: {
    backgroundColor: '#4F4FC8' | '#0F2133' | '#E5EAFF';
    desktopBgImage?: SanityImage;
    content: any[];
    assets: IVisualAsset[];
  };
}

export const RichTextStatisticBanner: React.FC<RichTextStatisticBannerType> = ({ node }) => {
  const { backgroundColor, desktopBgImage, content, assets } = node;

  return (
    <Box
      style={{ background: backgroundColor }}
      className={cn(
        'rte-sub 1280:p-12 768:max-w-full 768:mx-0 768:my-14 768:pb-10 768:pt-8 768:px-6 768:rounded-lg 768:w-full [&.dark_h3,.progressBarTextStyle]:text-light relative m-10 -mx-4 w-[calc(100%+32px)] max-w-none px-4 py-8 pb-10',
        isDark(backgroundColor) && 'dark'
      )}
    >
      {desktopBgImage ? (
        <Box
          className={
            'absolute right-0 top-0 h-full w-full overflow-hidden [&_>_div]:absolute [&_>_div]:right-0 [&_>_div]:top-0 [&_>_div]:h-full [&_>_div]:w-full [&_img]:h-full [&_img]:w-full [&_img]:object-cover'
          }
        >
          <NextImage
            layout='fill'
            objectFit='cover'
            objectPosition='center'
            image={desktopBgImage}
            alt=''
            loading='lazy'
          />
        </Box>
      ) : null}
      <Box
        className={
          '1280:flex-row 1280:items-center 1280:justify-between 1440:gap-[96px] 1650:gap-[72px] z-10 w-full items-stretch gap-8'
        }
      >
        <Box
          className={cn(
            backgroundColor !== '#E5EAFF' && 'dark',
            '1280:max-w-[390px] 1440:max-w-[472px]'
          )}
        >
          <ExternalContentWrapper
            className={cn(
              '[p,&_>_li,ol,ul,h2,h3,h4,h5,h6]:text-inherit [&_p:first-child]:mt-0 [&_p:last-child]:mb-0',
              '768:[&_h3]:!leading-[32px] 768:[&_h3]:!text-[23px] 768:[&_h3]:my-4 768:[&_p]:mt-4 [&_h3]:my-2 [&_h3]:!text-[20px] [&_h3]:font-bold [&_h3]:!leading-[28px] [&_p]:mt-2 [&_.rte-sub-image]:!mb-0 [&_.rte-sub-image]:!mt-0 [&_.rte-sub-image]:max-w-[350px] [&_.rte-sub-image_+_.rteSuperscriptClass]:!mt-6 [&_.rteSuperscriptClass]:!mt-0 [&_.rteSuperscriptClass]:mb-2',
              'dark:text-light text-dark'
            )}
          >
            <SanityBlockComponent blocks={content} />
          </ExternalContentWrapper>
        </Box>

        <Box
          className={
            '1280:pl-9 1280:pr-9 1280:w-full 768:w-[72%] 768:max-w-[72%] 768:mx-auto [&_.chapterIconStyle]:h-18 [&_.chapterIconStyle]:w-18 1280:shrink-[initial] gap-6 [&_.chapterIconStyle]:mr-4 [&_.progressBarTextStyle]:text-sm [&_.progressBarTitle]:text-[40px] [&_.progressBarTitle]:leading-[56px] [&_.progressBarTitle_span]:-ml-[10px]'
          }
        >
          {assets.map((asset) => (
            <VisualAsset
              // @ts-ignore
              key={asset._key}
              {...asset}
              objectFit='contain'
              layout='responsive'
              className={classForExcludingFromRichEditorRules}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
