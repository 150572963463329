import React, { useState, useEffect } from 'react';
import { QuestionFieldContainer } from './QuestionFieldContainer';
import {
  type QuestionItemType,
  type AdditionalProgressiveProps,
  type Answers,
  type Errors
} from './type';

export type ProgressiveFormFieldsProps = {
  position: number;
  answers: Answers;
  onChangeAnswers: (answers: Answers) => void;
  errors: Errors;
  onChangeErrors: (errors: Errors) => void;
  isError: boolean;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onGoNext: () => void;
  questions: QuestionItemType[];
  fieldPosition: 'top' | 'bottom';
  otherProps?: AdditionalProgressiveProps;
};

export const ProgressiveFormFields: React.FC<ProgressiveFormFieldsProps> = ({
  fieldPosition,
  position,
  answers,
  onChangeAnswers,
  errors,
  onChangeErrors,
  isError,
  onKeyPress,
  onGoNext,
  questions,
  otherProps
}) => {
  const [goNextAfterAnswersUpdate, setGoNextAfterAnswersUpdate] = useState(false);

  const handleGoNext = () => {
    setGoNextAfterAnswersUpdate(true);
  };

  useEffect(() => {
    if (goNextAfterAnswersUpdate) {
      setGoNextAfterAnswersUpdate(false);
      onGoNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  return (
    <>
      {questions.map((question, index) => (
        <QuestionFieldContainer
          key={question.name}
          errors={errors}
          isError={isError}
          isVisible={position === index}
          onGoNext={handleGoNext}
          onKeyPress={onKeyPress}
          question={question}
          onChangeAnswers={onChangeAnswers}
          onChangeErrors={onChangeErrors}
          otherProps={otherProps}
          fieldPosition={fieldPosition}
        />
      ))}
    </>
  );
};
