import React from 'react';
import { PeopleCardsContent } from '@konsus/lego/src/blocks/PeopleCardsSection';
import { type PeopleCardsSectionProps } from '@konsus/lego/src/blocks/PeopleCardsSection/types';
import { Box } from '@superside/ui';

interface RichTextPeopleCardsProps {
  node: PeopleCardsSectionProps;
}

export const RichTextPeopleCards: React.FC<RichTextPeopleCardsProps> = ({ node }) => (
  <Box className='rte-sub 1280:mt-7 mt-4 inline-block'>
    <PeopleCardsContent {...node} />
  </Box>
);
