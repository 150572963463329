import React, { useState } from 'react';
import { CaretDown } from 'phosphor-react';
import { type QuoteRichText } from '@konsus/lego/src/blocks/Quotes/types';
import { Button, cn } from '@superside/ui';
import { SanityBlockComponent } from '../../SanityBlockComponent';

export interface RichTextCollapsibleProps {
  node: {
    icon?: { svg: string };
    title: string;
    content: QuoteRichText[];
  };
}

export const RichTextCollapsible: React.FC<RichTextCollapsibleProps> = ({ node }) => {
  const { icon, title, content } = node;

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => setCollapsed((old) => !old);

  return (
    <div className='flex flex-col gap-6 text-left'>
      <Button
        variant='ghost'
        onClick={toggleCollapsed}
        className='p-0'
        icon={
          <CaretDown className={cn('transform transition-transform', !collapsed && 'rotate-180')} />
        }
      >
        <div className='flex items-center gap-2'>
          {icon?.svg && <div dangerouslySetInnerHTML={{ __html: icon.svg }} />}
          {title}
        </div>
      </Button>
      {!collapsed && (
        <div className='animate-slide-down-appearing'>
          <SanityBlockComponent blocks={content} />
        </div>
      )}
    </div>
  );
};
