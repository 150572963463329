import React from 'react';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

const BASE_LINKEDIN_URL = `https://www.linkedin.com/embed/feed/update/urn:li:share:`;

interface LinkedInComponentProps {
  id: string;
  width: number;
  height: number;
}

interface LinkedInComponentContentProps extends LinkedInComponentProps {
  node?: LinkedInComponentProps;
}

export const LinkedInComponentContent: React.FC<LinkedInComponentContentProps> = (props) => {
  const id = props?.node?.id || props?.id;
  const width = props?.node?.width || 100;
  const height = props?.node?.height || 200;

  return id ? (
    <iframe
      src={`${BASE_LINKEDIN_URL}${id}`}
      style={{ width: `${width}%`, height, borderRadius: 8, border: 0 }}
      className='rte-mobile-full-width'
    />
  ) : null;
};

export const LinkedInComponent = createLazyLoadableComponent(LinkedInComponentContent);
