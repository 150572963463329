import React, { useEffect, useRef, useState } from 'react';
import { cn, TextInput, PhoneInput } from '@superside/ui';

export type ProgressiveInputType = {
  placeholder: string;
  onChange: (value: string) => void;
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  autofocus?: boolean;
};

export const ProgressiveInput: React.FC<ProgressiveInputType> = ({
  placeholder,
  onChange,
  onKeyPress,
  className,
  name,
  autofocus
}) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus({ preventScroll: true });
    }
  }, [autofocus]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className='relative'>
      <label
        htmlFor={name}
        className={cn(
          'text-light cursor-text text-lg font-semibold',
          inputValue ? 'opacity-0' : 'opacity-60',
          className
        )}
      >
        {placeholder}
      </label>
      <TextInput
        id={name}
        name={name}
        onChange={handleInputChange}
        value={inputValue}
        className={cn('text-light absolute bottom-0 border-none bg-transparent p-0', className)}
        onKeyPress={onKeyPress}
        ref={inputRef}
      />
    </div>
  );
};

export type ProgressivePhoneInputType = Omit<ProgressiveInputType, 'autofocus'>;

export const ProgressivePhoneInput: React.FC<ProgressivePhoneInputType> = ({
  placeholder,
  onChange,
  onKeyPress,
  className,
  name
}) => {
  const [inputValue, setInputValue] = useState('');

  const handlePhoneInputChange = (value: string) => {
    setInputValue(value);
    onChange(value);
  };

  return (
    <div className='relative'>
      <PhoneInput
        value={inputValue}
        onChange={handlePhoneInputChange}
        placeholder={placeholder}
        className='relative bottom-0 border-none p-0'
        inputClass={cn('text-light border-0 bg-transparent py-0', className)}
        onEnterKeyPress={onKeyPress}
        inputProps={{ name }}
      />
    </div>
  );
};
