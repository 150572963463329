import React, { useMemo, type FC } from 'react';
import type { SanityAssetsGrid } from 'sanity.types';
import { cn } from '@superside/ui';
import { Box, classForExcludingFromRichEditorRules, RelativeBox } from '@konsus/superside-kit';
import { VisualAsset } from '../../components/VisualAsset';
import { SectionHeader } from '../../components/SectionHeader';

export const AssetsGridContent: FC<SanityAssetsGrid> = ({
  header,
  assets,
  boxShadow,
  isGap,
  threeCols
}) => {
  const progressBarTypesOnly = useMemo(() => {
    return assets?.every((asset) => asset?.visualAsset?.mediaType === 'progressBar');
  }, [assets]);

  if (!assets?.length) {
    return null;
  }

  return (
    <>
      {/* @ts-ignore match with sanity.types */}
      {header ? <SectionHeader {...header} /> : null}
      <Box
        className={cn(
          '768:grid 768:rounded-lg rounded-lg',
          '768:grid-cols-[repeat(var(--cols-count-0aed),1fr)]',
          progressBarTypesOnly &&
            '1280:grid-cols-[repeat(var(--cols-count-0aed),1fr)] 768:grid-cols-[1fr]',
          boxShadow && !isGap && 'bg-[#d9d9d9] shadow-[0_8px_52px_#0000001f]',
          isGap && 'gap-8'
        )}
        style={
          {
            overflow: isGap && boxShadow ? 'visible' : 'hidden',
            '--cols-count-0aed': threeCols ? 3 : 2
          } as React.CSSProperties
        }
      >
        {assets.map((asset, idx) => (
          <Box
            key={idx}
            className={
              assets.length === 1
                ? cn('col-[span_2]', isGap && 'overflow-hidden rounded-lg')
                : cn(
                    isGap && 'overflow-hidden rounded-lg',
                    boxShadow && isGap && 'bg-[#d9d9d9] shadow-[0_8px_52px_#0000001f]',
                    asset.tileType === 'longHorizontal' && 'col-[span_3]',
                    asset.tileType === 'horizontal' && 'col-[span_2]',
                    asset.tileType === 'vertical' && 'row-[span_2] [&_span]:!h-full',
                    asset.tileType === 'square' && ''
                  )
            }
          >
            <RelativeBox
              flexBox={false}
              className={'flex h-full min-h-full justify-center [&_span]:!h-full'}
            >
              <VisualAsset
                {...asset.visualAsset}
                objectFit='contain'
                className={classForExcludingFromRichEditorRules}
              />
            </RelativeBox>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default AssetsGridContent;
