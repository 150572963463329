import React from 'react';
import { type SanityImage } from '@konsus/image';
import { QuoteVariant1 } from './QuoteVariant1';
import { QuoteVariant2 } from './QuoteVariant2';

interface QuoteAuthorProps {
  name: string;
  position: string;
  image?: SanityImage;
}

export interface QuoteProps {
  author: QuoteAuthorProps;
  text: any;
}

interface RichTextQuoteProps {
  node: {
    author: QuoteAuthorProps;
    text: any;
    variant: 'variant1' | 'variant2';
  };
}

export const RichTextQuote = (props: RichTextQuoteProps) => {
  const { author, text, variant = 'variant1' } = props.node;

  if (variant === 'variant2') {
    return <QuoteVariant2 author={author} text={text} />;
  }

  return <QuoteVariant1 author={author} text={text} />;
};
