import React from 'react';
import { Box, cn } from '@superside/ui';
import { QuestionField } from './QuestionField';
import { type ProgressiveFormFieldsProps } from './ProgressiveFormFields';
import { type QuestionItemType } from './type';

export type QuestionFieldContainerProps = Pick<
  ProgressiveFormFieldsProps,
  | 'onChangeAnswers'
  | 'errors'
  | 'onChangeErrors'
  | 'isError'
  | 'fieldPosition'
  | 'onKeyPress'
  | 'otherProps'
> & {
  question: QuestionItemType;
  isVisible: boolean;
  onGoNext: () => void;
};

export const QuestionFieldContainer: React.FC<QuestionFieldContainerProps> = (props) => {
  const {
    onChangeAnswers,
    errors,
    isError,
    fieldPosition = 'top',
    onKeyPress,
    otherProps,
    question,
    isVisible,
    onGoNext
  } = props;

  const { name, label } = question;

  const handleChange = (value: string | string[] | undefined = '') => {
    if (Array.isArray(value)) {
      value = value.join(';');
    }

    onChangeAnswers({ [name]: value as string });
  };

  const handleChangeAndGoNext = (value: string | string[] | undefined = '') => {
    handleChange(value);
    if (value) {
      onGoNext();
    }
  };

  return (
    <Box
      className={cn(
        isVisible
          ? 'animate-slide-right-fade-in relative block w-full items-start gap-2 opacity-100'
          : 'invisible hidden transition-opacity duration-200 ease-in-out'
      )}
    >
      {fieldPosition === 'top' && (
        <div className='flex w-full flex-col items-start gap-1'>
          {label && otherProps?.showLabel && (
            <span className='text-light absolute -top-11 text-sm font-semibold'>{label}</span>
          )}
          {isError && errors[name] && (
            <span
              data-testid='field-validation'
              className='text-coral-500 absolute -top-6 text-sm font-semibold'
            >
              {errors[name]}
            </span>
          )}
        </div>
      )}
      <QuestionField
        {...question}
        onChange={handleChange}
        onChangeAndGoNext={handleChangeAndGoNext}
        inputClassName={otherProps?.inputClassName}
        onKeyPress={onKeyPress}
        fieldPosition={fieldPosition}
        isVisible={isVisible}
      />
    </Box>
  );
};
