import React, { type FC } from 'react';
import type { SanityAssetsGrid } from 'sanity.types';
import { isDark, cn } from '@superside/ui';
import { AssetsGridContent } from '@konsus/lego/src/blocks/AssetsGrid/AssetsGridContent';

export const RichTextAssetsGrid: FC<{
  node: SanityAssetsGrid & { backgroundColor: string };
}> = ({ node }) => (
  <div
    className={cn('flex', 'flex-col', 'rte-sub', isDark(node.backgroundColor) ? 'dark' : 'light')}
  >
    <AssetsGridContent richText {...node} />
  </div>
);
