import styles from './styles.module.css';

export const mobileBgImageStyle = styles.mobileBgImageStyle;
export const desktopBgImageStyle = styles.desktopBgImageStyle;
export const image = styles.image;
export const container = styles.container;
export const buttonBox = styles.buttonBox;
export const mobileThankYouImage = styles.mobileThankYouImage;
export const headingStyle = styles.headingStyle;
export const descriptionStyle = styles.descriptionStyle;
export const bannerBox = styles.bannerBox;
export const thankYouMessageContent = styles.thankYouMessageContent;
export const content = styles.content;
export const buttonCtaContainyStyle = styles.buttonCtaContainyStyle;
export const compactBannerBox = styles.compactBannerBox;
export const compactContainer = styles.compactContainer;
export const submittedContainerStyle = styles.submittedContainerStyle;
export const halfDesktopBgImageStyle = styles.halfDesktopBgImageStyle;
export const centeredContainer = styles.centeredContainer;
export const centeredContent = styles.centeredContent;
export const emailCapturingBox = styles.emailCapturingBox;
export const bannerBoxShadow = styles.bannerBoxShadow;
export const smallBannerBoxShadow = styles.smallBannerBoxShadow;
export const centeredBox = styles.centeredBox;
export const mobileOverflowImageContainerStyle = styles.mobileOverflowImageContainerStyle;
export const gradientOverlayStyles = styles.gradientOverlayStyles;
export const mobileOverflowImageImageStyle = styles.mobileOverflowImageImageStyle;
export const desktopOverflowImageStyle = styles.desktopOverflowImageStyle;
export const desktopOverflowImageContainerStyle = styles.desktopOverflowImageContainerStyle;
export const subHeaderTextBoxStyle = styles.subHeaderTextBoxStyle;
