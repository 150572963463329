/* eslint-disable @next/next/no-img-element */
import React from 'react';
import NextImage from 'adapter/next/image';
import { cn } from '@superside/ui';
import { type PeopleSignupProps } from '../types';

export const PeopleSignup: React.FC<PeopleSignupProps> = ({
  otherPeopleNumber,
  avatarStackIndexes,
  animateAvatarStack,
  className
}) => (
  <div className={cn('mt-4', className)}>
    {otherPeopleNumber ? (
      <span>
        You and <b>{otherPeopleNumber} others</b> are saving their spots!
      </span>
    ) : null}

    {avatarStackIndexes ? (
      <div className='mt-2 flex'>
        {avatarStackIndexes.map((avatar, idx) => (
          <NextImage
            key={avatar}
            src={`/images/avatars/${avatar}.png`}
            alt={`Avatar_${avatar}`}
            width={40}
            height={40}
            className={cn(
              'animate-fade-in translate-y-1 opacity-0 [&:not(:first-child)]:-ml-2',
              Boolean(idx === 0 && animateAvatarStack) &&
                '-ml-30 mr-22 transition-all duration-500 ease-in-out'
            )}
          />
        ))}
      </div>
    ) : null}
  </div>
);

export default PeopleSignup;
