import React from 'react';
import { Heading, Box, cn } from '@superside/ui';
import { type SanityAsset } from '@konsus/image/src/types';
import { classForExcludingFromRichEditorRules } from '@konsus/superside-kit';
import NextImage from '@konsus/image';
import VisualAsset, { type VisualAssetProps } from '@konsus/lego/src/components/VisualAsset';
import Superscript from '@konsus/lego/src/components/Superscript';
import { getAuthorsNames } from './helpers';

type SectionTitleType = {
  heading: string;
  superscriptText: string;
  chapterCover: VisualAssetProps;
  chapterIcon: SanityAsset;
  authors?: string[];
  anchorId?: string;
};

interface SectionTitleTypeSection extends SectionTitleType {
  node?: SectionTitleType;
}

const wrapNamesWithSpan = (names: Array<string>): Array<string> => {
  return names.map((item) => `<span class='font-semibold'>${item}</span>`);
};

export const SectionTitle: React.FC<SectionTitleTypeSection> = (props) => {
  const { heading, superscriptText, chapterCover, chapterIcon, anchorId, authors } =
    props.node || props;

  const isRichTextSectionTitle = !!props.node;

  return (
    <Box id={anchorId ? anchorId.trim() : ''} className={cn(isRichTextSectionTitle && '-mb-4')}>
      <Box className='mb-8 flex-row'>
        {chapterIcon ? (
          <Box className={'1024:block mr-[40px] hidden h-[96px] w-[96px]'}>
            <NextImage image={chapterIcon} alt={superscriptText} layout='responsive' />
          </Box>
        ) : null}
        <Box className='shrink grow justify-center'>
          {superscriptText ? <Superscript content={superscriptText} /> : null}
          {heading ? (
            <Heading
              type='h2'
              className={cn(
                classForExcludingFromRichEditorRules,
                '1280:text-[48px] 768:text-[30px] text-[26px] leading-[1.4]'
              )}
            >
              {heading}
            </Heading>
          ) : null}
          {authors ? (
            <div className='mt-2 text-lg'>
              Chapter by{' '}
              <span
                dangerouslySetInnerHTML={{
                  __html: getAuthorsNames(wrapNamesWithSpan(authors as Array<string>))
                }}
              />
            </div>
          ) : null}
        </Box>
      </Box>
      {chapterCover &&
      (chapterCover.image ||
        chapterCover.inlineVideo ||
        chapterCover.youtubeVideoId ||
        chapterCover.wistiaVideoId ||
        chapterCover.lottie) ? (
        <Box
          className={
            '<=768:!rounded-none <=768:[&_*]:!rounded-none <=768:[&_*]:aspect-h-9 <=768:[&_*]:aspect-w-16 <=768:[&_*]:object-cover <=768:aspect-[16/9] <=768:max-w-none <=768:ml-[-16px] <=768:w-[calc(100%+32px)] 768:[&_*]:rounded-md <=768:[&_*]:!h-full mb-14 overflow-hidden [&_*]:object-cover [&_div]:h-full'
          }
        >
          <VisualAsset {...chapterCover} />
        </Box>
      ) : null}
    </Box>
  );
};
