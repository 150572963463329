import React from 'react';
import { Box, cn } from '@superside/ui';

export type ProgressiveBarType = {
  width: number;
  isError?: boolean;
};

export const ProgressiveBar: React.FC<ProgressiveBarType> = ({ width = 0, isError }) => (
  <Box
    data-testid='progressive-bar-container-testid'
    className='bg-light relative h-[1.5px] w-full rounded'
  >
    <Box
      style={{ width: `${width}%` }}
      className={cn(
        'absolute -left-px top-0 h-1 w-full translate-y-[-40%] rounded transition-all duration-[0.5s] ease-[ease-out]',
        isError ? 'bg-bor-error-500' : 'bg-bor-secondary-400 superads:bg-bor-primary-500'
      )}
      data-testid='progressive-inner-bar-testid'
    ></Box>
  </Box>
);
