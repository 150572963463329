import { useCallback } from 'react';
import { useRouter } from 'adapter/next/router';

const isSSR = typeof window === 'undefined';

export function useRedirect() {
  const history = useRouter();

  return useCallback(
    (redirectLink, newWindow) => {
      if (!isSSR) {
        if (newWindow) {
          window.open(redirectLink, '_blank');
        } else {
          if (redirectLink.includes('https://www.superside.com')) {
            // url contains superside.com
            const path = redirectLink.split('https://www.superside.com');

            if (path.length > 1) {
              history.push(path[1]);
            } else {
              location.assign(redirectLink);
            }
          } else if (!redirectLink.includes('http')) {
            // not absolute path, but relative path, just redirect
            history.push(redirectLink);
          } else {
            location.assign(redirectLink);
          }
        }
      }
    },
    [history]
  );
}
