import { useEffect, useReducer, useState } from 'react';
import { actions, createInitialState, reducer } from './reducer';
import { getCacheKey } from './getCacheKey';
import { isSSR } from './const';
import { useSanityClient } from './useSanityClient';
import { type SanityClient, type UseSanityOptions } from './typing';

const EMPTY_CACHE_HIT = { result: undefined, error: undefined, promise: undefined };

async function request<QueryParams, ReturnValue>(
  query: string,
  params: QueryParams,
  fetch: SanityClient['fetch'],
  dispatch
): Promise<ReturnValue | null> {
  try {
    dispatch({ type: actions.SANITY_REQUEST });
    const response = await fetch<QueryParams, ReturnValue>(query, params);

    dispatch({ type: actions.SANITY_SUCCESS, payload: response });

    return response;
  } catch (err) {
    dispatch({ type: actions.SANITY_FAILURE, payload: err });

    return null;
  }
}

export function useSanity<QueryParams, ReturnValue>(
  query: string,
  params?: QueryParams,
  options: UseSanityOptions = { manual: false, sanityClient: undefined }
) {
  if (isSSR) {
    return serverSideHook<QueryParams, ReturnValue>(query, params, options);
  }

  return clientHook<QueryParams, ReturnValue>(query, params, options);
}

const noop = () => {};

const prepareHook = <QueryParams>(
  query: string,
  params?: QueryParams,
  options: UseSanityOptions = { manual: false, sanityClient: undefined }
) => {
  const { fetch, cache } = useSanityClient(options);
  const cacheKey = getCacheKey(query, params);
  const cacheHit = cache.get(cacheKey);
  const { result, error } = cacheHit || EMPTY_CACHE_HIT;
  const initialState = createInitialState(result, options);

  return {
    cacheKey,
    error,
    fetch,
    initialState,
    result
  };
};

const serverSideHook = <QueryParams, ReturnValue>(
  query: string,
  params?: QueryParams,
  options: UseSanityOptions = { manual: false, sanityClient: undefined }
) => {
  const { error, result, fetch, initialState } = prepareHook(query, params, options);
  let action;

  if (typeof result === 'undefined' && typeof error === 'undefined') {
    fetch<QueryParams | undefined, ReturnValue>(query, params);
    action = { type: actions.SANITY_REQUEST };
  } else if (error !== null) {
    action = { type: actions.SANITY_FAILURE, payload: error };
  } else {
    action = { type: actions.SANITY_SUCCESS, payload: result };
  }

  return [reducer(initialState, action), noop];
};

const clientHook = <QueryParams, ReturnValue>(
  query: string,
  params?: QueryParams,
  options: UseSanityOptions = { manual: false, sanityClient: undefined }
) => {
  const { cacheKey, fetch, initialState } = prepareHook(query, params, options);
  const [state, dispatch] = useReducer(reducer, initialState);
  const hasData = state.isLoading === false && state.data;

  const [activeCacheKey, setActiveCacheKey] = useState(cacheKey);

  useEffect(() => {
    const isInitialAutomaticLoad = options.manual !== true && !hasData;
    const cacheKeyHasChanged = activeCacheKey !== cacheKey;

    if (isInitialAutomaticLoad || cacheKeyHasChanged) {
      request<QueryParams | undefined, ReturnValue>(query, params, fetch, dispatch);
      setActiveCacheKey(cacheKey);
    }
  }, [cacheKey]);

  return [
    state,
    (query: string, params: QueryParams) => {
      return request<QueryParams | undefined, ReturnValue>(query, params, fetch, dispatch);
    }
  ];
};
