import type React from 'react';

export const itemsCountToken = { name: '--items-count', val: 'var(--items-count)' };

export const DEFAULT_SLIDE_HORIZONTAL_MARGIN = 4;

export interface CarouselItemProps<T> {
  index: number;
  item: T;
  setActive: (index: number) => void;
  scrollContainer?: React.MutableRefObject<HTMLElement>;
}

export interface ResponsiveCarouselProps<T> {
  items: T[];
  SlideComponent: React.FC<CarouselItemProps<T>>;
  beforeItems?: React.ReactElement;
  className?: string;
  cardListClassName?: string;
  cardClassName?: string;
}
