import React from 'react';
import { cn } from '@superside/ui';
import { createLazyLoadableComponent } from '../../utlis/createLazyLoadableComponent';

interface IframeComponentProps {
  node: {
    url: string;
    width?: number;
    height?: number;
  };
}

const IframeComponentContent: React.FC<IframeComponentProps> = (props) => {
  const isYoutubeIframe = props.node.url?.includes('youtube.com');

  return (
    <div className={cn('rte-sub', isYoutubeIframe && 'rte-youtube-iframe')}>
      <iframe
        className={cn('max-w-full border-none', isYoutubeIframe && 'aspect-video h-auto w-full')}
        src={props.node.url}
        width={`${props.node.width || 640}px`}
        height={`${props.node.height || 320}px`}
      />
    </div>
  );
};

export const IframeComponent = createLazyLoadableComponent(IframeComponentContent);
