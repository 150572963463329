import React from 'react';
import Box from '../../../components/Box';
import styles from './Globe.module.css';
import svg from './globe.svg?source';

interface IconProps {
  color?: string;
  size?: string;
  stroke?: string;
  fill?: string;
}

export const Globe = (props: IconProps) => {
  const { color = 'none', size = '20px', stroke = 'white', fill = 'initial' } = props;

  return <Icon svg={svg} color={color} size={size} stroke={stroke} fill={fill} />;
};

export const Icon = ({ svg, ...rest }: any) => (
  <Box
    className={styles.icon}
    style={
      {
        '--svg-size': rest.size ?? 'inherit',
        '--svg-color': rest.color ?? 'inherit',
        '--svg-fill': rest.fill ?? 'inherit',
        '--svg-stroke': rest.stroke ?? 'inherit'
      } as React.CSSProperties
    }
    dangerouslySetInnerHTML={{ __html: svg }}
    {...rest}
  />
);
