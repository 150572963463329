import { useRef } from 'react';
import { defaultClient } from './defaultClient';
import { createAsyncClient } from './createAsyncClient';
import { type SanityClient, type UseSanityOptions } from './typing';

export function useSanityClient(options?: UseSanityOptions): SanityClient {
  const optionsClient = useRef<SanityClient | null>(null);

  if (options && options.sanityClient) {
    if (optionsClient.current === null) {
      optionsClient.current = createAsyncClient(options.sanityClient);
    }

    return optionsClient.current;
  }

  return defaultClient;
}
