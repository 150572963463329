import React from 'react';
import { VisualAsset } from '@konsus/lego/src/components/VisualAsset';
import type { SlideType } from '@konsus/lego/src/components/VisualAsset/types';

export interface RichTextVisualAssetProps {
  showPreviewModal?: boolean;
  node: any;
  slides?: SlideType[];
  previewModalTitle?: string;
}

export const RichTextVisualAsset: React.FC<RichTextVisualAssetProps> = ({
  showPreviewModal,
  slides,
  previewModalTitle,
  node
}) => {
  const activeIndex = slides?.findIndex((item) => item._key === node._key);

  return (
    <div className='1024:my-14 my-10 flex self-center last:mb-0 [&_.cursor-zoom-in]:self-center'>
      <VisualAsset
        showPreviewModal={showPreviewModal}
        slides={slides}
        activeIndex={activeIndex}
        previewModalTitle={previewModalTitle}
        {...node}
        isRte
      />
    </div>
  );
};
