import React, { Suspense, useState } from 'react';
import Animation from '../../Animation';

const SplineWrapper = React.lazy(() => import('@splinetool/react-spline'));

export const Spline: React.FC<{ splineId?: string; splineHeight?: number }> = ({
  splineId,
  splineHeight: height = 600
}) => {
  const [loaded, setLoaded] = useState(false);

  return splineId ? (
    <div className='relative w-full overflow-hidden rounded-lg' style={{ height }}>
      {!loaded ? (
        <div className='absolute inset-0 m-auto h-32 w-32'>
          <Animation animationUrl={`/loadingState.json`} aspectRatio={1} />
        </div>
      ) : null}

      <div className='absolute inset-0'>
        <Suspense>
          <SplineWrapper
            onLoad={() => setLoaded(true)}
            className='h-full'
            scene={`https://prod.spline.design/${splineId}/scene.splinecode`}
          />
        </Suspense>
      </div>
    </div>
  ) : null;
};

export default Spline;
