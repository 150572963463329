import React from 'react';
import { Heading } from '@superside/ui';
import { RichTextSuperscript } from '../../RichTextSuperscript';
import { type TimeUnitProps } from '../types';

export const TimeUnit: React.FC<TimeUnitProps> = ({ title, subtitle }) => (
  <div className='<=768:[&_span]:text-bor-xs flex flex-col items-center justify-center'>
    <Heading type='h2' className='1280:leading-[1.4] !font-bold'>
      {title}
    </Heading>
    <RichTextSuperscript>{subtitle}</RichTextSuperscript>
  </div>
);

export default TimeUnit;
