import React from 'react';
// @ts-ignore Could not find a declaration file for module
import BlockContent from '@sanity/block-content-to-react';
import { TextBlockRenderer, type TextBlockRendererProps } from './TextBlockRenderer';

export type ListItemRendererProps = {
  node: { listItem: string } & TextBlockRendererProps['node'];
};

export const ListItemRenderer: React.FC<ListItemRendererProps> = (props) => {
  const {
    node: { listItem }
  } = props;

  const textAlign =
    listItem === 'alignCenter' ? 'center' : listItem === 'alignRight' ? 'right' : '';

  if (textAlign) {
    return (
      <div style={{ textAlign }}>
        <TextBlockRenderer {...props} />
      </div>
    );
  }

  // @ts-ignore
  return BlockContent.defaultSerializers.listItem(props);
};
