import React, { useCallback, useState } from 'react';
import { Button, Text, Box } from '@konsus/superside-kit';
import { cn } from '@superside/ui';
import { LinkIcon } from './LinkIcon';

export const CopyLinkButton: React.FC<{
  url: string;
  background?: string;
  color?: string;
  pad?: string;
  Icon?: any;
}> = (props) => {
  const { url, background, color, Icon = LinkIcon } = props;
  const [isCopied, setIsCopied] = useState(false);

  const clickHandler = useCallback(() => {
    window.navigator.clipboard.writeText(url);
    setIsCopied(true);
  }, [url]);

  return (
    <>
      <Button
        plain
        onClick={clickHandler}
        onMouseLeave={() => setIsCopied(false)}
        title='Copy link to clipboard'
      >
        <Box className='rounded-full p-2' responsive={false} background={background}>
          <Icon color={color} />
        </Box>
      </Button>
      <Box
        pad='2'
        className={cn(
          'bg-light absolute left-2/4 bottom-full flex w-[180px] max-w-[initial] -translate-x-2/4 rounded text-[black] opacity-0',
          'before:bg-light before:absolute before:bottom-[-7px] before:left-2/4 before:-ml-2 before:block before:h-4 before:w-4 before:rotate-45 before:rounded-sm',
          isCopied &&
            'bottom-[calc(100%+16px)] z-10 text-center opacity-100 transition-all duration-300 ease-out'
        )}
      >
        <Text semibold size='xsmall'>
          Link copied to clipboard
        </Text>
      </Box>
    </>
  );
};
