import { type MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

interface UsePaginationTrackerOptions {
  slideHorizontalMargin?: number;
  initialIndex?: number;
  setActiveIndexCallback?: (nextIndex: number) => void;
  scrollBehavior?: 'auto' | 'smooth';
  selector?: string;
}

export type ActiveIndexSetter = (nextIndex: number, shouldScroll?: boolean) => void;

export function usePaginationTracker(
  options: UsePaginationTrackerOptions
): [number, ActiveIndexSetter, MutableRefObject<HTMLDivElement | null>] {
  const {
    slideHorizontalMargin = 0,
    initialIndex = 0,
    setActiveIndexCallback,
    scrollBehavior = 'auto',
    selector = 'cardStyles'
  } = options;
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const updateActiveIndex = useCallback(
    (nextIndex: number, shouldScroll = false) => {
      setActiveIndex((currentIndex) => {
        const finalShouldScroll = shouldScroll && nextIndex !== currentIndex;

        if (finalShouldScroll) {
          setTimeout(() => {
            const firstCard = scrollContainerRef.current?.querySelector(`.${selector}`);

            if (!firstCard) {
              return;
            }

            scrollContainerRef.current?.scroll({
              left: firstCard?.clientWidth * nextIndex,
              behavior: scrollBehavior
            });
          }, 0);
        }

        return nextIndex;
      });
      setActiveIndexCallback?.(nextIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setActiveIndexCallback]
  );

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const scrollHandler = throttle(() => {
      if (!scrollContainer) {
        return;
      }

      const firstCard = scrollContainer?.querySelector(`.${selector}`);
      const slideWidth = (firstCard?.clientWidth || 0) + slideHorizontalMargin * 2;
      const scrollPosition = scrollContainer?.scrollLeft - slideHorizontalMargin;

      updateActiveIndex(Math.round(scrollPosition / slideWidth));
    }, 250);

    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', scrollHandler);
    }

    return () => {
      scrollContainer?.removeEventListener('scroll', scrollHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideHorizontalMargin, updateActiveIndex]);

  return [activeIndex, updateActiveIndex, scrollContainerRef];
}
