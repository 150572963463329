import React from 'react';
import { cn } from '@superside/ui';
import { classForExcludingFromRichEditorRules } from '@konsus/superside-kit';
import NextImage from '@konsus/image';
import { PageSection, VisualAsset, SectionHeader } from '../../components';
import { type VideoWithQuotePropsType } from './types';
import MultipleQuotes from './MultipleQuotes';

export const VideoWithQuoteContents: React.FC<VideoWithQuotePropsType> = ({
  header,
  button,
  video,
  floatingImage,
  showImage,
  quotes,
  intervalTime,
  rte
}) => {
  return (
    <>
      {header && !rte && (
        <SectionHeader {...header} ctaButton={button} flex={true} data-testid='sectionHeader' />
      )}
      <div className={cn('<1280:grid-cols-[1fr] relative grid grid-cols-[1fr_1fr] gap-6')}>
        <div className='flex h-fit overflow-hidden rounded-lg will-change-transform'>
          {video?.mediaType ? <VisualAsset {...video} /> : null}
        </div>

        {quotes?.length > 0 ? (
          <MultipleQuotes quotes={quotes} intervalTime={intervalTime} rte={rte} />
        ) : null}
      </div>
      {showImage && floatingImage && !rte && quotes?.[0] ? (
        <div
          className='<1280:hidden absolute -right-[50px] bottom-[40px] z-[2] block w-[259px] overflow-hidden blur-[10px]'
          data-testid='floatingImage'
        >
          <NextImage
            image={floatingImage}
            alt={header?.superscriptText || ''}
            layout='responsive'
            loading={'lazy'}
            className={classForExcludingFromRichEditorRules}
          />
        </div>
      ) : null}
    </>
  );
};

export const VideoWithQuote: React.FC<VideoWithQuotePropsType> = ({ rte, ...props }) => {
  const contents = <VideoWithQuoteContents {...props} rte={rte} />;
  const className = 'relative overflow-[unset] <1440:overflow-hidden';

  return rte ? (
    <div className={className} data-testid='rteWrapper'>
      {contents}
    </div>
  ) : (
    <PageSection className={className}>{contents}</PageSection>
  );
};

export default VideoWithQuote;
