import React from 'react';

export const GreenColorText = (props) => {
  return (
    <span
      className={'[.light_&]:font-inherit font-semibold text-green-500 [.light_&]:text-inherit'}
    >
      {props.children}
    </span>
  );
};
