import React from 'react';
import { cn, Heading } from '@superside/ui';
import NextImage from '@konsus/image';
import {
  Box,
  ExternalContentWrapper,
  RelativeBox,
  classForExcludingFromRichEditorRules,
  contentWrapperStyle
} from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { bannerBox, content, headingStyle } from './styles';
import { type BannerProps } from './types';

export const BannerSuccessContent: React.FC<Pick<BannerProps, 'successContent'>> = ({
  successContent
}) => {
  return (
    <RelativeBox
      className={cn(
        bannerBox,
        '<1024:flex-col <1024:gap-y-4 <1024:p-6 1024:p-12 text-dark flex-row items-center overflow-visible rounded-lg bg-blue-100'
      )}
    >
      {successContent?.mobileSuccessImage ? (
        <Box className={'1024:hidden [span]:overflow-visible relative -mt-10'}>
          <NextImage
            image={successContent?.mobileSuccessImage}
            alt={successContent?.headerText}
            loading={'lazy'}
            className={cn(classForExcludingFromRichEditorRules, 'object-cover')}
          />
          <div
            className={
              'absolute bottom-0 left-0 h-5 w-full bg-[linear-gradient(360deg,#E5EAFF_0%,rgba(229,234,255,0)_100%)]'
            }
          />
        </Box>
      ) : null}
      <Box className={cn(content, 'flex-1')}>
        <Heading className={cn(classForExcludingFromRichEditorRules, headingStyle)} level='h2'>
          {successContent?.headerText && (
            <Box dangerouslySetInnerHTML={{ __html: successContent.headerText }} />
          )}
        </Heading>
        {successContent?.subHeaderRichText ? (
          <Box maxWidth={'100%'}>
            <ExternalContentWrapper
              className={cn(contentWrapperStyle, '[&>p]:!text-dark mb-4')}
              margin={{ top: '3' }}
            >
              <SanityBlockComponent blocks={successContent?.subHeaderRichText} />
            </ExternalContentWrapper>
            {successContent?.bodyText ? (
              <ExternalContentWrapper
                className={cn(contentWrapperStyle, '[&>p]:!text-dark mb-4')}
                margin={{ top: '3' }}
              >
                <SanityBlockComponent blocks={successContent.bodyText} />
              </ExternalContentWrapper>
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Box className='<768:hidden w-80'></Box>
      {successContent?.desktopSuccessImage ? (
        <Box className='[span]:overflow-visible <1024:hidden absolute right-0 w-80 overflow-visible'>
          <NextImage
            image={successContent?.desktopSuccessImage}
            alt={successContent?.headerText}
            loading={'lazy'}
            className={cn(classForExcludingFromRichEditorRules, 'overflow-visible object-cover')}
          />
        </Box>
      ) : null}
    </RelativeBox>
  );
};
