import React from 'react';
import { GridSelect, Heading, Slider } from '@superside/ui';
import { ProgressiveInput, ProgressivePhoneInput } from '../ProgressiveInput';
import { ProgressiveSelect } from '../ProgressiveSelect';
import { type QuestionFieldContainerProps } from './QuestionFieldContainer';
import { type AdditionalProgressiveProps, type QuestionItemType } from './type';

export type FieldProps = QuestionItemType &
  Pick<QuestionFieldContainerProps, 'onKeyPress' | 'fieldPosition' | 'isVisible'> & {
    inputClassName: AdditionalProgressiveProps['inputClassName'];
    onChange: (value: string | string[] | undefined) => void;
    onChangeAndGoNext: (value: string | string[] | undefined) => void;
  };

export const QuestionField: React.FC<FieldProps> = ({
  placeholder = '',
  name,
  type,
  options = [],
  inputClassName,
  onChange,
  onChangeAndGoNext,
  onKeyPress,
  fieldPosition,
  mobileColumns,
  desktopColumns,
  multiple,
  isVisible,
  sliderMin,
  sliderMax,
  sliderStep,
  sliderIsPercentage,
  sliderPrefix,
  sliderSuffix
}) => {
  switch (type) {
    case 'email':
    case 'text':
      return fieldPosition === 'top' ? (
        <ProgressiveInput
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className={inputClassName}
          name={name}
          autofocus={isVisible}
        />
      ) : (
        <></>
      );
    case 'phone':
      return fieldPosition === 'top' ? (
        <ProgressivePhoneInput
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className={inputClassName}
          name={name}
        />
      ) : (
        <></>
      );
    case 'dropdown':
      return fieldPosition === 'top' ? (
        <ProgressiveSelect
          onChange={onChange}
          placeholder={placeholder}
          options={options}
          className={inputClassName}
        />
      ) : (
        <></>
      );
    case 'grid-select':
      return fieldPosition === 'top' ? (
        <Heading type='h4' className='1280:text-3xl text-light'>
          {placeholder}
        </Heading>
      ) : (
        <GridSelect
          onChange={multiple ? onChange : onChangeAndGoNext}
          options={options}
          mobileColumns={mobileColumns}
          desktopColumns={desktopColumns}
          withMultiple={multiple}
        />
      );
    case 'slider':
      return fieldPosition === 'top' ? (
        <Heading type='h4' className='1280:text-3xl text-light'>
          {placeholder}
        </Heading>
      ) : (
        <Slider
          onChange={(value) => onChange(String(value))}
          min={sliderMin}
          max={sliderMax}
          step={sliderStep}
          isPercentage={sliderIsPercentage}
          prefix={sliderPrefix}
          suffix={sliderSuffix}
        />
      );
    default:
      return <></>;
  }
};
