import React from 'react';
import NextImage from '@konsus/image';
import { Box, cn } from '@superside/ui';
import { SanityBlockComponent } from '../../SanityBlockComponent';
import styles from './RichTextQuote.module.css';
import { type QuoteProps } from './index';

export const QuoteVariant1 = (props: QuoteProps) => {
  const { author, text } = props;

  if (!author || !text) {
    return null;
  }

  return (
    <Box className='rte-sub my-15 flex-row'>
      <Box className='1280:flex 1280:h-[335px] 1280:w-[335px] hidden'>
        {/* @ts-ignore */}
        <NextImage layout='responsive' image={author?.image} alt={author?.name} />
      </Box>
      <Box className='1280:justify-between 1280:pl-12 flex-shrink'>
        <Box
          className={cn(
            styles.bg,
            '1280:after:h-[216px] 1280:after:w-[272px] 1280:before:h-[216px] 1280:before:w-[272px] 1024:[&_p]:!text-[23px] 1280:[&:after]:-bottom-14 1280:flex-grow 1280:justify-center 1280:pl-6 1280:pr-6 768:px-0 768:py-8 text-bor-lg relative py-6 font-semibold before:absolute before:left-0 before:top-0 before:block before:h-[124px] before:w-[156px] before:rotate-180 before:bg-cover before:content-[""] after:absolute after:bottom-0 after:right-0 after:block after:h-[124px] after:w-[156px] after:bg-cover after:content-[""] [&>*]:z-10'
          )}
        >
          <Box className='1024:[&_p]:!leading-8'>
            <SanityBlockComponent blocks={text} />
          </Box>
        </Box>

        <Box className='1280:pl-6 1280:pr-6 relative z-10 mt-2 flex-row items-center gap-6'>
          <Box className='1280:hidden h-14 w-14'>
            {/* @ts-ignore */}
            <NextImage layout='responsive' image={author.image} alt={author.name} />
          </Box>
          <Box className='shrink grow'>
            <span className='768:text-bor-lg font-semibold'>{author.name}</span>
            <span>{author.position}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
