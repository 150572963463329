import { FREE_EMAIL_SERVICES } from '@konsus/apps-common';
import type { QuestionItemType, Answers } from './type';

const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validPhoneNumberRegex = /^(?:\+\d{1,3}\s?)?[\d-]+$/;
const passedValidationEmailAnswer = { isValid: true, message: '' };
const invalidEmailAnswer = { isValid: false, message: 'Company email is required' };

type ValidationResult = {
  isValid: boolean;
  message: string;
};

const shortFreeEmailValidation = (domain: string) => {
  return FREE_EMAIL_SERVICES.includes(domain) ? invalidEmailAnswer : passedValidationEmailAnswer;
};

const isValidEmail = async (value: string, isCompanyEmail: boolean): Promise<ValidationResult> => {
  if (!validEmailRegex.test(value)) {
    return { isValid: false, message: 'Email is invalid' };
  }
  const domain = value.split('@')[1];

  if (isCompanyEmail) {
    try {
      const response = await fetch(`/api/free-email-providers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ e: domain })
      });

      if (!response.ok) {
        return shortFreeEmailValidation(domain);
      }

      const { isFreeEmailDomain } = await response.json();

      if (isFreeEmailDomain) {
        return invalidEmailAnswer;
      }
    } catch (error) {
      return shortFreeEmailValidation(domain);
    }
  }

  return passedValidationEmailAnswer;
};

const isValidPhoneNumber = (value: string): ValidationResult => {
  return validPhoneNumberRegex.test(value)
    ? { isValid: true, message: '' }
    : { isValid: false, message: 'Phone number is invalid' };
};

export const fieldValidator = async ({
  type,
  name,
  value,
  isCompanyEmail
}: {
  type: QuestionItemType['type'];
  name: string;
  value: string;
  isCompanyEmail: boolean;
}): Promise<ValidationResult> => {
  if (!value) {
    return { isValid: false, message: 'This field is mandatory' };
  }

  switch (type) {
    case 'text':
      return name === 'email'
        ? await isValidEmail(value, isCompanyEmail)
        : { isValid: true, message: '' };
    case 'email':
      return await isValidEmail(value, isCompanyEmail);
    case 'phone':
      return isValidPhoneNumber(value);
    default:
      return { isValid: true, message: '' };
  }
};

export const insertFieldsAfterPosition = (
  questions: QuestionItemType[],
  position: number,
  newFields: QuestionItemType[]
) => [...questions.slice(0, position + 1), ...newFields, ...questions.slice(position + 1)];

export const extractValueFromObject = (obj: Record<string, any>, key: string) => {
  if (key.includes('.')) {
    const keysArray = key.split('.');

    let value = obj;

    for (const k of keysArray) {
      value = value[k];
    }

    return value;
  }

  return obj[key];
};

export const getChilipiperValue = (question: QuestionItemType, answer: string) => {
  const { chilipiperPayload = 'True', validation, apolloStartsWith, apolloIncludes } = question;
  const oppositeChilipiperPayload = chilipiperPayload === 'True' ? 'False' : 'True';

  if (validation === 'notNull' && answer) {
    return chilipiperPayload;
  }

  if (validation === 'startsWith' && apolloStartsWith && answer) {
    return answer.startsWith(apolloStartsWith) ? chilipiperPayload : oppositeChilipiperPayload;
  }

  if (validation === 'includes' && apolloIncludes && answer) {
    return answer.includes(apolloIncludes) ? chilipiperPayload : oppositeChilipiperPayload;
  }

  return answer;
};

export const isPersonalQuestionsSubmitted = (answers: Answers) => {
  // eslint-disable-next-line camelcase
  const { firstname, lastname, email, company, company_size } = answers;

  // eslint-disable-next-line camelcase
  if (firstname && lastname && email && company && company_size) {
    return true;
  } else {
    return false;
  }
};

export const getLlmScore = async (answers: Answers) => {
  // eslint-disable-next-line camelcase
  const { firstname, lastname, email, company, company_size } = answers;

  return fetch(`/api/lead-qualification`, {
    method: 'POST',
    body: JSON.stringify({
      firstName: firstname,
      lastName: lastname,
      email,
      companyName: company,
      // eslint-disable-next-line camelcase
      companySize: company_size
    })
  }).then((data) => data.json());
};
