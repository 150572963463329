import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';
import { Box, type BoxProps, Icon } from '@konsus/superside-kit';
import { TwitterX, Facebook, Mail, Linkedin } from '@konsus/superside-kit/src/images/icons';
import { CopyLinkButton } from './CopyLinkButton';

const colorsTheme = {
  dark: {
    backgroundColor: 'secondary',
    iconColor: 'white'
  },
  light: {
    backgroundColor: '#FAFAFA',
    iconColor: '#0B1926'
  },
  lightBlue: {
    backgroundColor: '#FAFAFA',
    iconColor: '#4F4FC8'
  }
};

export const ShareButtons: React.FunctionComponent<
  BoxProps & {
    shareUrl?: string;
    sharingText?: string;
    directionColumn?: boolean;
    includeCopyLink?: boolean;
    includeEmail?: boolean;
    includeTikTok?: boolean;
    variant?: 'dark' | 'light' | 'lightBlue';
    size?: 'medium' | 'small';
  }
> = (props) => {
  const {
    shareUrl = '',
    sharingText = '',
    directionColumn,
    variant = 'dark',
    size = 'medium',
    includeCopyLink = false,
    includeEmail = true,
    ...rest
  } = props;
  const colors = colorsTheme[variant];

  return (
    <Box direction={directionColumn ? 'column' : 'row'} gap='3' align='start' {...rest}>
      <Box>
        <FacebookShareButton title={sharingText} url={shareUrl}>
          <Box
            className='shadow-down-blur cursor-pointer'
            background={colors.backgroundColor}
            round='full'
            pad='xsmall'
            responsive={false}
          >
            <Icon icon={Facebook} color={colors.iconColor} size={size} />
          </Box>
        </FacebookShareButton>
      </Box>

      <Box>
        <LinkedinShareButton title={sharingText} url={shareUrl}>
          <Box
            className='shadow-down-blur cursor-pointer'
            background={colors.backgroundColor}
            round='full'
            pad='xsmall'
            responsive={false}
          >
            <Icon icon={Linkedin} color={colors.iconColor} size={size} />
          </Box>
        </LinkedinShareButton>
      </Box>

      <Box>
        <TwitterShareButton url={shareUrl} title={sharingText} via='SupersideHQ'>
          <Box
            className='shadow-down-blur cursor-pointer'
            background={colors.backgroundColor}
            round='full'
            pad='xsmall'
            responsive={false}
          >
            <Icon icon={TwitterX} color={colors.iconColor} size={size} />
          </Box>
        </TwitterShareButton>
      </Box>

      {includeCopyLink ? (
        <Box className='relative'>
          <CopyLinkButton
            background={colors.backgroundColor}
            color={colors.iconColor}
            url={shareUrl}
          />
        </Box>
      ) : null}

      {includeEmail ? (
        <Box>
          <EmailShareButton url={shareUrl} subject={sharingText}>
            <Box
              className='shadow-down-blur cursor-pointer'
              background={colors.backgroundColor}
              round='full'
              pad='xsmall'
              responsive={false}
            >
              <Icon icon={Mail} color={colors.iconColor} size={size} />
            </Box>
          </EmailShareButton>
        </Box>
      ) : null}
    </Box>
  );
};

export default ShareButtons;
