import React from 'react';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

export class TwitterPostComponentContent extends React.Component<any, any> {
  componentDidMount() {
    const twitterScript = document.querySelector('#twitter-script');

    if (!twitterScript) {
      const script = document.createElement('script');

      script.src = 'https://platform.twitter.com/widgets.js';
      script.id = 'twitter-script';
      script.async = true;
      document.body.appendChild(script);
    }
  }

  render() {
    if (this.props.node.code.includes('<iframe')) {
      throw new Error('with iframe');
    }

    return (
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        dangerouslySetInnerHTML={{ __html: this.props.node.code }}
      />
    );
  }
}

export const TwitterPostComponent = createLazyLoadableComponent(TwitterPostComponentContent);
