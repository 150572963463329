import React from 'react';
import { Box, type BoxProps } from '@konsus/superside-kit';
import { cn } from '@superside/ui';

interface PaginationProps extends BoxProps {
  totalItems: number;
  index: number;
  onChangePage: (index: number, shouldScroll?: true) => void;
  isDark?: boolean;
}

export const PaginationDots: React.FC<PaginationProps> = (props) => {
  const {
    totalItems,
    index,
    onChangePage,
    justify = 'center',
    gap = '4',
    direction = 'row',
    className,
    isDark = false,
    ...rest
  } = props;

  return (
    <Box
      className={cn(className, 'paginationDots pt-6')}
      wrap
      justify={justify}
      gap={gap}
      direction={direction}
      {...rest}
    >
      {Array.from({ length: totalItems }).map((_, i) => (
        <button
          key={i}
          className={cn(
            'bg-bor-foreground h-4 w-4 cursor-pointer rounded-lg border-0 text-[0] opacity-10 transition-all duration-[0.3s] ease-[ease-in-out]',
            isDark && 'bg-dark',
            i === index && 'w-8 opacity-100'
          )}
          onClick={() => onChangePage(i, true)}
        />
      ))}
    </Box>
  );
};
