import React from 'react';
import { QuoteVariant2 } from './components/RichTextQuote/QuoteVariant2';

interface BlockQuoteProps {
  node: {
    text: string;
    author: string;
    company: string;
  };
}

export const BlockQuote: React.FC<BlockQuoteProps> = ({ node }) => {
  const { text, author, company } = node;

  return <QuoteVariant2 text={text} author={{ name: author, position: company }} />;
};
