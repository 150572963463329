import React, { useMemo } from 'react';
import { Box, type BoxProps, theme } from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { Heading, Superscript, Text } from '@superside/ui';
import { type CtaProps } from '../../types/ctaButton';
import { SectionHeaderWithCta, wrapperMaxWidthVar } from './SectionHeaderWithCta';

export interface SectionHeaderProps extends BoxProps {
  superscriptText?: string;
  subHeaderText?: string;
  subHeaderRichText?: Array<any>;
  headerText?: string;
  headerRichText?: any;
  wrapperMaxWidth?: string;
  ctaButton?: CtaProps;
  style?: { [x: string]: any };
  additionalButton?: any;
  children?: any;
  isCenter?: boolean;
}

export const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
  if (props.isCenter) {
    return (
      <Box className={props.isCenter ? 'items-center justify-center' : ''}>
        <SectionHeaderContent
          {...(props.isCenter
            ? { align: 'center', justify: 'center', style: { textAlign: 'center' } }
            : {})}
          {...props}
        />
      </Box>
    );
  }

  return <SectionHeaderContent {...props} />;
};

const SectionHeaderContent: React.FunctionComponent<SectionHeaderProps> = (props) => {
  const {
    superscriptText,
    subHeaderText,
    subHeaderRichText,
    headerText,
    headerRichText,
    wrapperMaxWidth = '1036px',
    ctaButton,
    additionalButton,
    ...rest
  } = props;

  const headerWithCtaStyle = useMemo(
    () => ({
      [wrapperMaxWidthVar.name]: wrapperMaxWidth
    }),
    [wrapperMaxWidth]
  );

  if (!headerText && !headerRichText) {
    return null;
  }

  const sectionContent = (
    <Content
      superscriptText={superscriptText}
      subHeaderText={subHeaderText}
      subHeaderRichText={subHeaderRichText}
      headerText={headerText}
      headerRichText={headerRichText}
    />
  );

  if (ctaButton?.ctaLink || ctaButton?.ctaText) {
    return (
      <SectionHeaderWithCta ctaButton={ctaButton} style={headerWithCtaStyle} {...rest}>
        {sectionContent}
      </SectionHeaderWithCta>
    );
  }

  if (additionalButton) {
    return (
      <Box
        className='1280:items-end 1280:flex-row 1280:mb-20'
        margin={{ bottom: theme.edgeSize.medium }}
        direction='column'
      >
        <Box width={wrapperMaxWidth} {...rest} flex={{ grow: 0, shrink: 1 }}>
          {sectionContent}
        </Box>
        {additionalButton}
      </Box>
    );
  }

  return (
    <Box margin={{ bottom: '10' }} width={wrapperMaxWidth} {...rest} flex={{ grow: 0, shrink: 1 }}>
      {sectionContent}
    </Box>
  );
};

interface ContentProps {
  superscriptText?: string;
  subHeaderText?: string;
  subHeaderRichText?: Array<any>;
  headerText?: string;
  headerRichText?: any;
}

const Content: React.FC<ContentProps> = ({
  superscriptText = null,
  subHeaderText = null,
  subHeaderRichText = null,
  headerText,
  headerRichText
}) => (
  <>
    {superscriptText ? <Superscript>{superscriptText}</Superscript> : null}
    {headerText && !headerRichText ? (
      <Heading dangerouslySetInnerHTML={{ __html: headerText }} level='h3' as='h2' />
    ) : null}
    {headerRichText ? (
      <SanityBlockComponent
        renderNormalAsHeading={{ as: 'h2', level: 'h3' }}
        blocks={headerRichText}
      />
    ) : null}
    {subHeaderText || subHeaderRichText ? (
      <div className='768:max-w-[590px] 1280:max-w-[794px] mt-3 max-w-none'>
        {subHeaderText && !subHeaderRichText ? (
          <Text className='text-bor-foreground'>{subHeaderText}</Text>
        ) : null}
        {subHeaderRichText ? (
          <Text>
            <SanityBlockComponent blocks={subHeaderRichText} />
          </Text>
        ) : null}
      </div>
    ) : null}
  </>
);
