import React from 'react';
import { Heading } from '@superside/ui';

export const TimeDivider: React.FC = () => (
  <div className='flex flex-col items-center'>
    <Heading type='h5' className='1024:mt-4 1024:mx-4 mx-1 mt-2'>
      :
    </Heading>
  </div>
);

export default TimeDivider;
