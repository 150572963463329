import React from 'react';
import { contentWrapperStyle, ExternalContentWrapper } from '@konsus/superside-kit';
import { Box, Heading, cn } from '@superside/ui';
import { ChevronDown } from '@konsus/superside-kit/src/images/icons';
import VisualAsset from '@konsus/lego/src/components/VisualAsset';
import { type ActiveIndexSetter } from '@konsus/lego/src/components';
import { SanityBlockComponent } from '../../SanityBlockComponent';
import { type AccordionItemProps } from './AccordionCarousel';

interface AccordionItemComponentProps {
  item: AccordionItemProps;
  index: number;
  setActive: ActiveIndexSetter;
  topAlignImages?: boolean;
  hideActiveSetter?: boolean;
}

export const AccordionItem: React.FC<AccordionItemComponentProps> = ({
  item,
  index,
  setActive,
  topAlignImages,
  hideActiveSetter
}) => {
  const { title, descriptionText, descriptionRichText, asset } = item;

  return (
    <Box
      className={cn(
        `card-${index + 1}`,
        'flex-1 rounded-md bg-blue-100 p-6',
        '1024:col-start-2 768:!w-full 768:[&_.cardContentDescription]:h-0 768:[&_.cardContentDescription]:overflow-hidden 768:[.cardActiveStyles_&]:bg-[#e5eaff] 768:[.cardActiveStyles_&]:border-b-0 768:[.cardActiveStyles_&]:rounded-[8px] 768:[.cardActiveStyles_&_.cardContentDescription]:flex 768:[.cardActiveStyles_&_.cardContentDescription]:h-auto 768:[.cardPreviousStyles_&]:border-b-0 768:bg-transparent 768:border-b 768:border-b-[1px] 768:border-b-[rgba(15,_33,_51,_0.1)] 768:col-start-1 768:rounded-none'
      )}
    >
      <button onClick={() => setActive(index)}>
        <Box className='flex-row justify-between'>
          <Heading className='768:[.cardActiveStyles_&]:mb-4 768:mb-0 mb-4 text-left' type='h4'>
            {title}
          </Heading>

          {!hideActiveSetter ? (
            <div className='w-6'>
              <ChevronDown
                className={
                  '768:[.cardActiveStyles_&]:rotate-180 768:block 768:duration-200 768:ease-in-out 768:transition-transform hidden'
                }
                size='24px'
                color='#9CA3AA'
              />
            </div>
          ) : null}
        </Box>
      </button>
      <Box
        className={cn(
          'cardContentDescription flex-row items-center gap-6',
          topAlignImages && 'items-start'
        )}
      >
        <Box className={'flex-1'}>
          {descriptionRichText ? (
            <ExternalContentWrapper className={contentWrapperStyle}>
              <SanityBlockComponent blocks={descriptionRichText} />
            </ExternalContentWrapper>
          ) : null}

          {descriptionText && !descriptionRichText ? <span>{descriptionText}</span> : null}
        </Box>
        {asset ? (
          <Box className={'1280:block mt-4 hidden max-w-[352px] [&_div]:h-full [&_div]:w-full'}>
            <VisualAsset {...asset} />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
