import React from 'react';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

const BASE_TIKTOK_URL = `https://www.tiktok.com/embed/v3/`;

interface TikTokComponentProps {
  id: string;
  width: number;
  height: number;
}

interface TikTokComponentContentProps extends TikTokComponentProps {
  node?: TikTokComponentProps;
}

export const TikTokComponentContent: React.FC<TikTokComponentContentProps> = (props) => {
  const id = props?.node?.id || props?.id;
  const width = props?.node?.width || 100;
  const height = props?.node?.height || 200;

  return id ? (
    <div>
      <iframe
        src={`${BASE_TIKTOK_URL}${id}`}
        style={{ width: `${width}%`, height, borderRadius: 8, border: 0 }}
        className='rte-mobile-full-width'
      />
    </div>
  ) : null;
};
export const TikTokComponent = createLazyLoadableComponent(TikTokComponentContent);
