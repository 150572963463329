import React from 'react';
import { createLazyLoadableComponent } from '../utlis/createLazyLoadableComponent';

declare let window;

export class InstagramComponentContent extends React.Component<any, any> {
  componentDidMount() {
    const instagramScript = document.querySelector('#instgrm-script');

    if (!instagramScript) {
      const script = document.createElement('script');

      script.src = '//www.instagram.com/embed.js';
      script.id = 'instgrm-script';
      script.async = true;
      script.onload = () => {
        window.instgrm.Embeds.process();
      };
      document.body.appendChild(script);
    } else if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }

  render() {
    if (this.props.node.code.includes('<iframe')) {
      throw new Error('with iframe');
    }

    return (
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        dangerouslySetInnerHTML={{ __html: this.props.node.code }}
      />
    );
  }
}

export const InstagramComponent = createLazyLoadableComponent(InstagramComponentContent);
