import React, { forwardRef } from 'react';
import dynamic from 'adapter/next/dynamic';
import { useAtomMutator } from '@konsus/atoms';
import { previewMediaValueAtom } from '@konsus/superside-kit';
import { cn } from '@superside/ui';
import { MediaTypes, type VisualAssetProps } from './types';
import Lottie from './components/Lottie';
import Image from './components/Image';
import InlineVideo from './components/InlineVideo';
import Wistia from './components/Wistia';
import YouTube from './components/YouTube';
import ProgressBar from './components/ProgressBar';
import SquareVideoBackground from './components/SquareVideoBackground';
import Spline from './components/Spline';

export * from './types';

export const maxWidthVariants = {
  '100%': 'max-w-full',
  '75%': '768:max-w-[75%] mx-auto',
  '50%': '768:max-w-[50%] mx-auto',
  optimized: ''
} as const;

const BeforeAndAfterImage = dynamic(
  () =>
    import(
      /* webpackChunkName: "VisualAsset_BeforeAndAfterImage" */ './components/BeforeAndAfterImage'
    )
);

const SkewingAssets = dynamic(
  () => import(/* webpackChunkName: "VisualAsset_SkewingAssets" */ './components/SkewingAssets')
);

export const VisualAsset = forwardRef<HTMLVideoElement, VisualAssetProps>((props, ref) => {
  const {
    mediaType,
    image,
    imageMobile,
    alt,
    maxWidth,
    captionText,
    objectFit,
    objectFitMobile,
    objectPosition,
    objectPositionMobile,
    layout,
    className,
    inlineVideo,
    inlineVideoMobile,
    inlineVideoThumbnail,
    inlineVideoThumbnailMobile,
    inlineVideoData,
    inlineVideoDataMobile,
    hideLoadingBar,
    lottieMobile,
    lottie,
    lottieMobileAspectRatio,
    lottieAspectRatio,
    wistiaVideoId,
    wistiaVideoIdMobile,
    wistiaVideoData,
    wistiaVideoDataMobile,
    youtubeVideoId,
    youtubeVideoIdMobile,
    youtubeVideoData,
    youtubeVideoDataMobile,
    squareVideoBg,
    lottieTrigger,
    progressBarIcon,
    progressBarPercentage,
    progressBarText,
    showPreviewModal = false,
    slides,
    activeIndex,
    previewModalTitle,
    requestWidth,
    requestWidthMobile,
    videoOnClickEnabled = false,
    videoOnClick,
    splineId,
    splineHeight,
    isRte,
    beforeImage,
    afterImage,
    beforeAfterCaption,
    skewingAssets,
    skewingAssetsDisabledMobile,
    loading,
    ...rest
  } = props;

  const setPreviewMediaValue = useAtomMutator(previewMediaValueAtom);

  const maxWidthClassName =
    maxWidth && maxWidth in maxWidthVariants
      ? maxWidthVariants[maxWidth as keyof typeof maxWidthVariants]
      : '';

  const launchVideo = () => {
    if (!videoOnClickEnabled) {
      return false;
    }

    const { mediaType, title } = videoOnClick || {};

    if (
      mediaType !== MediaTypes.YOUTUBE &&
      mediaType !== MediaTypes.WISTIA &&
      mediaType !== MediaTypes.INLINEVIDEO
    ) {
      return false;
    }

    const mediaValue = { [mediaType]: videoOnClick };

    setPreviewMediaValue({ title, ...mediaValue });
  };

  const inlineVideoComponent = inlineVideo ? (
    <InlineVideo
      inlineVideo={inlineVideo}
      inlineVideoMobile={inlineVideoMobile}
      inlineVideoThumbnail={inlineVideoThumbnail}
      inlineVideoThumbnailMobile={inlineVideoThumbnailMobile}
      inlineVideoData={inlineVideoData}
      inlineVideoDataMobile={inlineVideoDataMobile}
      hideLoadingBar={hideLoadingBar}
      className={className}
      objectPosition={objectPosition}
      ref={ref}
      onClick={launchVideo}
      videoOnClickEnabled={videoOnClickEnabled}
      maxWidthClassName={cn(maxWidthClassName, maxWidth === 'optimized' && 'max-h-[700px] w-full')}
    />
  ) : null;

  const wistiaComponent = wistiaVideoId ? (
    <Wistia
      wistiaVideoId={wistiaVideoId}
      wistiaVideoData={wistiaVideoData}
      wistiaVideoIdMobile={wistiaVideoIdMobile}
      wistiaVideoDataMobile={wistiaVideoDataMobile}
      maxWidthClassName={maxWidthClassName}
      maxWidth={maxWidth}
    />
  ) : null;

  const youtubeComponent = youtubeVideoId ? (
    <YouTube
      youtubeVideoId={youtubeVideoId}
      youtubeVideoData={youtubeVideoData}
      youtubeVideoIdMobile={youtubeVideoIdMobile}
      youtubeVideoDataMobile={youtubeVideoDataMobile}
      maxWidthClassName={cn(maxWidthClassName, maxWidth === 'optimized' && 'max-h-[700px]')}
    />
  ) : null;

  const progressBarComponent = (
    <ProgressBar
      progressBarIcon={progressBarIcon}
      progressBarPercentage={progressBarPercentage}
      progressBarText={progressBarText}
      onClick={launchVideo}
      videoOnClickEnabled={videoOnClickEnabled}
    />
  );

  const imageComponent = image ? (
    <Image
      // @ts-ignore fix types based on sanity.types
      image={image}
      imageMobile={imageMobile}
      alt={alt || ''}
      objectFit={objectFit}
      objectPosition={objectPosition}
      layout={layout}
      className={cn(maxWidth === 'optimized' ? '!max-h-[700px]' : '', className)}
      objectFitMobile={objectFitMobile}
      objectPositionMobile={objectPositionMobile}
      showPreviewModal={videoOnClickEnabled === true ? true : showPreviewModal}
      slides={slides}
      activeIndex={activeIndex}
      previewModalTitle={previewModalTitle}
      onClick={launchVideo}
      videoOnClickEnabled={videoOnClickEnabled}
      requestWidth={requestWidth}
      requestWidthMobile={requestWidthMobile}
      maxWidth={maxWidth}
      maxWidthClassName={maxWidthClassName}
      captionText={captionText}
      isRte={isRte}
      loading={loading}
    />
  ) : null;

  const splineComponent = <Spline splineId={splineId} splineHeight={splineHeight} />;

  const lottieComponent = (
    <Lottie
      lottieMobile={lottieMobile}
      lottie={lottie}
      lottieMobileAspectRatio={lottieMobileAspectRatio}
      lottieAspectRatio={lottieAspectRatio}
      lottieTrigger={lottieTrigger}
      onClick={launchVideo}
      videoOnClickEnabled={videoOnClickEnabled}
    />
  );

  const skewingAssetsComponent = (
    <SkewingAssets
      skewingAssets={skewingAssets}
      skewingAssetsDisabledMobile={skewingAssetsDisabledMobile}
    />
  );

  const beforeAfterComponent =
    mediaType === MediaTypes.BEFORE_AFTER ? (
      <BeforeAndAfterImage
        beforeImage={beforeImage}
        afterImage={afterImage}
        beforeAfterCaption={beforeAfterCaption}
      />
    ) : null;

  const squareVideoAvailableComponents: Record<string, React.ReactElement | null> = {
    [MediaTypes.INLINEVIDEO]: inlineVideoComponent,
    [MediaTypes.WISTIA]: wistiaComponent,
    [MediaTypes.YOUTUBE]: youtubeComponent,
    [MediaTypes.PROGRESS_BAR]: progressBarComponent
  };

  const allComponents: Record<string, React.ReactElement | null> = {
    [MediaTypes.IMAGE]: imageComponent,
    [MediaTypes.LOTTIE]: lottieComponent,
    [MediaTypes.SPLINE]: splineComponent,
    [MediaTypes.BEFORE_AFTER]: beforeAfterComponent,
    [MediaTypes.SKEWING_ASSETS]: skewingAssetsComponent,
    ...squareVideoAvailableComponents
  };

  if (mediaType) {
    return squareVideoBg && squareVideoBg?.squareVideoBgEnable ? (
      // @ts-ignore fix types based on sanity.types
      <SquareVideoBackground squareVideoBg={squareVideoBg}>
        {squareVideoAvailableComponents[mediaType]}
      </SquareVideoBackground>
    ) : (
      allComponents[mediaType]
    );
  } else {
    // eslint-disable-next-line no-console
    console.error('Media not supported', rest);

    return null;
  }
});

VisualAsset.displayName = 'VisualAsset';

export default VisualAsset;
